import React, { useState, useRef } from "react";
import SignatureCanvas from 'react-signature-canvas'
import { Modal, Button, Input, Typography } from "antd";

const { Title } = Typography;

const SignCanvas = ({ open, setOpen, onSave }) => {
    const canvas = useRef(null);
    const [name, setName] = useState('')
    const [signature, setSignature] = useState(null)

    if (!open) {
        return null;
    }

    return (
        <Modal
            title={"Firma del " + (open === 'client' ? 'cliente' : 'transportista')}
            open={open !== null}
            footer={[
                <Button key="back" onClick={() => setOpen(false)}>
                    Cancelar
                </Button>,
                <Button key="submit" type="primary" onClick={() => {
                    const image = canvas.current.toDataURL()
                    setSignature(image)
                    onSave(image, name)
                    setOpen(false)
                }}>
                    Guardar
                </Button>,
            ]}
        >
            <SignatureCanvas ref={canvas} penColor='black' canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }} />
            <Title level={5}>Nombre</Title>
            <Input value={name} onChange={(e) => setName(e.target.value)} />
        </Modal>
    );
}

export default SignCanvas;