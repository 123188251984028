import React, { useEffect, useState } from "react";
import { Card, Col, Row, Table, Typography, Statistic, FloatButton, Tag } from "antd";
import ReactApexChart from "react-apexcharts";
import NewIngredient from "./components/NewIngredient";
import { fetchInventory } from "./utils/inventory";
import { useNavigate } from "react-router-dom";
const { Title } = Typography;

const InputInventory = () => {
  const [dataSource, setDataSource] = useState([]);
  const [totalValueSum, setTotalValueSum] = useState(0);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const navigate = useNavigate();

  const refresh = () => {
    setShouldRefresh(!shouldRefresh);
  };

  useEffect(() => {
    fetchInventory().then((warehouseEntries) => {
      setDataSource(warehouseEntries);
      console.log('asdad', warehouseEntries);
      const totalValue = warehouseEntries.reduce((acc, entry) => acc + isNaN(entry.baseCost * entry.stock) ? 0 : entry.baseCost * entry.stock, 0);
      console.log('totalValue', totalValue);
      setTotalValueSum(totalValue);
    });
  }, [shouldRefresh]);


  const [newIngredientModal, setNewIngredientModal] = React.useState(false);

  // Definición de columnas para la tabla de insumos
  const columns = [
   
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
      render: (text, item) => <p onClick={() => navigate('/inventory/InventoryDetails', { state: { item } })} style={{ cursor: 'pointer' }}>{text}</p>,
    },
    {
      title: "Costo Unitario",
      dataIndex: "baseCost",
      key: "baseCost",
      render: (text) => <p>{`$ ${text}`}</p>,
    },
    {
      title: "Cantidad",
      dataIndex: "stock",
      key: "stock",
      render: (text) => <p>{text.toLocaleString()}</p>,
    },
    
    {
      title: "Unidad base",
      dataIndex: "basePresentation",
      key: "basePresentation",
      render: (text) => <p>{text}</p>,
    },
    {
        title: 'Otras presentaciones',
        dataIndex: 'presentations',
        key: 'presentations',
        render: (presentations) => presentations.map((presentation) => <Tag key={presentation.presentation}>{presentation.presentation}</Tag>),
    },
    {
      title: "Valor",
      dataIndex: "total",
      key: "total",
      render: (text, product) => <p>{`$ ${(product.baseCost * product.stock).toLocaleString()}`}</p>,
    },
  ];

  // // Preparar datos para la gráfica de líneas
  // const chartData = {
  //   series: [
  //     {
  //       name: "Valor Total",
  //       data: dataSource.map(item => ({ x: new Date(item.date), y: parseFloat(item.total.replace('$', '').replace(',', '')) })),
  //     },
  //     {
  //       name: "Volumen Total",
  //       data: dataSource.map(item => ({ x: new Date(item.date), y: parseInt(item.quantity) })),
  //     },
  //   ],
  //   options: {
  //     chart: {
  //       type: 'line',
  //       height: 350,
  //       toolbar: {
  //         show: false,
  //       },
  //     },
  //     dataLabels: {
  //       enabled: false,
  //     },
  //     stroke: {
  //       curve: 'smooth',
  //     },
  //     xaxis: {
  //       type: 'datetime',
  //       categories: dataSource.map(item => new Date(item.date)),
  //     },
  //     yaxis: [
  //       {
  //         title: {
  //           text: 'Valor ($)',
  //         },
  //       },
  //       {
  //         opposite: true,
  //         title: {
  //           text: 'Volumen (Unidades)',
  //         },
  //       },
  //     ],
  //     tooltip: {
  //       x: {
  //         format: 'dd/MM/yyyy',
  //       },
  //       y: {
  //         formatter: function (val, opts) {
  //           return opts.seriesIndex === 0 ? `$ ${val.toLocaleString()}` : `${val} unidades`;
  //         },
  //       },
  //     },
  //   },
  // };

  return (
    <div className="layout-content">
      <FloatButton onClick={() => setNewIngredientModal(true)} />
      <NewIngredient open={newIngredientModal} setOpen={setNewIngredientModal} refresh={refresh} />
      <Row gutter={[24, 0]}>
        <Col lg={24} className="zindex">
          <h2 className="p-15 h2">Inventario de insumos</h2>

          <Card
            bordered={false}
            bodyStyle={{ padding: "0px" }}
            className="criclebox cardbody mb-24 header-solid"
          >
            <div className="ant-list-box table-responsive">
              <Table
                dataSource={dataSource}
                columns={columns}
                rowKey={(record, index) => index}
                pagination={true}
              />
            </div>
          </Card>
        </Col>
      </Row>

      <Row gutter={[24, 0]}>
        <Col lg={12} md={24} className="zindex">
          <Card
            bordered={false}
            bodyStyle={{ padding: "20px" }}
            className="criclebox cardbody mb-24 header-solid"
            title={<h3>Valor en $</h3>}
          >
            <Statistic value={totalValueSum} prefix="$" style={{ fontSize: '20px' }} />
          </Card>
        </Col>
        {/* <Col lg={12} md={24} className="zindex">
          <Card
            bordered={false}
            bodyStyle={{ padding: "20px" }}
            className="criclebox cardbody mb-24 header-solid"
            title={<h3>Volúmen</h3>}
          >
            <Statistic title="Total" value={totalVolumeSum} suffix="unidades" style={{ fontSize: '20px' }} />
          </Card>
        </Col> */}
      </Row>

      <Row>
        {/* <Col span={24}>
          <Card
            bordered={false}
            bodyStyle={{ padding: "20px" }}
            className="criclebox cardbody mb-24 header-solid"
            title={<h3>Estadísticas de Inventario</h3>}
          >
            <ReactApexChart
              options={chartData.options}
              series={chartData.series}
              type="line"
              height={350}
            />
          </Card>
        </Col> */}
      </Row>
    </div>
  );
};

export default InputInventory;
