import React, { useEffect, useState, useRef } from 'react';
import { Button, Modal, Form, Input, Space, Table, Flex, Tooltip, Badge, message } from 'antd';
import CameraModal from '../../../media/Camera';
import SignCanvas from '../../../media/SignCanvas';
import Parse from 'parse/lib/browser/Parse';

const NewInventorysale = ({ open, setOpen, saleInfo }) => {
    const [form] = Form.useForm();
    const [sale, setSale] = useState(saleInfo);
    const [images, setImages] = useState([]);
    const [openCamera, setOpenCamera] = useState(false);
    const [openSign, setOpenSign] = useState(false);
    const [clientSignature, setClientSignature] = useState(null);
    const [carrierSignature, setCarrierSignature] = useState(null);

    const handleSubmit = async () => {
        form.validateFields().then(async (values) => {
            const Index = Parse.Object.extend("Index");
            const indexQuery = new Parse.Query(Index);
            indexQuery.equalTo("name", "WarehouseOutlet");
            indexQuery.equalTo('year', new Date().getFullYear());
            const index = await indexQuery.first();

            const WarehouseOutlet = Parse.Object.extend("WarehouseOutlet");
            const outletObject = new WarehouseOutlet();
            outletObject.set("user", Parse.User.current());
            outletObject.set("images", images);
            outletObject.set("folio", 'S' + (index.get('value')+1).toString().padStart(4, '0') + '-' + new Date().getFullYear());
            outletObject.set("sale", { __type: "Pointer", className: "Sales", objectId: saleInfo.objectId });
           
            const Presentation = Parse.Object.extend("Presentation");
            let products = [];
            sale.products.forEach((product) => {
                console.log("product", product);
                const productObject = new Presentation();
                productObject.set("objectId", product?.presentation?.objectId);
                productObject.decrement("stock", parseInt(product.delivered));
                products.push(productObject);
            });
            outletObject.set("products", sale.products.map((product) => {
                return {
                    ...product,
                    delivered: parseInt(product.delivered),
                    remaining: parseInt(product.remaining) - parseInt(product.delivered),
                };
            }));
            outletObject.set("productPointers", sale.products.map((product) => {
                return { __type: "Pointer", className: "Product", objectId: product.objectId };
            }));
            let toSave = []
            outletObject.save().then((result) => {
                const Sale = Parse.Object.extend("Sales");
                const saleOrder = new Sale();
                saleOrder.set("objectId", saleInfo.objectId);
                // totalReceived >= totalRemaining ? purchaseOrder.set("deliveries", "Recibido") : purchaseOrder.set("deliveries", "Parcial");
                saleOrder.addUnique("outlets", { __type: "Pointer", className: "WarehouseOutlet", objectId: result.id });
                index.increment('value', 1);
                toSave.push(index)
                toSave.push(saleOrder)
                toSave.push(...products)
                
                
                Parse.Object.saveAll(toSave).then((results) => {
                    message.success('Salida de almacén creada correctamente');
                    setOpen(false);
                }
                ).catch((error) => {
                    console.log(error);
                    message.error('Error al crear la salida de almacén');
                }
                );
            }
            ).catch((error) => {
                console.log(error);
                message.error('Error al crear la salida de almacén');
            }
            );

        }).catch((error) => {
            console.log(error);
            message.error('Error al crear la salida de almacén');
        });
    }

    useEffect(() => {
        console.log(saleInfo);
        setSale(saleInfo);
    }, [saleInfo]);



    return (
        <Modal
            title="Nueva salida de almacén"
            open={open}
            onCancel={() => setOpen(false)}
            style={{ top: 20 }}
            footer={[
                <Button key="back" onClick={() => setOpen(false)}>
                    Cancelar
                </Button>,
                <Button key="submit" type="primary" onClick={handleSubmit}>
                    Guardar
                </Button>,
            ]}
        >
            <Space direction="vertical">
            <h2>Venta: {sale?.saleNumber}</h2>
            <h3>Cliente: {sale?.client?.name}</h3>
            <h3>Recibe: {sale?.deliveryPlace === 'Domiclio' ? 'Cliente' : 'Tansportista'}</h3>
           <Table
                dataSource={sale?.products}
                columns={[
                    {
                        title: "Producto",
                        dataIndex: "name",
                        key: "name",
                        render: (text) => (
                            <p>{text}</p>
                        ),
                    },
                    {
                        title: "Cantidad",
                        dataIndex: "quantity",
                        key: "quantity",
                        render: (text, product) => (
                            <p>{text} {product.baseUnit?.toLowerCase()}</p>
                        ),
                    },
                    {
                        title: 'Entregado',
                        dataIndex: 'delivered',
                        key: 'delivered',
                        render: (text, product) => (
                            <Input 
                                style={{ color: parseInt(product.quantity) === parseInt(text) ? 'green' : 'red' }} 
                                onChange={(e) => {
                                    const products = sale.products
                                        .map((p) => {
                                            if (p.name === product.name) {
                                                return { ...p, delivered: e.target.value };
                                            }
                                            return p;
                                        });
                                        setSale({ ...sale, products });
                                }}
                            />
                                
                        )
                    }
                ]}
                rowKey={(record, index) => index}
                pagination={false}
            />
            <Flex direction="horizontal" style={{ marginTop: 20, overflowX: 'scroll' }}>
                {images && images.map((image, index) => (
                    <Space direction="vertical" style={{ marginRight: 10, paddingTop: 10 }}>
                        <Badge title="Eliminar" onClick={() => setImages(images.filter((_, i) => i !== index))} count="X" style={{ cursor: 'pointer' }}>
                            <img key={index} src={image} alt={`Foto ${index}`} style={{ width: 90, height: 160, objectFit: 'cover' }} />
                        </Badge>
                    </Space>
                ))}
            </Flex>
            <CameraModal open={openCamera} setOpen={setOpenCamera} onImageTook={setImages} />
            
            {clientSignature && (
                <Space direction="vertical">
                    <h3>Firma del cliente</h3>
                    <img src={clientSignature.image} alt={clientSignature.name} style={{ width: 100, height: 100, objectFit: 'cover' }} />
                    <p>{clientSignature.name}</p>
                </Space>
            )}
            {carrierSignature && (
                <Space direction="vertical">
                    <h3>Firma del transportista</h3>
                    <img src={carrierSignature.image} alt={carrierSignature.name} style={{ width: 100, height: 100, objectFit: 'cover' }} />
                    <p>{carrierSignature.name}</p>
                </Space>
            )}
            <SignCanvas open={openSign} setOpen={setOpenSign} onSave={(image, name) => {
                if (openSign === 'client') {
                    setClientSignature({ image, name });
                } else {
                    setCarrierSignature({ image, name });
                }
            }} />
            <Space direction="horizontal">
            <Button type="primary" onClick={() => setOpenCamera(true)}>Agregar foto</Button>
            {sale?.deliveryPlace === 'Pie de fábrica' ? (
                <Button type="primary" onClick={() => setOpenSign('carrier')}>Firma del transportista</Button>
            ) : (
                <Button type="primary" onClick={() => setOpenSign('client')}>Firma del cliente</Button>
            )}
            </Space>
            </Space>
        </Modal>
    );
}

export default NewInventorysale;
