import React, { useRef, useState } from "react";
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import { Input, Select } from "antd";

const AddressAutocomplete = ({ onSelect }) => {
  const { ready, value, suggestions: { status, data }, setValue, clearSuggestions } = usePlacesAutocomplete({
    requestOptions: {
        componentRestrictions: { country: 'mx' }, // Restrict results to Mexico
        // types: ['locality', 'sublocality', 'postal_code', 'street_address', 'route', 'establishment'], // Exclude countries and states
    },
    debounce: 600,
  });


    return (
            <Select
            showSearch
                options={data.map((suggestion) => ({
                    value: suggestion.description,
                    label: suggestion.description,
                    ...suggestion,
                }))}
                onSelect={(value, option) => onSelect(value, option, setValue, clearSuggestions, getGeocode, getLatLng)}
                onSearch={(value) => {
                    setValue(value);
                }}
                placeholder="Empieza a escribir la dirección"
                style={{ width: "100%" }}
                disabled={!ready}
                value={value}
                notFoundContent={status === "OK" ? "No hay resultados" : "Cargando..."}
            />
          
    );

}

export default AddressAutocomplete;