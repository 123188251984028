import React, { useEffect, useState } from "react";
import { Table, Typography, Button, Space, Input, message, Modal, Form } from "antd";
import DebounceSelect from "../../../form/DebounceSelect";
import { fetchIngredients } from "../utils/inventory";
import { PlusOutlined } from "@ant-design/icons";
import Parse from "parse";

const { Title } = Typography;
const NewRecipe = ({ parentForm, open, setOpen, addRecipe, recipe }) => {
    const [form] = Form.useForm();
    const [ingredients, setIngredients] = useState([]);
    const [renderId, setRenderId] = useState(0); 
    const [newIngredient, setNewIngredient] = useState(false);

    useEffect(() => {
        if (recipe) {
            form.setFieldsValue({
                name: recipe.name,
                description: recipe.description,
                ingredients: recipe.ingredients,
            });
        }
    }, [recipe]);

    useEffect(() => {
        if (!open) {
            form.resetFields();
            setIngredients([]);
        }
    }, [open]);

    const handleSubmit = () => {
        let recipeObject = form.getFieldsValue();
        form.validateFields().then((values) => {

            recipeObject.ingredients = ingredients;
            recipeObject.ingredientPointers = ingredients.map((ingredient) => {
                return {
                    __type: "Pointer",
                    className: "Ingredient",
                    objectId: ingredient.objectId,
                };
            });
            recipeObject.ingredientsWeight = ingredients.reduce((acc, ingredient) => acc + parseFloat(ingredient.quantity), 0);
            recipeObject.totalCost = parseFloat(totalCost());
            recipeObject.baseCost = parseFloat(unitCost());
            recipeObject.baseUnit = "Kg";
            recipeObject.finalWeight = parseFloat(values.finalWeight);

            const Recipe = Parse.Object.extend("Recipe");
            const recipe = new Recipe(recipeObject);

            recipe.save().then((recipe) => {
                message.success("Receta guardada correctamente");
                form.resetFields();
                addRecipe(recipeObject);
            }).catch((error) => {
                console.log(error);
            });
            
        }).catch((error) => {
            console.log(error);
        });
    }

    const onIngredientSelected = (objectId, ingredient) => {
        console.log("ingredient", ingredient);
        if (ingredients.find((p) => p.objectId === ingredient.objectId)) {
            return;
        }
        ingredients.push({
            ...ingredient,
            quantity: 1,
            total: 0,
        });
        setIngredients(ingredients);
        setRenderId((prev) => prev + 1);
    };

    const totalCost = () => {
        let totalCost = ingredients.reduce((acc, ingredient) => acc + ingredient.baseCost * ingredient.quantity, 0);
        isNaN(totalCost) && (totalCost = 0);
        return totalCost.toFixed(2);
        
    }
    
    const unitCost = () => {
        const totalCost = ingredients.reduce((acc, ingredient) => acc + ingredient.baseCost * ingredient.quantity, 0);
        let unitCost = totalCost/form.getFieldValue("finalWeight");
        isNaN(unitCost) && (unitCost = 0);
        return unitCost.toFixed(2);
    }

    return (
        <Modal
            title="Nueva receta"
            open={open}
            onCancel={() => setOpen(false)}
            footer={[
                <Button key="back" onClick={() => setOpen(false)}>
                    Cancelar
                </Button>,
                <Button key="submit" type="primary" onClick={handleSubmit}>
                    Guardar
                </Button>,
            ]}
        >
            <Form form={form} layout="vertical">
                <Form.Item
                    label="Nombre"
                    name="name"
                    rules={[{ required: true, message: "Por favor ingrese el nombre de la receta" }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Descripción"
                    name="description"
                    rules={[{ required: true, message: "Por favor ingrese la descripción de la receta" }]}
                >
                    <Input.TextArea />
                </Form.Item>

                <Form.Item
                    label="Peso producto terminado"
                    name="finalWeight"
                    rules={[{ required: true, message: 'Por favor indique el peso del producto terminado' }]}
                    shouldUpdate
                >
                    <Input type="number" suffix="Kg" />
                </Form.Item>

                <Space direction="vertical" style={{ width: "100%" }}>
            <DebounceSelect
                fetchOptions={fetchIngredients}
                onChange={onIngredientSelected}
                placeholder="Buscar ingrediente"
                style={{ width: "100%" }}
                allowClear
                showSearch
            />
        <Table
            dataSource={ingredients}
            rowKey="objectId"
            pagination={false}
            scroll={{ x: true }}
        >
            <Table.Column
                title="Insumo"
                dataIndex="name"
                key="name"
            />
           
          
            <Table.Column
                title="Cantidad"
                dataIndex="quantity"
                key="quantity"
                render={(text, record) => (
                    <Input
                        type="number"
                        value={text}
                        onClick={(e) => e.target.select()}
                        style={{ width: 80 }}
                        sufix={record?.basePresentation}
                        onChange={(e) => {
                            const value = e.target.value;
                            const ingredient = ingredients.find((p) => p.objectId === record.objectId);
                            ingredient.quantity = value;
                            ingredient.total = ingredient.lastCost * value;
                            setRenderId((prev) => prev + 1);
                        }}
                    />
                )}
            />

            <Table.Column
                title="Costo unitario"
                dataIndex="baseCost"
                key="baseCost"
                render={(text, record) => (
                    <p>${text.toFixed(2)}</p>
                )}
            />

            <Table.Column
                title="Costo total"
                dataIndex="total"
                key="total"
                render={(text, record) => (
                    <p>${(record.baseCost * record.quantity).toFixed(2)}</p>
                )}
            />
            
           
            <Table.Column
                title="Acción"
                key="action"
                render={(text, record) => (
                    <Button
                        type="danger"
                        onClick={() => {
                            setIngredients(ingredients.filter((p) => p.objectId !== record.objectId));
                            setRenderId((prev) => prev + 1);
                        }}
                    >
                        Eliminar
                    </Button>
                )}
            />
        </Table>
        <Title level={5}>Costo total: ${totalCost()}</Title>
        <Title level={5}>Costo por Kg: ${unitCost()}</Title>
        </Space>
            </Form>
        </Modal>
    );
};

export default NewRecipe;
