import React from 'react'
import {
  Row,
  Col,
  Avatar,
  Switch,
  Card,
  Space,
  Statistic,
  Form,
  Input,
  Table
} from 'antd'
import profilavatar from "../../../../images/face-1.jpg";
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';

function onChange(checked) {
  console.log(`switch to ${checked}`);
}

const SupplierInfo = () => {

  return (<Row gutter={[24, 24]}>


    <Col span={20} lg={14} id="my-scroll-layout">
      <Card
        id="profile"
        className="card-profile-head"
        bordered={false}
        bodyStyle={{ display: "none" }}
        title={
          <Row justify="space-between" align="middle" gutter={[24, 0]}>
            <Col span={24} md={12} className="col-info">
              <Avatar.Group>
                <Avatar size={74} shape="square" src={profilavatar} />

                <div className="avatar-info">
                  <h4 className="font-semibold m-0">Costales Grumesa</h4>
                  <text style={{ color: "gray" }}>@Grumesa.costales</text>
                </div>
              </Avatar.Group>
            </Col>
            <Col
              span={24}
              md={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <small className="font-regular mr-5">
                Activo
              </small>
              <Switch defaultChecked onChange={onChange} />
            </Col>
          </Row>
        }
      ></Card>

      <Card
        id="basic-info"
        className="mb-24 header-solid"
        bordered={false}
        title={[
          <h5 className="mb-0" key={0}>
            Información del vendedor
          </h5>,
        ]}
      >
        <Form layout="vertical">
          <Row gutter={[30, 20]}>
            <Col span={24} lg={12}>
              <Form.Item label="Nombre del proveedor" colon={false}>
                <Input placeholder="Costales Grumesa" />
              </Form.Item>
            </Col>
            <Form.Item label="Persona de contacto" colon={false}>
                <Input placeholder="Gonzalo Franco" />
              </Form.Item>
              <Col span={30} lg={20}>
              <Form.Item label="Razón social" colon={false}>
                <Input placeholder="Manuel Gómez Ruíz"  />
              </Form.Item>
            </Col>
            <Col span={24} lg={12}>
              <Form.Item label="Número de teléfono" colon={false}>
                <Input placeholder="3312567439" />
              </Form.Item>
            </Col>
            <Col span={30} lg={20}>
              <Form.Item label="Correo eléctronico" colon={false}>
                <Input placeholder="grumesa@gmail.com" />
              </Form.Item>
            </Col>
            <Col span={24} lg={12}>
              <Form.Item label="Domicilio" colon={false}>
                <Input placeholder="Patria #505, Universidad, Zapopan, Jalisco" />
              </Form.Item>
            </Col>
            <Col span={24} lg={12}>
            <Col span={30} lg={20}>
              <Form.Item label="R.F.C" colon={false}>
                <Input placeholder="XAXX010101000"  />
              </Form.Item>
            </Col>
            </Col>
            <Col span={24} lg={12}>
            </Col>
          </Row>
        </Form>
      </Card>
    </Col>
    <Col span={12} lg={8} id="clientStats">
      <Row>

        <Card title="Volumen de compra Junio" style={{ width: 300 }}>
          <Row gutter={16}>
            <Col span={12}>
              <Statistic
                title="Compras"
                value={"10,000 piezas"}
                valueStyle={{ color: '#a7c957' }} />
              <Statistic
                title="Total de inversión"
                value={"$10,000"}
                valueStyle={{ fontSize: 18 }} />
            </Col>

          </Row>
        </Card>
      </Row>
      <Space>  </Space>
      <Row>
        <Card title="Balance Financiero" style={{ width: 300 }}>
          <p>Pagos (deuda - pagado ) </p>
        </Card>
      </Row>
    </Col>
  </Row>)

}


export  {SupplierInfo};