import Parse from "parse";

const fetchSuppliers = async (search) => {
    return new Promise((resolve) => {
       const query = new Parse.Query("_User");
       query.equalTo("role", "Proveedor");
    //    query.equalTo("role", "Proveedor");

    //      if (search) {
    //         query.matches("name", search, "i");
    //         }

        query.find().then((results) => {
            console.log("results", results);
            const providers = results.map((provider) => ({
                value: provider.id,
                label: provider.get("name"),
                ...provider.toJSON()
            }));
            console.log("providers", providers);
            resolve(providers);
        });
    });
};

const fetchProducts = async (search) => {
    return new Promise((resolve) => {
        const Ingredient = Parse.Object.extend("Ingredient");
        const query = new Parse.Query(Ingredient);
        search && query.matches("name", search, "i");
        query.find().then((results) => {
            const products = results.map((product) => ({
                value: product.id,
                label: product.get("name"),
                units: product.get("presentations")?.map((p) => p.presentation),
                ...product.toJSON()
            }));
            console.log("products", products);
            resolve(products);
        });

    });
}

export { fetchSuppliers, fetchProducts };