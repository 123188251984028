import React, { useEffect, useState } from 'react';
import { Modal, Button, Table, Input, Select, message } from 'antd';
import Parse from 'parse';

const { Option } = Select;

const saveIngredient = (production, ingredients, setOpen) => {
    const Production = Parse.Object.extend('Production');
    const productionObject = new Production();
    productionObject.set('ingredients', ingredients.map(item => ({ name: item.name, quantity: item.added, objectId: item.objectId })));
    productionObject.set('type', 'addIngredients');
    productionObject.save().then((result) => {
        const ProductionOrder = Parse.Object.extend('ProductionOrder');
        const productionOrder = new ProductionOrder();
        productionOrder.set('objectId', production.objectId);
        productionOrder.addUnique('production', result);
        productionOrder.save();
        message.success('Ingredientes agregados correctamente');
        setOpen(false);
    }, (error) => {
        console.error('Error while creating ProductionOrder: ', error);
        message.error('Error al agregar ingredientes');
    });
}

const AddIngredientModal = ({ open, setOpen, production, warehouse }) => {
    const [ingredients, setIngredients] = useState(null);

    useEffect(() => {
        setIngredients(production?.recipe?.ingredients);
    }, [production]);

    return (
        <Modal
            title="Agregar ingrediente"
            visible={open}
            onOk={() => setOpen(false)}
            onCancel={() => setOpen(false)}
            footer={[
                <Button key="back" onClick={() => saveIngredient(production, ingredients, setOpen)}>
                    Confirmar
                </Button>,
            ]}
        >
            <Table 
                dataSource={ingredients?.filter(item => item.warehouse === warehouse)}
                columns={[
                    {
                        title: "Ingrediente",
                        dataIndex: "name",
                        key: "name",
                        render: (text) => <h3>{text}</h3>,
                    },
                    {
                        title: "Cantidad",
                        dataIndex: "quantity",
                        key: "quantity",
                        render: (text, ingredient) => <h3>{ingredient.quantity * production?.productionQuantity}</h3>,
                    },
                    {
                        title: "Unidad",
                        dataIndex: "basePresentation",
                        key: "basePresentation",
                        render: (text) => <h3>{text}</h3>,
                    },
                    // {
                    //     title: "Restante",
                    //     dataIndex: "added",
                    //     key: "added",
                    //     render: (text, ingredient) => <h2>{text}</h2>,
                    // },
                    {
                        title: "Agregar",
                        dataIndex: "added",
                        key: "added",
                        render: (text, ingredient) => <Input 
                            type="number" 
                            value={text}
                            onChange={(e) => setIngredients(prev => prev.map(item => item.name === ingredient.name ? { ...item, added: e.target.value } : item))} />,
                    }
                ]}
            />
        </Modal>
    );
}

export default AddIngredientModal;