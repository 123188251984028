import React, { useState, useEffect } from "react";
import { Button, Menu } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import NavGroup from "./NavGroup";
import NavIcon from "./NavIcon";
import NavBadge from "./NavBadge";
import menuItems from "../../../../utils/menu-items";

const NavContent = (props) => {
  const location = useLocation(); // Use hook to get location
  const [openKeys, setOpenKeys] = useState([]);
  const [rootKeys, setRootKeys] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Update window size on resize
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // useEffect(() => {
  //   // Initialize menu items
  //   let rootKeys = menuItems.items.map((item) => {
  //     if (item.children) {
  //       return item.children.map((child) => child.id);
  //     }
  //     return false;
  //   });
  //   rootKeys = [].concat.apply([], rootKeys);

  //   const keys = location.pathname.split("/");
  //   if (keys[keys.length - 1] !== "dashboard") {
  //     keys.pop();
  //   }
  //   keys.splice(0, 1);
  //   setOpenKeys(keys);
  //   setRootKeys(rootKeys);
  // }, [location]);

  // useEffect(() => {
  //   if (openKeys.length === 0 && windowWidth > 992) {
  //     let rootKeys = menuItems.items.map((item) => {
  //       if (item.children) {
  //         return item.children.map((child) => child.id);
  //       }
  //       return false;
  //     });
  //     rootKeys = [].concat.apply([], rootKeys);

  //     const keys = location.pathname.split("/");
  //     if (keys[keys.length - 1] !== "dashboard") {
  //       keys.pop();
  //     }
  //     keys.splice(0, 1);
  //     setOpenKeys(keys);
  //     setRootKeys(rootKeys);
  //   }

  //   if (windowWidth <= 992 && openKeys.length) {
  //     setOpenKeys([]);
  //   }
  // }, [windowWidth, openKeys, location]);

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);

    if (rootKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const renderItem = (navigation) => {
    if (!navigation) return null;

    return navigation.map((item, index) => {
      switch (item.type) {
        case "group":
          return (
            <React.Fragment key={index}>
              <Menu.Item
                className={`menu-item-header ${
                  item.className ? item.className : ""
                }`}
                key={item.id || index}
              >
                {item.title}
              </Menu.Item>
              {renderItem(item.children)}
            </React.Fragment>
          );
        case "collapse":
          return (
            <Menu.SubMenu
              key={item.id || index}
              icon={
                item.icon
                  ? item.icon(
                      openKeys.includes(item.id),
                      window.sessionStorage.getItem("color_theam")
                    )
                  : ""
              }
              title={item.title}
            >
              <Menu.ItemGroup key={item.id + "1"}>
                {renderItem(item.children)}
              </Menu.ItemGroup>
            </Menu.SubMenu>
          );
        case "item":
          if (item.target) {
            return (
              <Menu.Item key={item.id} className="catlog">
                <a
                  href={item.url}
                  className="nav-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  <NavIcon items={item} />
                  <span className="label"> {item.title}</span>
                  <NavBadge items={item} />
                </a>
              </Menu.Item>
            );
          }
          return (
            <Menu.Item key={item.id}>
              <NavLink to={item.url} className="nav-link" exact={true}>
                <NavIcon items={item} />
                <span className="label">{item.title}</span>
                <NavBadge items={item} />
              </NavLink>
            </Menu.Item>
          );
        default:
          return false;
      }
    });
  };

  const dashboard = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M3 4C3 3.44772 3.44772 3 4 3H16C16.5523 3 17 3.44772 17 4V6C17 6.55228 16.5523 7 16 7H4C3.44772 7 3 6.55228 3 6V4Z"
        fill={props.sidebarColor}
      ></path>
      <path
        d="M3 10C3 9.44771 3.44772 9 4 9H10C10.5523 9 11 9.44771 11 10V16C11 16.5523 10.5523 17 10 17H4C3.44772 17 3 16.5523 3 16V10Z"
        fill={props.sidebarColor}
      ></path>
      <path
        d="M14 9C13.4477 9 13 9.44771 13 10V16C13 16.5523 13.4477 17 14 17H16C16.5523 17 17 16.5523 17 16V10C17 9.44771 16.5523 9 16 9H14Z"
        fill={props.sidebarColor}
      ></path>
    </svg>,
  ];

  const mainContent = (
    <div className="navbar-content datta-scroll">
      <Menu
        theme="light"
        mode="inline"
        openKeys={openKeys}
        onOpenChange={onOpenChange}
      >
        {renderItem(props.navigation)}
      </Menu>
    </div>
  );

  return <div>{mainContent}</div>;
};

export default NavContent;
