import React, { useEffect } from "react";
import { Button, Space, Typography, Modal, Form, Input, Select, message } from "antd";
import Parse, { Role } from "parse";
import { generateRandomPassword } from "../utils/randomPassword";
const { Title } = Typography;

const NewClient = ({ open, setOpen, refresh }) => {
    const [form] = Form.useForm();
    const [renderId, setRenderId] = React.useState(0);

    useEffect(() => {
        form.setFieldsValue({
            email: "Correo electrónico",
            name: "Nombre",
        });
    }, [open]);

    const aclFields = [
        {name: 'Admin', read: true, write: true},
        {name: 'Sales', read: true, write: true},
        ]

    const createClient = () => {
        const client = form.getFieldsValue();
        console.log(client);

        const acl = new Parse.ACL();
        aclFields.forEach((field) => {
            acl.setRoleReadAccess(field.name, field.read);
            acl.setRoleWriteAccess(field.name, field.write);
        });

        const Client = Parse.Object.extend("_User");
        const NewClient = new Client();

        NewClient.set('username', client.email);
        NewClient.set('password', generateRandomPassword());
        NewClient.setACL(acl);

        NewClient.save().then(
            (result) => {
                const parseRole = new Parse.Role();
                parseRole.set('objectId', 'MybOZP8brw');
                parseRole.getUsers().add(result);
                parseRole.save();
                console.log("Client created", result);
                message.success("Nuevo Cliente agregado correctamente");
                refresh();
            },
            (error) => {
                console.error("Error while creating Client: ", error);
                message.error("Error al agregar el nuevo cliente");
            }
        );
    };

    const handleSubmit = () => {
        form.validateFields().then((values) => {
            console.log(values);
            createClient();
            setOpen(false);
        }).catch((error) => {
            console.log(error);
        });
    };

    return (
        <Modal
            title="Nuevo Cliente"
            open={open}
            onCancel={() => setOpen(false)}
            footer={[
                <Button key="back" onClick={() => setOpen(false)}>
                    Cancelar
                </Button>,
                <Button key="submit" type="primary" onClick={handleSubmit}>
                    Guardar
                </Button>,
            ]}
        >
            <Form
                layout="vertical"
                form={form}
                name="basic"
            >
                <Form.Item
                    label="Nombre"
                    name="name"
                    rules={[{ required: true, message: 'Nombre' }]}
                    onClick={() => form.setFieldsValue({ email: '' })}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Correo electrónico"
                    name="email"
                    rules={[{ required: true, message: 'Correo electrónico' }]}
                    onClick={() => form.setFieldsValue({ email: '' })}
                >
                    <Input />
                </Form.Item>
                
            </Form>
        </Modal>
    );
}

export default NewClient;
