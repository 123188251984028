import React, { useEffect } from 'react';
import { Row, Col, Card, Statistic, Steps, Table, Progress, Flex } from 'antd';
import Parse from 'parse';
import { useParams } from 'react-router-dom';
import ProductionSummary from './Tabs/ProductionSummary';
import FormulaWeightDetail from './Tabs/FormulaWeightDetail';
import FatDigestWeightDetail from './Tabs/FatDigestWeightDetail';
import CroquetteWeightDetail from './Tabs/CroquetteWeightDetail';
import PackingDetails from './Tabs/PackingDetails';

const fetchProduction = async (uuid) => {
    const Production = Parse.Object.extend('ProductionOrder');
    const query = new Parse.Query(Production);
    query.equalTo('uuid', uuid);
    query.include(["product", "recipePointer", "ingredientPointers", "presentationPointers", "production", "shrinkage", "packaging"]);
    const result = await query.first();
    return result.toJSON();
}

const grainsPercent = (production) => {
    let percent = (production?.grainsAdded / production?.totalGrains * 100)
    percent = isNaN(percent) ? 0 : percent;
    return percent;
}

const fatPercent = (production) => {
    let percent = production?.fatAdded / production?.totalFat * 100;
    percent = isNaN(percent) ? 0 : percent;
    return percent;
}

const packedPercent = (production) => {
    let percent = production?.packed / production?.packagingQuantity * 100;
    percent = isNaN(percent) ? 0 : percent;
    return percent;
}


const ProductionDetails = () => {
    const [current, setCurrent] = React.useState(0);
    const [production, setProduction] = React.useState(null);
    const { id } = useParams();


    const progressPercent = (current) => {
        if (!production) return 0;
        let percent = 0;
        switch (current) {
            case 0:
                percent = (grainsPercent(production) * .33) + (fatPercent(production) * .33) + (packedPercent(production) * .34);
                break;
            case 1:
               return grainsPercent(production);
            case 2:
               return fatPercent(production);
            case 3:
                percent += production.colors ? 33 : 0;
                percent += production.finalWeight ? 33 : 0;
                percent += production.retrievedFinePowders ? 34 : 0;
                return percent;
            case 4:
               return packedPercent(production);
            default:
                percent = 0;
                break;
        }
        console.log(current, percent);
        return percent;
    }

    const steps = [
        {
            title: <Flex gap="10px" vertical style={{ width: '100%' }}>Resumen<Progress percent={progressPercent(0)} format={() => ''} style={{ width: '5vw' }} /></Flex>,
            content: ProductionSummary,
        },
        {
            title: <Flex gap="10px" vertical style={{ width: '100%' }}>Forraje<Progress percent={progressPercent(1)} format={() => ''} style={{ width: '5vw' }} /></Flex>,
            content: FormulaWeightDetail,
        },
        {
            title: <Flex gap="10px" vertical style={{ width: '100%' }}>Grasa y digesta<Progress percent={progressPercent(2)} format={() => ''} style={{ width: '5vw' }} /></Flex>,
            content: FatDigestWeightDetail,
        },
        {
            title: <Flex gap="10px" vertical style={{ width: '100%' }}>Cocción<Progress percent={progressPercent(3)} format={() => ''} style={{ width: '5vw' }} /></Flex>,
            content: CroquetteWeightDetail,
        },
        {
            title: <Flex gap="10px" vertical style={{ width: '100%' }}>Empaque<Progress percent={progressPercent(4)} format={() => ''} style={{ width: '5vw' }} /></Flex>,
            content: PackingDetails,
        },
    ];

    let CurrentComponent = steps[current].content;


    useEffect(() => {
        fetchProduction(id).then((result) => {
            let production = result;
            let presentations = []
            let ingredients = {}
            let packages = {}
            result.presentations.forEach(presentation => {
                let newPresentation = {
                    quantity: presentation.quantity,
                    ...result.presentationPointers.find(i => i.objectId === presentation.objectId),
                }
                presentations.push(newPresentation);
            });

            result.recipe.ingredients.forEach(ingredient => {
                ingredients[ingredient.objectId] = { ...ingredient, added: 0, total: ingredient.quantity * result.productionQuantity };
            });
            result.production?.forEach(production => {
                production.ingredients.forEach(ingredient => {
                    if (!ingredient.quantity|| ingredient.quantity === '0') return;
                   if (!ingredients[ingredient.objectId].added) {
                    ingredients[ingredient.objectId].added = parseFloat(ingredient.quantity);
                     } else {
                        ingredients[ingredient.objectId].added += parseFloat(ingredient.quantity);
                     }
                });
            });
            production.ingredients = Object.values(ingredients);

            production.packaging?.forEach(packaging => {
                console.log(packaging);
                packaging.ingredients.forEach(pack => {
                    let presentation = presentations.find(presentation => presentation.objectId === pack.objectId);
                    if (!presentation) return;
                    if (!presentation.added) {
                        presentation.added = parseFloat(pack.quantity);
                    } else {
                        presentation.added += parseFloat(pack.quantity);
                    }
                });
            });

            production.presentations = presentations


            let grainsAdded = 0;
            let fatAdded = 0;
            let packed = 0;

            production.ingredients.forEach(ingredient => {
                if (ingredient.warehouse === 'Forrajes') {
                    console.log(ingredient);
                    grainsAdded += parseFloat(ingredient.added);
                } else if (ingredient.warehouse === 'Producción') {
                    fatAdded += parseFloat(ingredient.added);
                }
            });

            production.presentations.forEach(pack => {
                packed += parseFloat(pack.added);
            });


            let totalGrains = production.recipe.ingredients.filter(ingredient => ingredient.warehouse === 'Forrajes').reduce((acc, ingredient) => acc + parseFloat(ingredient.quantity), 0) * production.productionQuantity
            let totalFat = production.recipe.ingredients.filter(ingredient => ingredient.warehouse === 'Producción').reduce((acc, ingredient) => acc + parseFloat(ingredient.quantity), 0) * production.productionQuantity;

            
            production.grainsAdded = grainsAdded;
            production.fatAdded = fatAdded;
            production.totalGrains = totalGrains;
            production.totalFat = totalFat;
            production.packed = packed;
            console.log('packed', packed);

            console.log(production);
            setProduction(production);
        });
    }, [id]);

    const onChange = current => {
        setCurrent(current);
        CurrentComponent = steps[current].content;
    };


    return (
        <Col>
            <h1 style={{ fontSize: '24px', fontWeight: 'bold' }}>Detalles de Producción</h1>
            <h2>{production?.product?.name}</h2>
            <h4>{production?.folio}</h4>
            <Row justify="center" style={{ marginBottom: '20px' }}>
                <Col span={14}>
                    <Steps
                        current={current}
                        onChange={onChange}
                        size="small"
                        direction="horizontal"
                        style={{ width: '62vw' , position: 'relative', left: '-10vw'}}
                    >
                        {steps.map((step, index) => (
                            <Steps.Step key={index} title={step.title} production={production} />
                        ))}
                    </Steps>
                </Col>
            </Row>
            <div className="steps-content" style={{ marginTop: '20px', textAlign: 'center' }}>
                <CurrentComponent production={production} percent={progressPercent(current)} />
            </div>
        </Col>
    );
};

export default ProductionDetails;
