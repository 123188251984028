import React, { Component } from "react";
import { Card, Col, Row, Table, Typography, Tag, Avatar } from "antd";
import animalia from "../../images/animale.jpeg";

const { Title } = Typography;

const SuppliersList= () => {
    const dataSource = [
      {
        img: "https://directoriosvito.com.mx/wp-content/uploads/2018/06/proanlogo.jpg",
        name: 'Proteínas Proan',
        adress: "Francisco Magaña #54, roma, CDMX",
        description: "Provedor de carnes y proteínas", 
        email: 'Proan@gmail.com',
        phone: '55674321894',
        shop: "1 tonelada",
        balance: '$50,000.00',
      },
      {
        img: "https://mundoconstruccion.com/marca/grumesa/logo",
        name: 'Costales grumesa',
        adress: "Patria #505, Universidad, Zapopan, Jalisco",
        description: "Provedor de costal para croqueta", 
        email: 'grumesa.gr@gmail.com',
        phone: '3378032816',
        shop: "10,000 costales",
        balance: '$20,000.00',
      },
      {
        img: "https://w7.pngwing.com/pngs/443/269/png-transparent-logo-brand-fedex-product-desktop-hermes-staff-text-orange-logo.png",
        name: 'Logistica fedex',
        adress: "Servicio a puerta de bodega",
        description: "Servicio de logística", 
        email: 'Clientes@fedex.com',
        phone: '80033339696',
        shop: "30 viajes",
        balance: '$5,000.00',
      },
      {
        img: "https://www.shutterstock.com/image-vector/vehicle-weighing-vector-icon-600nw-738376429.jpg",
        name: 'Bascula integral',
        adress: "Oficinas dasner sauces",
        description: "Sistema de pesaje automatico", 
        email: 'basculaje-integral@hotmail.com',
        phone: '3785671045',
        shop: "1,000 usos",
        balance: '$2,000.00',
      },
      {
        img: "https://static.vecteezy.com/system/resources/previews/010/367/791/non_2x/vitamin-or-nutrition-pet-logo-a-typography-logo-with-a-combination-of-text-leaf-and-puppy-or-dog-vector.jpg",
        name: 'Trigo richard',
        adress: "Morelos #341, Centro, Tepatitlán de Morelos, Jalisco",
        description: "Provedor de  trigo y vitaminas", 
        email: 'Trigo.richard@gmail.com',
        phone: "37812346783",
        shop: "1 tonelada",
        balance: '$40,000.00',
      },
    ];
  
    const columns = [
      {
        dataIndex: "img",
        key: "img",
        render: (img) => <img src={img} alt="" className="avatar-sm mr-10" style={{width: "45px"}}/>,
      },
      {
        title: "Nombre",
        dataIndex: "name",
        key: "name",
        render: (text) => (
          <>
            <h3>{text}</h3>
          </>
        ),
      },{
        title: "Dirección",
        dataIndex: "adress",
        key: "adress",
        render: (text) => (
          <>
            <p>{text}</p>
          </>
        ),
      },{
        title: "Descripción",
        dataIndex: "description",
        key: "description",
        render: (text) => (
          <>
            <p>{text}</p>
          </>
        ),
      },
      {
        title: "Correo electrónico",
        dataIndex: "email",
        key: "email",
        render: (text) => (
          <>
            <p>{text}</p>
          </>
        ),
      },
      {
        title: "Número de teléfono",
        dataIndex: "phone",
        key: "phone",
        render: (text) => (
          <>
            <p>{text}</p>
          </>
        ),
      },
      {
        title: "Volumen de compra",
        dataIndex: "shop",
        key: "shop",
        render: (text) => (
          <>
            <p>{text}</p>
          </>
        ),
      },
      {
        title: "Balance financiero",
        dataIndex: "balance",
        key: "balance",
        render: (text) => (
          <>
            <h3>{text}</h3>
          </>
        ),
      },
    ];

    return (
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <Col lg={24} className="zindex">
            <h2 className="p-15 h2">Proveedores</h2>

            <Card
              bordered={false}
              bodyStyle={{ padding: "0px" }}
              className="criclebox cardbody mb-24 header-solid"
              title={<h3>Proveedores</h3>}
            >
              <div className="ant-list-box table-responsive">
                <Table
                  dataSource={dataSource}
                  columns={columns}
                  rowKey={(record, index) => index}
                  pagination={true}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }

  export default SuppliersList
