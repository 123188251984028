import React, { useEffect } from "react";
import { Button, Space, Typography, Modal, Form, Input, message, Table, Card } from "antd";
// import PresentationList from "./PresentationList";
// import NewPresentation from "./NewPresentation";
import Parse from "parse";
import DebounceSelect from "../../../form/DebounceSelect";
// import NewRecipe from "./NewRecipe";
// import RecipeList from "./RecipeList";
import { Check, XCircle } from "react-bootstrap-icons";
import { v4 as uuidv4 } from "uuid";
const { Title } = Typography;

const pendingVolume = (toProduce, presentations) => {
    if (!presentations?.length) {
        return toProduce;
    }
    let total = toProduce;
    presentations?.forEach((presentation) => {
        total -= (presentation.quantity * presentation.content);
    });
    return total;
}

const NewProductionOrder = ({ open, setOpen, refresh }) => {
    const [form] = Form.useForm();
    const [product, setProduct] = React.useState(null);
    const [products, setProducts] = React.useState([]);
    const [recipe, setRecipe] = React.useState(null);
    const [presentations, setPresentations] = React.useState([]);
    const [renderId, setRenderId] = React.useState(0);

    useEffect(() => {
        form.setFieldsValue({
            name: ""
        });
        setPresentations([]);
        setRecipe(null);
    }, [open]);

    const handleSubmit = async () => {
        console.log(product.recipe)
        let recipe = product.recipe;
        delete recipe.ingredientPointers;
        delete recipe.__type;

        const Index = Parse.Object.extend("Index");
        const indexQuery = new Parse.Query(Index);
        indexQuery.equalTo("name", "ProductionOrder");
        indexQuery.equalTo('year', new Date().getFullYear());
        const index = await indexQuery.first();


        const ProductionOrder = Parse.Object.extend("ProductionOrder");
        const productionOrder = new ProductionOrder();
        productionOrder.set("product", { __type: "Pointer", className: "Product", objectId: product.value });
        productionOrder.set("productionQuantity", parseInt(form.getFieldValue("quantity")));
        productionOrder.set("packagingQuantity", parseInt(presentations.reduce((acc, item) => acc + item.quantity, 0)));
        productionOrder.set("presentations", presentations.map((presentation) => ({
            objectId: presentation.objectId,
            quantity: parseInt(presentation.quantity)
        })));
        productionOrder.set("presentationPointers", presentations);
        productionOrder.set("recipePointer", { __type: "Pointer", className: "Recipe", objectId: recipe.objectId });
        productionOrder.set("ingredientPointers", recipe.ingredients.map((ingredient) => ({
            __type: "Pointer",
            className: "Ingredient",
            objectId: ingredient.objectId
        })));
        productionOrder.set('recipe', recipe);
        productionOrder.set("folio", 'P' + (index.get('value')+1).toString().padStart(4, '0') + '-' + new Date().getFullYear());
        productionOrder.set("produced", 0);
        productionOrder.set("packed", 0);
        productionOrder.set('uuid', uuidv4());
        productionOrder.save().then((productionOrder) => {
            index.increment('value');
            index.save();
            message.success("Orden de producción guardada correctamente");
            form.resetFields();
            refresh();
            setOpen(false);
        }).catch((error) => {
            console.log(error);
        })
    };
    


    console.log("product", product);

    return (
        <>
            {/* <NewPresentation open={newPresentation} setOpen={setNewPresentation} addPresentation={(presentation) => {
                form.setFieldsValue({
                    presentations: [...form.getFieldValue("presentations"), presentation],
                });
                setRenderId(renderId + 1);
            }
            } /> */}
        <Modal
            title="Nueva orden de producción"
            open={open}
            width={800}
            onCancel={() => setOpen(false)}
            footer={[
                <Button key="back" onClick={() => setOpen(false)}>
                    Cancelar
                </Button>,
                <Button key="submit" type="primary" onClick={handleSubmit} disabled={pendingVolume(form.getFieldValue("quantity"), presentations) !== 0}>
                    Guardar
                </Button>,
            ]}
        >
            <Form
                layout="vertical"
                form={form}
                name="basic"
            >
                <Form.Item
                    label="Producto"
                    name="product"
                    rules={[{ required: true, message: 'Por favor seleccione un producto' }]}
                >
                    <DebounceSelect
                        fetchOptions={async (search) => {
                            const Product = Parse.Object.extend("Product");
                            const query = new Parse.Query(Product);
                            query.contains("name", search);
                            query.include(["recipe", "presentations"]);
                            const results = await query.find();
                            const products = results.map((result) => ({
                                value: result.id,
                                label: result.get("name"),
                                ...result.toJSON()
                            }));
                            console.log("products", products);
                            setProducts(products);
                            return products;
                        }}
                        onChange={(product) => {
                            let productObject = products.find((p) => p.value === product.value);
                            setProduct(productObject);
                            setPresentations(productObject.presentations.map((presentation) => ({
                                ...presentation,
                                quantity: 0
                            })));
                        }}
                        placeholder="Buscar producto"
                        style={{ width: "100%" }}
                        allowClear
                        showSearch
                    />
                </Form.Item>

                <Form.Item 
                    label="Cantidad a producir"
                    name="quantity"
                    rules={[{ required: true, message: 'Por favor ingrese la cantidad a producir' }]}
                    shouldUpdate
                >
            <Input type="number" suffix={product?.recipe?.baseUnit} style={{ width: "30%", alignSelf: "flex-end" }} onChange={(e) => {
                setRenderId(renderId + 1);
                form.setFieldsValue({ quantity: e.target.value });
            }} 
            value={form.getFieldValue("quantity")} />
        </Form.Item>

                
                <h2 style={{ marginBottom: 0 }}>Receta</h2>
                <h3 style={{ marginTop: 0 }}>{product?.recipe?.name}</h3>
                <h4>Costo base: ${product?.recipe?.baseCost}</h4>
                <h4>Peso final: {product?.recipe?.finalWeight} {product?.recipe?.baseUnit}</h4>
                <h4 style={{ marginBottom: 10 }}>Ingredientes</h4>
                <Table dataSource={product?.recipe?.ingredients} rowKey="objectId" pagination={false} scroll={{ x: true }}>
               
            <Table.Column
                title="Ingrediente"
                dataIndex="name"
                key="name"
            />
            <Table.Column
                title="Cantidad"
                dataIndex="quantity"
                key="quantity"
                render={(text, record) => (
                    <p>{(record.quantity * form.getFieldValue("quantity")).toFixed(2)}</p>
                )}
            />

            <Table.Column
                title="Costo unitario"
                dataIndex="baseCost"
                key="baseCost"
                render={(text, record) => (
                    <p>${text.toFixed(2)}</p>
                )}
            />

            <Table.Column
                title="Costo total"
                dataIndex="total"
                key="total"
                render={(text, record) => (
                    <p>${(record.baseCost * record.quantity * form.getFieldValue("quantity")).toFixed(2)}</p>
                )}
            />

            <Table.Column
                title="Disponibilidad"
                dataIndex="available"
                key="available"
                render={(text, record) => (
                    <p>{record.stock > form.getFieldValue("quantity") ? <Check style={{ color: "green" }} size={40} /> : <XCircle style={{ color: "red" }} size={30} />}</p>
                )}
            />
        </Table>

        
        <h3 style={{ marginBottom: 10 }}>Costo total: ${product?.recipe?.totalCost * form.getFieldValue("quantity")}</h3>
        
        <h2 style={{ marginBottom: 0 }}>Presentaciones</h2>
        {product?.presentations?.map((presentation) => (
            <Space key={presentation.objectId} style={{ display: "flex", marginBottom: 8 }} align="baseline">
                <Card title={presentation.name} style={{ width: 300 }}>
                    <p>Costo: ${presentation.totalCost}</p>
                    <p>Contenido: {presentation.content} {presentation.baseUnit}</p>
                    <p>Precio mínimo: ${presentation.baseCost}</p>
                    <Input type="number" placeholder="Cantidad" style={{ width: 120 }} onChange={(e) => {
                        console.log("e", e.target.value);
                        const newPresentations = presentations.map((p) => {
                            if (p.objectId === presentation.objectId) {
                                return { ...p, quantity: e.target.value };
                            }
                            return p;
                        });
                        setPresentations(newPresentations);
                    }
                    } />
                </Card>
            </Space>
        ))}

        <h2 style={{ marginBottom: 10 }}>Volumen restante: {pendingVolume(form.getFieldValue("quantity"), presentations)}</h2>
            </Form>
        </Modal>
        </>
    );
}

export default NewProductionOrder;