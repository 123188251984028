import React from 'react';
import { Row, Col, Card, Statistic, Steps, Table, Progress, Flex, Button, message } from 'antd';
import HopperModal from './Modals/HopperModal';
import AddIngredientModal from './Modals/AddIngredientModal';

var total = 0

const dataSource = [
    { ingredient: "Sorgo", quantity: "40kg" },
    { ingredient: "Trigo", quantity: "10kg" },
    { ingredient: "Maíz", quantity: "18kg" },
];



dataSource.forEach(item => {
    const quantity = parseInt(item.quantity.replace('kg', ''), 10);
    total = total + quantity;
});

const FormulaWeightDetail = ({ production }) => {
    const [showHopperModal, setShowHopperModal] = React.useState(false);
    const [showAddIngredientModal, setShowAddIngredientModal] = React.useState(false);

    const columns = [
        {
            title: "Ingrediente",
            dataIndex: "name",
            key: "name",
            render: (text) => <h3>{text}</h3>,
        },
        {
            title: "Cantidad",
            dataIndex: "quantity",
            key: "quantity",
            render: (text, product) => <h3>{text * production?.productionQuantity} {product.basePresentation}</h3>,
        },
        {
            title: "Agregado",
            dataIndex: "added",
            key: "added",
            render: (text, ingredient) => <Progress percent={(text / ingredient.total) * 100} format={() => `${text} / ${ingredient.total}`} />,
        }
    ];

    console.log(production.ingredients);

    return (
        <Col span={24}>
            <HopperModal open={showHopperModal} setOpen={setShowHopperModal} production={production} />
            <AddIngredientModal open={showAddIngredientModal} setOpen={setShowAddIngredientModal} production={production} warehouse="Forrajes" />
            <Row gutter={24}>
            <Col span={8} xs={24} md={8}>
            <Card
                        bordered={false}
                        bodyStyle={{ padding: '10px' }}
                        style={{ margin: '10px', width:"100%"}}
                        className="criclebox cardbody mb-24 header-solid"
                        >
                            <Statistic
                                title="Peso agregado"
                                value={production?.grainsAdded}
                                suffix="KG"
                                style={{ fontSize: '20px' }}
                            />
                        </Card>
                </Col>
                <Col span={8} xs={24} md={8}>
                <Card
                        bordered={false}
                        bodyStyle={{ padding: '10px' }}
                        style={{ margin: '10px', width:"100%"}}
                        className="criclebox cardbody mb-24 header-solid"
                        >
                            <Statistic
                                title="Peso total"
                                value={production?.recipe?.ingredients?.filter(ingredient => ingredient.warehouse === 'Forrajes').reduce((acc, ingredient) => acc + parseFloat(ingredient.quantity), 0) * production?.productionQuantity}
                                suffix="KG"
                                style={{ fontSize: '20px' }}
                            />
                        </Card>
                </Col>
                <Col span={8} xs={24} md={8}>
                    <Card
                        bordered={false}
                        bodyStyle={{ padding: '10px' }}
                        style={{ margin: '10px' }}
                        className="criclebox cardbody mb-24 header-solid"
                        hoverable
                        onClick={() => setShowHopperModal(true)}
                        >
                            <Statistic
                                title="Tolva"
                                value={production?.hopper ? production?.hopper : 'No asignada'}
                                style={{ fontSize: '20px' }}
                            />
                        
                        </Card>
                </Col>
               
            </Row>
            <Row gutter={24}>
                <Card
                    bordered={false}
                    bodyStyle={{ padding: "0px" }}
                    style={{ padding: 20, width: "100%" }}
                    className="criclebox cardbody mb-24 header-solid"
                    title={<Flex justify="space-between" align="middle"><h2>Ingredientes</h2><Button type="primary" onClick={() => !production?.hopper ? message.error('Asigna una tolva primero') : setShowAddIngredientModal(true)}>Agregar ingrediente</Button></Flex>}
                >
                    <div className="ant-list-box table-responsive">
                        <Table
                            style={{ width: '100%' }}
                            dataSource={production?.ingredients?.filter(ingredient => ingredient.warehouse === 'Forrajes')}
                            columns={columns}
                            rowKey={(record, index) => index}
                            pagination={true}
                        />
                    </div>
                </Card>
            </Row>
        </Col>
    )
}

export default FormulaWeightDetail;