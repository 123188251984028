import React, { useState } from 'react';
import { Modal, Button, Form, Input, Select, message } from 'antd';
import Parse from 'parse';
const { Option } = Select;

const saveFinalWeight = (production, finePowders, setOpen) => {
    const ProductionOrder = Parse.Object.extend('ProductionOrder');
    const productionOrder = new ProductionOrder();
    productionOrder.set('objectId', production.objectId);
    productionOrder.set('finalWeight', finePowders);
    productionOrder.save().then((result) => {
        message.success('Peso final registrado correctamente');
        setOpen(false);
    }, (error) => {
        console.error('Error while creating ProductionOrder: ', error);
        message.error('Error al registrar el peso final');
    });
}

const SetFinalWeightModal = ({ open, setOpen, production }) => {
    const [finalWeight, setFinalWeight] = useState(null);

    return (
        <Modal
            title="Establecer peso final"
            visible={open}
            onOk={() => setOpen(false)}
            onCancel={() => setOpen(false)}
            footer={[
                <Button key="back" onClick={() => saveFinalWeight(production, finalWeight, setOpen)}>
                    Confirmar
                </Button>,
            ]}
        >
          <Input type='number' placeholder="Cantidad" onChange={(e) => setFinalWeight(e.target.value)} value={finalWeight} suffix="Kg"/>
          <h4>Peso esperado: {production?.productionQuantity} Kg</h4>
        </Modal>
    );
}

export default SetFinalWeightModal;