import React, { useEffect, useState, useContext } from "react";
import { Table, Typography, Button, Space, notification, message, Select, Modal, Tag, Flex, Tabs, Card, Steps } from "antd";
import Parse from "parse/lib/browser/Parse";
import NewPayment from "./NewPayment";
import NewWarehouseOrder from "./NewWarehouseOrder";
import moment from "moment";
import Notes from "./Notes";
import { Printer, Envelope, Whatsapp, Pencil, CircleFill } from "react-bootstrap-icons";
import { UserContext } from "../../../../context/UserContext";

import NewQuote from "./NewQuote";
import { set } from "parse/lib/browser/CoreManager";

const { Title } = Typography;

const SaleDetail = ({ open, setOpen, sale, refresh, shouldRefresh, setSale }) => {
    const [tab, setTab] = useState("summary");
    const [openPayment, setOpenPayment] = useState(false);
    const [openWarehouseOrder, setOpenWarehouseOrder] = useState(false);
    const [renderId, setRenderId] = useState(0);
    const [printing, setPrinting] = useState(false);
    const [sendingEmail, setSendingEmail] = useState(false);
    const [sales, setSales] = useState([]);
    const [editing, setEditing] = useState(false);
    const { permissions } = useContext(UserContext);

    const allowEdit = () => {
        if (!sale) return false;
        if (sales?.some((s) => s.status === 'Venta')) return false;
        if (permissions.some((p) => p.group === 'quotes' && p.action === 'modifyAll')) return true;
        if (permissions.some((p) => p.group === 'quotes' && p.action === 'modifyOwn')) {
            if (sale.seller.objectId === Parse.User.current().id) return true;
        }
        return false;
    }

    const allowConfirm = () => {
        if (!sale) return false;
        if (sale.status !== 'Cotización') return false;
        if (!sale.active) return false;
        if (permissions.some((p) => p.group === 'quotes' && p.action === 'confirmAll')) return true;
        if (permissions.some((p) => p.group === 'quotes' && p.action === 'confirmOwn')) {
            if (sale.seller.objectId === Parse.User.current().id) return true;
        }
        return false;
    }

    const allowAuthorize = () => {
        if (!sale) return false;
        if (sale.status !== 'Confirmada') return false;
        if (!sale.active) return false;
        if (permissions.some((p) => p.group === 'quotes' && p.action === 'authorizeAll')) return true;
        return false;
    }

    const allowAddPayment = () => {
        if (!sale) return false;
        if (!sale.active) return false;
        if (permissions.some((p) => p.group === 'receivableAccounts' && p.action === 'addPayment')) return true;
        return false;
    }

    const allowSendWarehouseOrder = () => {
        if (!sale) return false;
        if (!sale.active) return false;
        if (permissions.some((p) => p.group === 'warehouses' && p.action === 'createWarehouseRelease')) return true;
        return false;
    }


    const fetchAllVersions = async () => {
        if (!sale) return;
        const Sale = Parse.Object.extend('Sales');
        const query = new Parse.Query(Sale);
        query.equalTo('quoteNumber', sale.quoteNumber);
        query.ascending('createdAt');
        query.include(['client', 'seller', 'payments', 'outlets', 'comments']);
        const results = await query.find();
        console.log("📦 Results: ", results);
        setSales(results?.map((result) => result.toJSON()));
    }

    useEffect(() => {
       setRenderId(Math.random());
    }, [shouldRefresh]);

    useEffect(() => {
        fetchAllVersions();
    }, [sale]);

    const confirmSale = async (sale) => {
        const Sale = Parse.Object.extend('Sales');
        const saleObject = new Sale();
        saleObject.set('objectId', sale.objectId);
        saleObject.set('status', 'Confirmada');
        saleObject.set('confirmationDate', new Date());

        try {
            await saleObject.save();
            message.success('Venta confirmada correctamente');
            refresh();
        }
        catch (error) {
            console.error('Error while confirming Sale: ', error);
            message.error('Error al confirmar la venta');
        }
    }

    const authorizeSale = async (sale) => {
        const indexQuery = new Parse.Query('Index');
        indexQuery.equalTo("name", "Sale");
        indexQuery.equalTo("year", new Date().getFullYear());
        const index = await indexQuery.first();

        console.log("📦 Result: ", index);
    
        const Sale = Parse.Object.extend('Sales');
        const saleObject = new Sale();
        saleObject.set('objectId', sale.objectId);
        saleObject.set('status', 'Venta');
        saleObject.set('authorizationDate', new Date());
        saleObject.set('saleNumber', 'V' + (index.get('value')+1).toString().padStart(4, '0') + '-' + new Date().getFullYear());

        const _User = Parse.Object.extend('_User');
        const user = new _User();
        user.set('objectId', sale.client.objectId);
        user.increment('balance', sale.total);
        user.addUnique('sales', saleObject);
        
        
        try {
            await saleObject.save();
            index.increment('value');
            await index.save();
            await user.save();
            message.success('Venta confirmada correctamente');
            refresh();
        }
        catch (error) {
            console.error('Error while confirming Sale: ', error);
            message.error('Error al confirmar la venta');
        }
    }


    const print = async () => {
        const numberOfProducts = sale.products.reduce((acc, product) => acc + product.quantity, 0);

        setPrinting(true);
        const params = {
            customerName: sale.client.fullName,
            deliveryAddress: sale.deliveryAddress?.address,
            invoiceId: sale.saleNumber + '-' + sale.version || sale.quoteNumber + '-' + sale.version,
            invoiceDate: sale.createdAt,
            items: sale.products.map((product) => ({
                // name: product.name,
                name: product.presentation.name,
                quantity: product.quantity,
                price:  sale.deliveryPrice ? parseFloat(product.price) + (sale.deliveryPrice/numberOfProducts) : parseFloat(product.price),
                tax: isNaN(parseFloat(product.tax)) ? 0 : parseFloat(product.tax),
            })),
            totalAmount: parseFloat(sale.total),
            deliveryDate: sale.deliveryDate.iso,
            deliveryPlace: sale?.deliveryPlace,
            useCardStyle: true,
        };

        try {
        const response = await fetch('http://localhost:3001/print/serviceOrder', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(params),
        });
        const data = await response.json();
       
            const pdfWindow = window.open();
            pdfWindow.document.write(
                `<iframe width='100%' height='100%' src='data:application/pdf;base64,${data.pdf}'></iframe>`
            );
        }
        catch (error) {
            // Catch if pop-up is blocked
            console.error('Error opening PDF:', error);
            notification.error({
                message: 'Error',
                description: 'Please allow pop-ups for this website',
            });
        }
        finally {
            setPrinting(false);
        }
    }

    const sendEmail = async () => {
        setSendingEmail(true);
        const numberOfProducts = sale.products.reduce((acc, product) => acc + product.quantity, 0);

        const info = {
            customerName: sale.client.fullName,
            deliveryAddress: sale.deliveryAddress?.address,
            invoiceId: sale.saleNumber + '-' + sale.version || sale.quoteNumber + '-' + sale.version,
            invoiceDate: sale.createdAt,
            items: sale.products.map((product) => ({
                // name: product.name,
                name: product.presentation.name,
                quantity: product.quantity,
                price:  sale.deliveryPrice ? parseFloat(product.price) + (sale.deliveryPrice/numberOfProducts) : parseFloat(product.price),
                tax: isNaN(parseFloat(product.tax)) ? 0 : parseFloat(product.tax),
            })),
            totalAmount: parseFloat(sale.total),
            deliveryDate: sale.deliveryDate.iso,
            deliveryPlace: sale?.deliveryPlace,
            useCardStyle: true,
        };

        const params = {
            to: sale.client.email,
            subject: 'Cotización',
            text: 'Por favor, encuentre la cotización adjunta.',
            info,
            filename: 'Cotización Dasner ' + (sale.quoteNumber) + '.pdf',
            url: 'https://app.dasnerpetcare.com/sales/AcceptQuote/' + sale.uuid,
        };

        try {
        const response = await fetch('http://localhost:3002/sendEmail', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(params),
        });
        const data = await response.json();
        console.log("📧 Data: ", data);
        message.success('Correo enviado correctamente');
        }
        catch (error) {
            console.error('Error sending email:', error);
            notification.error({
                message: 'Error',
                description: 'Error al enviar el correo',
            });
        }
        finally {
            setSendingEmail(false);
        }
    }


    const OwnButton = ({ icon, children, ...props }) => {
        const [showLabel, setShowLabel] = useState(false);
        return <Button {...props} style={{ display: "flex", justifyContent: "center", alignItems: "center" }} onMouseEnter={() => setShowLabel(true)} onMouseLeave={() => setShowLabel(false)}>{icon}{showLabel && children}</Button>
    }

    const buttons = () => 
        <Flex justify="end" gap={10} style={{ marginBottom: 10 }}>
            {sale?.active && <OwnButton icon={<Printer />} onClick={print} loading={printing}>Imprimir</OwnButton>}
            {sale?.active && <OwnButton icon={<Envelope />} onClick={() => sendEmail()} loading={sendingEmail}>Enviar por correo</OwnButton>}
            {sale?.active && <OwnButton icon={<Whatsapp />} onClick={() => console.log("📲 Enviar por WhatsApp")}>Enviar por WhatsApp</OwnButton>}
            {allowEdit() && <OwnButton icon={<Pencil />} onClick={() => setEditing(true)}>Editar</OwnButton>}
        </Flex>

if (!sale) return null;

    return (
        <Modal
            open={open}
            onCancel={() => setOpen(null)}
            footer={null}
            closable={false}
            style={{ top: 20, width: "90%", height: "90%" }}
            width="90%"
            height="90%"
        >
            <NewPayment open={openPayment} setOpen={setOpenPayment} object={sale} parseClass="Sales" refresh={refresh} />
            <NewQuote open={editing} setOpen={setEditing} sale={sale} refresh={refresh} editing refreshSale={fetchAllVersions} versionNumbner={sales.length} />
            <NewWarehouseOrder open={openWarehouseOrder} setOpen={setOpenWarehouseOrder} object={sale} parseClass="Sales" refresh={refresh} />
            <Flex justify="between">
                <Flex justify="space-between" align="flex-start" vertical style={{ width: "100%" }}>
                    <Tag color="blue">{sale?.status}</Tag>
                    <Title level={4}>Detalle de la venta</Title>
                </Flex>
                {buttons()}
            </Flex>
            <Flex justify="between" style={{ marginBottom: 10 }}>
               
            <Flex justify="between" vertical style={{ marginBottom: 10, width: "80%" }}>
            <Space>
               <Tabs defaultActiveKey="summary" onChange={(key) => setTab(key)}>
                    <Tabs.TabPane tab="Resumen" key="summary" />
                    <Tabs.TabPane tab="Productos" key="products" />
                    <Tabs.TabPane tab="Pagos" key="payments" />
                    <Tabs.TabPane tab="Entregas" key="deliveries" />
                    <Tabs.TabPane tab="Comentarios" key="comments" />
                </Tabs>
            </Space>
            {
                tab === "summary" && (
                    <Card>
                        <Flex justify="between" style={{ marginBottom: 10 }}>
                            <Title level={5}>Cliente</Title>
                            <p>{sale?.client.name}</p>
                        </Flex>
                        <Flex justify="between" style={{ marginBottom: 10 }}>
                            <Title level={5}>Vendedor</Title>
                            <p>{sale?.seller.name}</p>
                        </Flex>
                        <Flex justify="between" style={{ marginBottom: 10 }}>
                            <Title level={5}>Fecha de entrega</Title>
                            <p>{moment(sale?.deliveryDate?.iso).format("DD/MM/YYYY")}</p>
                        </Flex>
                        <Flex justify="between" style={{ marginBottom: 10 }}>
                            <Title level={5}>Lugar de entrega</Title>
                            <p>{sale?.deliveryPlace}</p>
                        </Flex>
                        {sale?.deliveryPlace === 'Domicilio' && <Flex justify="between" style={{ marginBottom: 10 }}>
                            <Title level={5}>Dirección de entrega</Title>
                            <p>{sale?.deliveryAddress?.address}</p>
                        </Flex>}
                        <Flex justify="between" style={{ marginBottom: 10 }}>
                            <Title level={5}>Precio de entrega</Title>
                            <p>${sale?.deliveryPrice?.toLocaleString()}</p>
                        </Flex>
                        <Flex justify="between" style={{ marginBottom: 10 }}>
                            <Title level={5}>Notas de entrega</Title>
                            <p>{sale?.deliveryNotes}</p>
                        </Flex>
                        <Flex justify="between" style={{ marginBottom: 10 }}>
                            <Title level={5}>Total</Title>
                            <p>${sale?.total?.toLocaleString()}</p>
                        </Flex>
                        <Flex justify="between" style={{ marginBottom: 10 }}>
                            <Title level={5}>Total pagado</Title>
                            <p>${sale?.totalPaid?.toLocaleString()}</p>
                        </Flex>
                    </Card>
                )
            }
            {tab === "products" && (
                <Flex justify="end" vertical>
                <Table
                    dataSource={sale?.products}
                    rowKey="objectId"
                    pagination={false}
                    scroll={{ x: true }}
                >
                    <Table.Column
                        title="Producto"
                        dataIndex="name"
                        key="name"
                    />
                    <Table.Column
                        title="Presentación"
                        dataIndex="presentation"
                        key="presentation"
                        render={(text, product) => console.log("📦 Product: ", product) || product.presentation.name}
                    />
                    <Table.Column
                        title="Cantidad"
                        dataIndex="quantity"
                        key="quantity"
                    />
                    <Table.Column
                        title="Precio"
                        dataIndex="price"
                        key="price"
                    />
                    <Table.Column
                        title="Total"
                        key="total"
                        render={(text, product) => product.price * product.quantity}
                    />
                </Table>
                {allowConfirm() && <Button type="primary" onClick={() => confirmSale(sale)}>Confirmar venta</Button>}
                {allowAuthorize() && <Button type="primary" onClick={() => authorizeSale(sale)}>Autorizar venta</Button>}
                </Flex>
            )}
            {tab === "payments" && (
                <Flex justify="end" vertical>
                    <Table  
                        dataSource={sale?.payments}
                        rowKey="objectId"
                        pagination={false}
                        scroll={{ x: true }}
                    >
                        <Table.Column
                            title="Fecha"
                            dataIndex="paymentDate"
                            key="paymentDate"
                            render={(text) => moment(text).format("DD/MM/YYYY")}
                        />
                        <Table.Column
                            title="Método"
                            dataIndex="method"
                            key="method"
                        />
                        <Table.Column
                            title="Monto"
                            dataIndex="amount"
                            key="amount"
                        />
                    </Table>
                    <Title level={4}>Saldo: ${(sale.total - sale.totalPaid).toLocaleString()}</Title>
                    {allowAddPayment() && <Button type="primary" onClick={() => setOpenPayment(true)}>Agregar pago</Button>}
                </Flex>
            )}
            {
                tab === "deliveries" && (
                    <Flex justify="end" vertical>
                    <Table
                        dataSource={sale?.outlets}
                        rowKey="objectId"
                        pagination={false}
                        scroll={{ x: true }}
                    >
                        <Table.Column
                            title="Fecha"
                            dataIndex="createdAt"
                            key="createdAt"
                            render={(text) => moment(text).format("DD/MM/YYYY")}
                        />
                        <Table.Column
                            title="Productos"
                            dataIndex="products"
                            key="products"
                            render={(products) => products.map((product) => (
                                <p>{product.delivered} {product.name}</p>
                            ))}
                        />
                        <Table.Column 
                            title="Entregado por"
                            dataIndex="user"
                            key="user"
                            render={(text) => text.name}
                        />
                        <Table.Column
                            title="Notas"
                            dataIndex="notes"
                            key="notes"
                        />
                    </Table>
                    {allowSendWarehouseOrder() && <Button type="primary" onClick={() => setOpenWarehouseOrder(true)}>Generar orden de salida</Button>}
                    </Flex>
                )
            }
            {
                tab === "comments" && <Notes object={sale} parseClass="Sales" refresh={refresh} shouldRefresh={renderId} />
            }
            </Flex>
            <Flex justify="flex-start" style={{ marginTop: 10, marginLeft: 10 }} align="start" vertical>
                <Title level={5}>Versión</Title>
                <Steps current={sales.findIndex((s) => s.objectId === sale.objectId)} direction="vertical" style={{ width: "100%" }}>
                    {sales.map((s) => (
                        <Steps.Step title={moment(s.createdAt).format("DD/MM/YYYY HH:mm")} description={s.modificationComment} onClick={() => setSale(s)} />
                    ))}
                </Steps>
                </Flex>
            </Flex>

        </Modal>
    );
}

export default SaleDetail;