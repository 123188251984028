import React, { useState } from "react";
import { Row, Col, Card, Table, Switch, Statistic, Progress } from "antd";

const objective = 10000;
const currentSells = 600;

const SellerSales = () => {
  const [dataSource, setData] = useState([
    {
      key: '1',
      Date: "16/06/2024",
      Order: "D0001",
      cost: '$2,450.00',
      paid: true,
    },
    {
      key: '2',
      Date: "17/06/2024",
      Order: "D0002",
      cost: '$3,500.00',
      paid: false,
    },
    {
      key: '3',
      Date: "18/06/2024",
      Order: "D0003",
      cost: '$1,250.00',
      paid: true,
    },
  ]);

  const handlePaidChange = (checked, record) => {
    const newData = dataSource.map(item => {
      if (item.key === record.key) {
        return { ...item, paid: checked };
      }
      return item;
    });
    setData(newData);
  };

  const StatisticsCard = () => {
    const percent = ((currentSells / objective) * 100).toFixed(2);

    return (
      <Card title="Estadísticas" style={{ width: "100%" }}>
        <Row gutter={16}>
          <Col span={12}>
            <Statistic
              title="Objetivo"
              value={objective}
              valueStyle={{ color: '#3f8600' }}
              prefix="$"
            />
          </Col>
          <Col span={12}>
            <Statistic
              title="Avances en Ventas"
              value={percent}
              precision={2}
              suffix="%"
            />
            <Progress percent={parseFloat(percent)}/>
          </Col>
        </Row>
      </Card>
    );
  };

  const columns = [
    {
      title: "Fecha",
      dataIndex: "Date",
      key: "Date",
      render: (text) => <h3>{text}</h3>,
    },
    {
      title: "Orden de compra",
      dataIndex: "Order",
      key: "Order",
      render: (text) => <h3>{text}</h3>,
    },
    {
      title: "Precio",
      dataIndex: "cost",
      key: "cost",
      render: (text) => <h3>{text}</h3>,
    },
    {
      title: "Pagado",
      dataIndex: "paid",
      key: "paid",
      render: (paid, record) => (
        <Switch
          checked={paid}
          onChange={(checked) => handlePaidChange(checked, record)}
        />
      ),
    },
  ];

  return (
    <Col>
      <Row>
        <StatisticsCard />
      </Row>
      <Row>
        <Col span={24} lg={24} className="zindex">
          <Card
            bordered={false}
            bodyStyle={{ padding: "0px" }}
            className="criclebox cardbody mb-24 header-solid"
            title={<h2>Clientes</h2>}
          >
            <div className="ant-list-box table-responsive">
              <Table
                style={{ width: '100%' }}
                dataSource={dataSource}
                columns={columns}
                rowKey="key"
                pagination={true}
              />
            </div>
          </Card>
        </Col>
      </Row>
    </Col>
  );
};

export { SellerSales };
