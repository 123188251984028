import React, { useEffect } from "react";
import { Button, Space, Typography, Modal, Form, Input, message } from "antd";
import IngredientList from "./IngredientList";
import PresentationList from "./PresentationList";
import NewPresentation from "./NewPresentation";
import Parse from "parse";
import NewRecipe from "./NewRecipe";
import RecipeList from "./RecipeList";
const { Title } = Typography;

const NewProduct = ({ open, setOpen, refresh }) => {
    const [form] = Form.useForm();
    const [renderId, setRenderId] = React.useState(0);
    const [newPresentation, setNewPresentation] = React.useState(false);
    const [recipe, setRecipe] = React.useState(null);
    const [presentations, setPresentations] = React.useState([]);

    useEffect(() => {
        form.setFieldsValue({
            name: ""
        });
        setPresentations([]);
        setRecipe(null);
    }, [open]);

    const handleSubmit = async () => {
        form.validateFields().then(async (values) => {

            const toSave = [];
            const Presentation = Parse.Object.extend("Presentation");
            presentations.forEach((presentation) => {
                const presentationObject = new Presentation();
                presentation.objectId && presentationObject.set("objectId", presentation.objectId);
                presentationObject.set("name", presentation.name);
                presentationObject.set("content", presentation.content);
                presentationObject.set("packaging", presentation.packaging);
                presentationObject.set("baseCost", presentation.baseCost);
                presentationObject.set("totalCost", presentation.totalCost);
                toSave.push(presentationObject);
            });

            const savedPresentations = await Parse.Object.saveAll(toSave);
                
            const Product = Parse.Object.extend("Product");
            const product = new Product();
            product.set("name", values.name);
            product.set("presentations", savedPresentations);
            product.set("recipe", {__type: "Pointer", className: "Recipe", objectId: recipe.value});
            product.save().then((product) => {
                message.success("Producto guardado correctamente");
                refresh();
                setOpen(false);
                form.resetFields();
            }).catch((error) => {
                console.log(error);
            })
            setOpen(false);
        }).catch((error) => {
            console.log(error);
        });
    };


    return (
        <>
            {/* <NewPresentation open={newPresentation} setOpen={setNewPresentation} addPresentation={(presentation) => {
                form.setFieldsValue({
                    presentations: [...form.getFieldValue("presentations"), presentation],
                });
                setRenderId(renderId + 1);
            }
            } /> */}
        <Modal
            title="Nuevo producto"
            open={open}
            width={800}
            onCancel={() => setOpen(false)}
            footer={[
                <Button key="back" onClick={() => setOpen(false)}>
                    Cancelar
                </Button>,
                <Button key="submit" type="primary" onClick={handleSubmit}>
                    Guardar
                </Button>,
            ]}
        >
            <Form
                layout="vertical"
                form={form}
                name="basic"
            >
                <Form.Item
                    label="Nombre"
                    name="name"
                    rules={[{ required: true, message: 'Por favor ingrese el nombre del ingrediente' }]}
                >
                    <Input />
                </Form.Item>
               
                        {/* <Form.Item label="Receta" name={['recipe']} rules={[{ required: true, message: 'Seleccione al menos un ingrediente' }]}> */}
                            <RecipeList setRecipe={setRecipe} recipe={recipe} />
                        {/* </Form.Item> */}
                        
                        <Title level={5}>Presentaciones</Title>
                        {/* <Form.Item label="Presentaciones" name={['presentations']} rules={[{ required: true, message: 'Seleccione al menos una presentación' }]}> */}
                            <PresentationList setRenderId={setRenderId} recipe={recipe} presentations={presentations} setPresentations={setPresentations} />
                        {/* </Form.Item> */}
            </Form>
        </Modal>
        </>
    );
}

export default NewProduct;