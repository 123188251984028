import React, { useEffect, useState, useRef } from 'react';
import { Button, Modal, Form, Input, Space, Table, Flex, Tooltip, Badge, message } from 'antd';
import CameraModal from '../../../media/Camera';
import Ribbon from 'antd/es/badge/Ribbon';
import Parse from 'parse';

const NewInventoryEntry = ({ open, setOpen, entryInfo, refresh }) => {
    const [form] = Form.useForm();
    const [entry, setEntry] = useState(entryInfo);
    const [images, setImages] = useState([]);
    const [openCamera, setOpenCamera] = useState(false);

    useEffect(() => {
        if (!open) {
            setEntry(null);
            setImages([]);
            form.resetFields();
        }
        else {
            setEntry(entryInfo);
            setImages([]);
        }
    }, [open]);


    const handleSubmit = async () => {
        form.validateFields().then(async (values) => {
            const Index = Parse.Object.extend("Index");
            const indexQuery = new Parse.Query(Index);
            indexQuery.equalTo("name", "WarehouseEntry");
            indexQuery.equalTo('year', new Date().getFullYear());
            const index = await indexQuery.first();

            const WarehouseEntry = Parse.Object.extend("WarehouseEntry");
            const entryObject = new WarehouseEntry();
            entryObject.set("user", Parse.User.current());
            entryObject.set("images", images);
            entryObject.set("folio", 'E' + (index.get('value')+1).toString().padStart(4, '0') + '-' + new Date().getFullYear());
            entryObject.set("purchaseOrder", { __type: "Pointer", className: "PurchaseOrder", objectId: entryInfo.objectId });
            console.log("entryInfo", entryInfo);
            console.log("entry", entry);
            const totalPurchase = entry.products.reduce((acc, product) => acc + parseInt(product.quantity), 0);
            const totalReceived = entry.products.reduce((acc, product) => acc + parseInt(product.received), 0);
            const totalRemaining = entry.products.reduce((acc, product) => acc + parseInt(product.remaining), 0);
            console.log("totalPurchase", totalPurchase);
            console.log("totalReceived", totalReceived);
            console.log("totalRemaining", totalRemaining);
            const Ingredient = Parse.Object.extend("Ingredient");
            let ingredients = [];
            entry.products.forEach((product) => {
                const ingredient = new Ingredient();
                ingredient.set("objectId", product.objectId);
                console.log('Incrementing stock by: ', parseFloat(product.received) * product.baseQuantity);
                ingredient.increment("stock", parseFloat(product.received) * product.baseQuantity);
                ingredient.set('baseCost', product.cost / product.baseQuantity);
                ingredients.push(ingredient);
            });
            entryObject.set("products", entry.products.map((product) => {
                return {
                    ...product,
                    received: parseInt(product.received),
                    remaining: parseInt(product.remaining) - parseInt(product.received),
                };
            }));
            entryObject.set("productPointers", entry.products.map((product) => {
                return { __type: "Pointer", className: "Product", objectId: product.objectId };
            }));
            let toSave = []
            entryObject.save().then((result) => {
                const PurchaseOrder = Parse.Object.extend("PurchaseOrder");
                const purchaseOrder = new PurchaseOrder();
                purchaseOrder.set("objectId", entryInfo.objectId);
                totalReceived >= totalRemaining ? purchaseOrder.set("deliveries", "Recibido") : purchaseOrder.set("deliveries", "Parcial");
                purchaseOrder.addUnique("entries", { __type: "Pointer", className: "WarehouseEntry", objectId: result.id });
                index.increment('value', 1);
                toSave.push(index)
                toSave.push(purchaseOrder)
                toSave.push(...ingredients)
                
                
                Parse.Object.saveAll(toSave).then((results) => {
                    message.success('Entrada de almacén creada correctamente');
                    refresh();
                    setOpen(false);
                }
                ).catch((error) => {
                    message.success('Entrada de almacén creada correctamente');
                    refresh();
                    setOpen(false);
                    console.log(error);
                }
                );
            }
            ).catch((error) => {
                console.log(error);
            }
            );

        }).catch((error) => {
            console.log(error);
        });
    }

    useEffect(() => {
        setEntry(entryInfo);
    }, [entryInfo]);

    return (
        <Modal
            title="Nueva entrada de inventario"
            open={open}
            onCancel={() => setOpen(false)}
            footer={[
                <Button key="back" onClick={() => setOpen(false)}>
                    Cancelar
                </Button>,
                <Button key="submit" type="primary" onClick={handleSubmit}>
                    Guardar
                </Button>,
            ]}
        >
            <h2>Orden de compra: {entry?.folio}</h2>
            <h3>Proveedor: {entry?.supplier?.name}</h3>
           <Table
                dataSource={entry?.products}
                columns={[
                    {
                        title: "Producto",
                        dataIndex: "name",
                        key: "name",
                        render: (text) => (
                            <p>{text}</p>
                        ),
                    },
                    {
                        title: "Cantidad",
                        dataIndex: "quantity",
                        key: "quantity",
                        render: (text, product) => (
                            <p>{text} {product.presentation?.toLowerCase()}{text !== 1 ? 's' : ''}</p>
                        ),
                    },
                    {
                        title: "Restante",
                        dataIndex: "remaining",
                        key: "remaining",
                        render: (text, product) => (
                            <p>{text} {product.presentation?.toLowerCase()}{text !== 1 ? 's' : ''}</p>
                        ),
                    },
                    {
                        title: 'Recibido',
                        dataIndex: 'received',
                        key: 'received',
                        render: (text, product) => (
                            <Input 
                                type="number"
                                disabled={parseInt(product.remaining) <= 0}
                                style={{ color: parseInt(product.remaining) === parseInt(text) ? 'green' : 'red' }} 
                                suffix={product.presentation + (parseInt(text) !== 1 ? 's' : '')}
                                onChange={(e) => {
                                    const products = entry.products
                                        .map((p) => {
                                            if (p.name === product.name) {
                                                return { ...p, received: e.target.value };
                                            }
                                            return p;
                                        });
                                    setEntry({ ...entry, products });
                                }}
                            />
                                
                        )
                    }
                ]}
                rowKey={(record, index) => index}
                pagination={false}
            />
            <Button type="primary" onClick={() => setOpenCamera(true)}>Agregar foto</Button>
            <Flex direction="horizontal" style={{ marginTop: 20, overflowX: 'scroll' }}>
                {images && images.map((image, index) => (
                    <Space direction="vertical" style={{ marginRight: 10, paddingTop: 10 }}>
                        <Badge title="Eliminar" onClick={() => setImages(images.filter((_, i) => i !== index))} count="X" style={{ cursor: 'pointer' }}>
                            <img key={index} src={image} alt={`Foto ${index}`} style={{ width: 90, height: 160, objectFit: 'cover' }} />
                        </Badge>
                    </Space>
                ))}
            </Flex>
            <CameraModal open={openCamera} setOpen={setOpenCamera} onImageTook={setImages} />
        </Modal>
    );
}

export default NewInventoryEntry;
