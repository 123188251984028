import React, { useEffect } from 'react';
import { Row, Col, Avatar, Switch, Card, Statistic, Table, Segmented, Space } from 'antd';

import { useLocation } from 'react-router-dom';

import MovementsTable from './components/IngredientTable';
import IngredientCharts from './components/IngredientCharts';

function onChange(checked) {
  console.log(`switch to ${checked}`);
}

const InventoryDetails = () => {
  const [item, setItem] = React.useState(null);
  const [chartGroup, setChartGroup] = React.useState('Entradas');
  const location = useLocation();
  
  useEffect(() => {
    setItem(location.state.item);
  }, [location]);

  return (
    <Row gutter={[24, 24]}>
      <Col span={8} md={12} lg={12}>
        <Card
          id="profile"
          className="card-profile-head"
          bordered={false}
          bodyStyle={{ display: "none" }}
          title={
            <Row justify="space-between" align="middle" gutter={[24, 0]}>
              <Col span={24} md={12} className="col-info">
                <Avatar.Group style={{ display: "flex", alignItems: "center", padding: "10px" }}>
                  {/* <Avatar size={74} shape="square" src="https://http2.mlstatic.com/D_NQ_NP_961228-MLM72596894113_102023-O.webp" /> */}
                  <div className="avatar-info">
                    <h4 className="font-semibold m-0">{item?.name}</h4>
                    <div style={{ color: "gray" }}>Unidad base: {item?.basePresentation}</div>
                    <div style={{ color: "gray" }}>Último costo: ${item?.baseCost}</div>
                  </div>
                </Avatar.Group>
              </Col>
              <Col
                span={24}
                md={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <small className="font-regular mr-5">
                  Activo
                </small>
                <Switch defaultChecked onChange={onChange} checked={item?.isActive} />
              </Col>
            </Row>
          }
        ></Card>
      </Col>
      <Col span={8} md={8} lg={6} id="clientStats">
        <Card title="Existencia" style={{ width: "100%" }}>
          <Row gutter={16}>
              <Statistic
                title={item?.basePresentation}
                value={item?.stock}
                valueStyle={{ color: '#a7c957' }} />
          </Row>
        </Card>
      </Col>
      <Col span={8} md={8} lg={6} id="clientStats">
        <Card title="Valor total" style={{ width: "100%" }}>
          <Row gutter={16}>
              <Statistic
                title="Total"
                prefix="$"
                value={item?.stock * item?.baseCost}
                valueStyle={{ fontSize: 18 }} />
          </Row>
        </Card>
      </Col>

      <Col span={24}>
        <IngredientCharts item={item} />
      </Col>

      <Col span={24}>
      <MovementsTable item={item} />
      </Col>
    </Row>
  )
}

export default InventoryDetails;
