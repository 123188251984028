import React, { useEffect, useState } from "react";
import { Card, Col, Row, Table, Select } from "antd";
import ReactApexChart from "react-apexcharts";

const FinanceList = () => {
    
    const [datosFinancieros, setDatosFinancieros] = useState([
        { date: '2023-01-01', type: 'Ingreso', amount: 200, account: 'Banamex', balance: 200},
        { date: '2023-01-02', type: 'Egreso', amount: -50, account: 'Santander', balance: 150},
        { date: '2023-01-03', type: 'Ingreso', amount: 3200, account: 'Banamex', balance: 3350},
        { date: '2023-01-04', type: 'Egreso', amount: -1100, account: 'Banregio', balance: 2250},
        { date: '2023-01-05', type: 'Ingreso', amount: 5200, account: 'Santander', balance: 7450},
        { date: '2023-01-06', type: 'Ingreso', amount: 10200, account: 'Santander', balance: 17650},
    ]);


    const [series, setSeries] = useState([]);

   
    const [options, setOptions] = useState({
        chart: {
            type: 'area',
            height: 350,
            width: '100%',
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },
        xaxis: {
            categories: [] 
        },
        tooltip: {
            x: {
                format: 'yyyy-MM-dd'
            },
        },
        legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetY: -20
        }
    });

    
    const columns = [
        {
            title: "Fecha",
            dataIndex: "date",
            key: "date",
            render: (text) => <h3>{text}</h3>,
        },
        {
            title: "Tipo",
            dataIndex: "type",
            key: "type",
        },
        {
            title: "Cantidad",
            dataIndex: "amount",
            key: "amount",
            render: (text, item) => <h3 style={{ color: item.type === 'Ingreso' ? 'green' :'orange' }}>{text}</h3>
        },
        {
            title: "Cuenta",
            dataIndex: "account",
            key: "account",
            render: (text) => <h3>{text}</h3>,
        },
        {
            title: "Balance",
            dataIndex: "balance",
            key: "balance",
            render: (text) => <h3>{text}</h3>,
        },
    ];

    // Efecto para ordenar datos y calcular balance
    useEffect(() => {
        // Ordenar datos por fecha ascendente
        const datosOrdenados = [...datosFinancieros].sort((a, b) => new Date(a.date) - new Date(b.date));

        // Calcular ingresos, egresos y balance
        let ingresos = [];
        let egresos = [];
        let balance = [];

        let totalIngresos = 0;
        let totalEgresos = 0;

        datosOrdenados.forEach((item) => {
            totalIngresos += item.ingress;
            totalEgresos += item.egress;

            ingresos.push(totalIngresos);
            egresos.push(totalEgresos);
            balance.push(totalIngresos - totalEgresos);
        });

        // Actualizar series y categorías en las opciones del gráfico
        setSeries([
            { name: 'Ingreso', data: datosFinancieros.map((item) => item.type === 'Ingreso' ? item.amount : 0)},
            { name: 'Egreso', data: datosFinancieros.map((item) => item.type === 'Egreso' ? item.amount : 0)},
            { name: 'Balance', data: datosFinancieros.map((item) =>  item.balance )},
        ]);

        setOptions((prevOptions) => ({
            ...prevOptions,
            xaxis: {
                ...prevOptions.xaxis,
                categories: datosOrdenados.map((item) => item.date)
            }
        }));

    }, [datosFinancieros]);

    return (
        <Col span={24}>
            <Row gutter={16}>
                <Col span={8} md={8}>
                    <AccountCard
                        banco="Banamex"
                        monto="$10,000.00"
                        clabe="123456789012345678"
                        tipoCuenta="Cuenta Pagos"
                        logo="https://1000marcas.net/wp-content/uploads/2020/10/Citibanamex-logo.png"
                    />
                </Col>
                <Col span={8} md={8}>
                    <AccountCard
                        banco="Santander"
                        monto="$50,000.00"
                        clabe="987654321098765432"
                        tipoCuenta="Cuenta Proveedores"
                        logo="https://www.liderlogo.es/wp-content/uploads/2022/12/pasted-image-0-4-2-1024x360.png"
                    />
                </Col>
                <Col span={8} md={8}>
                    <AccountCard
                        banco="Banregio"
                        monto="$1,000.00"
                        clabe="567890123456789012"
                        tipoCuenta="Cuenta Clientes"
                        logo="https://upload.wikimedia.org/wikipedia/commons/e/e7/Banregio.png"
                    />
                </Col>
            </Row>
            <Row gutter={16}>
                <Card
                    style={{ padding: 20, width: "100%" }}
                    bordered={false}
                    bodyStyle={{ padding: "0px" }}
                    className="criclebox cardbody mb-24 header-solid"
                    title={<h2>Gráfica de Balance</h2>}
                >
                    <div className="ant-list-box table-responsive">
                        <ReactApexChart
                            className="full-width"
                            options={options}
                            series={series}
                            type="area"
                            height={350}
                            width={"100%"}
                        />
                    </div>
                </Card>
            </Row>
            <Row gutter={16}>
                <Card
                    bordered={false}
                    bodyStyle={{ padding: "0px" }}
                    style={{ padding: 20, width: "100%" }}
                    className="criclebox cardbody mb-24 header-solid"
                    title={<h2>Producción</h2>}
                >
                    <div className="ant-list-box table-responsive">
                        <Table
                            style={{ width: '100%' }}
                            dataSource={datosFinancieros}
                            columns={columns}
                            rowKey={(record, index) => index}
                            pagination={true}
                        />
                    </div>
                </Card>
            </Row>
        </Col>
    );
};

const AccountCard = ({ banco, monto, clabe, tipoCuenta, logo }) => (
    <Card
        hoverable
        style={{ width: 300, margin: '20px' }}
        cover={<img alt={banco} src={logo} style={{ height: 100, objectFit: 'contain' }} />}
    >
        <Card.Meta
            title={banco}
            description={
                <div>
                    <p><strong>Monto:</strong> {monto}</p>
                    <p><strong>CLABE:</strong> {clabe}</p>
                    <p><strong>Tipo de cuenta:</strong> {tipoCuenta}</p>
                </div>
            }
        />
    </Card>
);

export default FinanceList;
