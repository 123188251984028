import Parse from 'parse';

const fetchInventory = async () => {
    return new Promise((resolve) => {
        const Inventory = Parse.Object.extend("Ingredient");
        const query = new Parse.Query(Inventory);
        query.include("product");
        query.find().then((results) => {
            const inventory = results.map((entry) => ({
                value: entry.id,
                ...entry.toJSON()
            }));
            resolve(inventory);
        });
    });
}

const fetchIngredients = async () => {
    const Ingredients = Parse.Object.extend('Ingredient');
    const query = new Parse.Query(Ingredients);
    query.limit(10);
    const results = await query.find();
    const ingredients = results.map((result) => ({
        key: result.id,
        label: result.get('name'),
        value: result.id,
        ...result.toJSON()
    }));
    return ingredients;
}

const fetchPackaging = async (value) => {
    const Ingredients = Parse.Object.extend('Ingredient');
    const query = new Parse.Query(Ingredients);
    query.equalTo('warehouse', 'Empacado');
    value && query.matches('name', value, 'i');
    query.limit(10);
    const results = await query.find();
    const ingredients = results.map((result) => ({
        key: result.id,
        label: result.get('name'),
        value: result.id,
        ...result.toJSON()
    }));
    return ingredients;
}

export { fetchInventory, fetchIngredients, fetchPackaging };