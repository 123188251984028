import React, { useEffect, useState, useContext } from "react";
import { Card, Col, Row, Table, Typography, FloatButton, Progress, Tag, Tooltip, Flex } from "antd";
import ReactApexChart from "react-apexcharts";
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import NewQuote from "./components/NewQuote";
import Parse from "parse";
import moment from "moment";
import SaleDetail from "./components/SaleDetail";
import { UserContext } from "../../../context/UserContext";

const { Title } = Typography;

const fetchSales = async () => {
  const Sale = Parse.Object.extend("Sales");
  const query = new Parse.Query(Sale);
  query.descending("createdAt");
  query.equalTo("active", true);
  query.include(["client", "products", "payments", "deliveries", "comments.author", "outlets"]);
  const results = await query.find();
  return results.map((result) => result.toJSON());
};

const SalesList = () => {
  const [newQuoteModal, setNewQuoteModal] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [sales, setSales] = useState([]);
  const [sale, setSale] = useState(null);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const { user, permissions } = useContext(UserContext);

  useEffect(() => {
    fetchSales().then((results) => setSales(results));
  }, [shouldRefresh]);

  const refresh = () => {
    setShouldRefresh(!shouldRefresh);
  };


  // Calcular la suma total de los totales
  const totalSales = sales?.reduce((acc, curr) => acc + curr.total, 0);

  const renderDeliveryPlace = (deliveryPlace, sale) => {
    console.log("📍 Delivery Place: ", deliveryPlace);
    if (deliveryPlace === 'Pie de fábrica') {
      return 'Pie de fábrica';
    }
    // Get the previous term from the delivery address terms array
    let terms = sale.deliveryAddress?.terms;
    if (terms) {
      return terms[terms.length - 3].value + ', ' + terms[terms.length - 2].value
    } else {
      return sale.deliveryAddress?.address;
    }
  }

  const columns = [
   
    {
      title: "Fecha",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => <h3>{moment(text).format("DD/MM/YYYY")}</h3>,
    },
    {
      title: "Folio",
      dataIndex: "quoteNumber",
      key: "quoteNumber",
      render: (text, sale) => <p onClick={() => {
        setSale(sale);
        setShowDetail(true);
      }}>{sale?.saleNumber || sale?.quoteNumber}</p>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => <Tag color="green">{text}</Tag>,
    },
    {
      title: "Cliente",
      dataIndex: "client",
      key: "client",
      render: (client) => <Tooltip title={<Flex vertical gap="10px">
        <p>{client.name}</p>
        <p>{client.rfc}</p>
        <p>{client.email}</p>
        <p>{client.phone}</p>
      </Flex>}>
        <p>{client.name}</p>
      </Tooltip>,
    },
    {
      title: "Vendedor",
      dataIndex: "seller",
      key: "seller",
      render: (text) => <p>{text?.fullName}</p>,
    },
    // {
    //   title: "Productos",
    //   dataIndex: "products",
    //   key: "products",
    //   render: (text) => <p>{text}</p>,
    // },
    {
      title: "Lugar de entrega",
      dataIndex: "deliveryPlace",
      key: "deliveryPlace",
      render: (deliveryPlace, sale) =><Tooltip title={sale.deliveryAddress?.address}>
        <p>{renderDeliveryPlace(deliveryPlace, sale)}</p>
      </Tooltip>,
    },
    {
      title: 'Entregas',
      dataIndex: 'deliveries',
      key: 'deliveries',
      render: (text, entry) => {
        let deliveredVolume = 0;
        const expectedVolume = entry.products?.reduce((acc, product) => acc + product.quantity, 0);
        if (entry.outlets) {
          deliveredVolume = entry.outlets.reduce((acc, delivery) => acc + delivery?.products.reduce((acc, product) => acc + product.delivered, 0), 0);
        }
        if (deliveredVolume === 0) {
          return 'Sin entregas';
        }
        // Percentage to 2 decimal places
        const percentage = ((deliveredVolume /expectedVolume) * 100).toFixed(0);
        console.log("🚚 Delivered Volume: ", deliveredVolume, percentage)
        return <Progress percent={percentage}  style={{ width: 100 }} format={() => deliveredVolume < expectedVolume ? percentage + '%' : 'Entregado'} />
      }
    },
    {
      title: 'Pagos',
      dataIndex: 'payments',
      key: 'payments',
      render: (text, entry) => {
        let paidAmount = entry.totalPaid || 0;
        // Percentage to 2 decimal places
        const percentage = ((paidAmount / entry.total) * 100).toFixed(2);
        if (paidAmount === 0) {
          return 'Sin pagos';
        }
        return <Progress percent={percentage} style={{ width: 100 }} format={() => '$' + paidAmount.toLocaleString()} />
      }
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (text) => <h3>${text.toLocaleString()}</h3>,
    },
  ];

  const newSaleButton = () => {
    console.log("🔒 Permissions: ", permissions);
    if (permissions.find(p => p?.group === 'quotes' && p?.action === 'create')) {
      return <FloatButton style={{ right: 20, bottom: 20 }} tooltip="Nueva venta" icon=<PlusOutlined /> onClick={() => setNewQuoteModal(true)} />
    }
  }

  return (
    <div className="layout-content">
      <NewQuote open={newQuoteModal} setOpen={setNewQuoteModal} refresh={refresh} />
      <SaleDetail open={showDetail} setOpen={setShowDetail} sale={sale} refresh={refresh} shouldRefresh={shouldRefresh} setSale={setSale} />
      {newSaleButton()}
       {/* <Row>
        <Col span={24}>
          <Card
            style={{ padding: 20 }}
            bordered={false}
            bodyStyle={{ padding: "0px" }}
            className="criclebox cardbody mb-24 header-solid"
            title={<h3 style={{fontSize:"25px"}}>Estadística de Ventas</h3>}
          >
            <div className="ant-list-box table-responsive">
              <div className="linechart">
                <div></div>
                <div className="sales">
                  <ul>
                    <li><MinusOutlined /> Kilos</li>
                    <li><MinusOutlined /> Venta en $</li>
                  </ul>
                </div>
              </div>

              <ReactApexChart
                className="full-width"
                options={options}
                series={chartData.series}
                type="area"
                height={350}
                width={"100%"}
              />
            </div>
          </Card>
        </Col>
      </Row> */}
      <Row gutter={[24, 0]}>
        <Col lg={24} className="zindex">
          <h2 className="p-15 h2">Ventas</h2>

          <Card
            bordered={false}
            bodyStyle={{ padding: "0px" }}
            className="criclebox cardbody mb-24 header-solid"
            title={<h3 style={{fontSize:"25px"}}>Historial de Ventas</h3>}
          >
            <div className="ant-list-box table-responsive">
              <Table
                dataSource={sales}
                columns={columns} // Usar las columnas con la suma total
                rowKey={(record, index) => index}
                pagination={true}
                footer={() => (
                  <div style={{ textAlign: "right", fontSize:"20px" }}>
                    <strong>Total:</strong> ${totalSales.toLocaleString()}
                  </div>
                )}
              />
            </div>
          </Card>
        </Col>
      </Row>
      
    </div>
  );
};

export default SalesList;
