
import Parse from 'parse';

const fetchPurchaseOrders = async () => {
    return new Promise((resolve) => {
        const PurchaseOrder = Parse.Object.extend("PurchaseOrder");
        const query = new Parse.Query(PurchaseOrder);
        query.include("supplier");
        query.include("user");
        query.include("entries")
        query.find().then((results) => {
            const purchaseOrders = results.map((order) => ({
                value: order.id,
                ...order.toJSON()
            }));
            resolve(purchaseOrders);
        });
    });
}

const fetchWarehouseEntries = async () => {
    return new Promise((resolve) => {
        const WarehouseEntry = Parse.Object.extend("WarehouseEntry");
        const query = new Parse.Query(WarehouseEntry);
        query.include(['user', 'purchaseOrder', 'purchaseOrder.supplier']);
        query.find().then((results) => {
            const warehouseEntries = results.map((entry) => ({
                value: entry.id,
                ...entry.toJSON()
            }));
            resolve(warehouseEntries);
        });
    });
}

export { fetchWarehouseEntries, fetchPurchaseOrders };