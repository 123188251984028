import React, { useEffect, useState } from "react";
import { Flex, Button, Typography, Table, Modal, Checkbox, message, Select } from "antd";
import Parse from "parse";

// Fetch users and their permissions
const queryUsersWithPermissions = async () => {
    const query = new Parse.Query(Parse.User);
    const users = await query.find();

    // Fetch permissions for each user
    const usersWithPermissions = await Promise.all(users.map(async (user) => {
        const permissionsRelation = user.relation('permissions');
        const userPermissions = await permissionsRelation.query().find();
        return {
            ...user.toJSON(), // Add user data
            permissions: userPermissions.map(permission => permission.toJSON()) // Add permissions data
        };
    }));

    return usersWithPermissions;
};

// Fetch roles with users and permissions
const queryRoles = async () => {
    const query = new Parse.Query(Parse.Role);
    query.include(['users', 'permissions']);
    const roles = await query.find();
    const rolesWithDetails = await Promise.all(roles.map(async (role) => {
        const usersRelation = role.relation('users');
        const users = await usersRelation.query().find();

        const permissionsRelation = role.relation('permissions');
        const permissions = await permissionsRelation.query().find();

        return {
            ...role.toJSON(),
            users: users?.map(user => user.toJSON()), // Convert to array of user names
            permissions: permissions.map(permission => permission.toJSON()), // Convert to array of permission names
        };
    }));

    return rolesWithDetails;
};

// Fetch all permissions (global permissions)
const queryPermissions = async () => {
    const query = new Parse.Query('Permission');
    const permissions = await query.find();
    return permissions?.map((permission) => permission.toJSON());
};

const RoleModal = ({ open, setOpen }) => {
    if (open) return (
        <Modal
            title={open?.name}
            open={open}
            onCancel={() => setOpen(false)}
            footer={[
                <Button key="close" onClick={() => setOpen(false)}>
                    Close
                </Button>
            ]}
        >
            <ul>
                {open?.permissions.map(permission => (
                    <li key={permission.objectId}>{permission.groupName}: {permission.name}</li>
                ))}
            </ul>
        </Modal>
    );
};


const RolesList = () => {
    const [roles, setRoles] = useState(undefined);
    const [permissions, setPermissions] = useState([]);

    useEffect(() => {
        // Fetch users with their individual permissions and roles
        const getUsersAndRoles = async () => {
            const usersWithPermissions = await queryUsersWithPermissions();
            const roles = await queryRoles();
            const permissions = await queryPermissions();

            // Merge role permissions with user permissions
            const usersWithMergedPermissions = usersWithPermissions.map(user => {
                // Collect all permissions from roles
                console.log('Roles:', roles);
                const rolePermissions = roles
                    .filter(role => role.users.some(roleUser => roleUser.objectId === user.objectId))
                    .flatMap(role => role.permissions); // Flatten all role permissions

                console.log('Role permissions:', rolePermissions);

                // Merge role permissions with user-specific permissions
                const mergedPermissions = [...new Set([...rolePermissions, ...user.permissions])]; // Remove duplicates with Set

                return {
                    ...user,
                    role: roles.find(role => role.users.some(roleUser => roleUser.objectId === user.objectId)),
                    rolePermissions,
                    userPermissions: user.permissions, 
                    mergedPermissions,
                    permissions: mergedPermissions
                };
            });

            console.log('Users with merged permissions:', usersWithMergedPermissions);

            setRoles(roles);
            setPermissions(permissions);
        };

        getUsersAndRoles();
    }, []); // Empty dependency array to ensure it runs only once

   
   const renderPermissions = (role) => {
    const permissionGroups = permissions.reduce((acc, permission) => {
        const group = acc.find(group => group.groupName === permission.groupName);
        if (group) {
            group.permissions.push(permission);
        } else {
            acc.push({
                groupName: permission.groupName,
                permissions: [permission]
            });
        }
        return acc;
    }, []);

        return <Table
            dataSource={permissionGroups}
            columns={[
                {
                    title: 'Group',
                    dataIndex: 'groupName',
                    key: 'groupName',
                },
                {
                    title: 'Permissions',
                    dataIndex: 'permissions',
                    key: 'permissions',
                    render: permissions => permissions.map(permission => (
                                        <Checkbox 
                                            checked={role.permissions.some(rolePermission => rolePermission.objectId === permission.objectId)}
                                            onChange={async (e) => {
                                                try {
                                                    const roleQuery = new Parse.Query(Parse.Role);
                                                    const roleObject = await roleQuery.get(role.objectId);
                                                    const permissions = roleObject.relation('permissions');
                                                    
                                                    const permissionQuery = new Parse.Query('Permission');
                                                    const permissionObject = await permissionQuery.get(permission.objectId);
                                                    
                                                    let newRole = { ...roles.find(r => r.objectId === role.objectId) }; // Create a copy of the role object
                                                    
                                                    if (e.target.checked) {
                                                        permissions.add(permissionObject);
                                                        await roleObject.save();
                                                        newRole.permissions = [...newRole.permissions, permissionObject.toJSON()]; // Add permission immutably
                                                    } else {
                                                        permissions.remove(permissionObject);
                                                        await roleObject.save();
                                                        newRole.permissions = newRole.permissions.filter(
                                                            rolePermission => rolePermission.objectId !== permission.objectId
                                                        ); // Remove permission immutably
                                                    }
                                                
                                                    setRoles(roles.map(r => (r.objectId === role.objectId ? newRole : r))); // Replace the updated role
                                                } catch (error) {
                                                    console.error('Error:', error);
                                                    message.error('Ocurrió un error al modificar el permiso');
                                                }                                                    
                                            }
                                        }>
                                            {permission.name}
                                        </Checkbox>
                    )   
                    )
                }
            ]}
        />;
    }

    return (
        <Flex vertical gap={20}>
            <Typography.Title level={2}>Roles</Typography.Title>
            <Table
                dataSource={roles?.map(role => ({ ...role, key: role.objectId }))}
                expandable={{
                    expandedRowRender: user => {
                        return (
                            <div>
                               
                            <ul>
                               {renderPermissions(user)}
                            </ul>
                            </div>
                        );
                    },
                }}
                loading={!roles}
                columns={[
                    {
                        title: 'Nombre',
                        dataIndex: 'name',
                        key: 'name',
                        sortDirections: ['descend', 'ascend'],
                        sorter: (a, b) => a.name.localeCompare(b.name),
                    },
                    {
                        title: 'Permisos',
                        dataIndex: 'permissions',
                        key: 'permissions',
                        render: permissions => permissions.length,
                    },
                    {
                        title: 'Usuarios',
                        dataIndex: 'users',
                        key: 'users',
                        render: users => users.length,
                    },
                    
                    
                ]}
            />
        </Flex>
    );
};

export default RolesList;
