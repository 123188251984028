import Parse from "parse";

const fetchVisits = async (sellerId) => {
    return new Promise((resolve, reject) => {
        const User = Parse.Object.extend("Comment");
        const query = new Parse.Query(User);
        query.equalTo("objectId", sellerId);
        query.find()
            .then((results) => {
                resolve(results.map((result) => {
                    return {
                        ...result.toJSON(),
                        value: result.get("name"),
                        label: result.get("name"),
                        key: result.id,
                    };
                }));
            })
            .catch((error) => {
                console.error("Error al obtener los vendedores:", error); // Verifica si hay algún error
                reject(error);
            });
    });
};

const fetchClients = async () => {
    return new Promise((resolve, reject) => {
        const User = Parse.Object.extend("_User");
        const query = new Parse.Query(User);
        query.equalTo("role", "Cliente");
        query.find()
            .then((results) => {
                resolve(results.map((result) => {
                    return {
                        ...result.toJSON(),
                        value: result.get("name"),
                        label: result.get("name"),
                        key: result.id,
                    };
                }));
            })
            .catch((error) => {
                console.error("Error al obtener los clientes:", error); // Verifica si hay algún error
                reject(error);
            });
    });
};

export { fetchVisits, fetchClients };
