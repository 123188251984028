import React from 'react'
import {
  Row,
  Col,
  Avatar,
  Switch,
  Card,
  Space,
  Statistic,
  Form,
  Input,
  Table,
  Select
} from 'antd'
import profilavatar from "../../../../images/face-1.jpg";
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';


const columns = [
  // {
  //   title: 'Descripción',
  //   dataIndex: 'description',
  //   key: 'description',

  // },
  {
    title: 'Fecha',
    dataIndex: 'fecha',
    key: 'fecha',
  },
  {
    title: 'Kgs',
    dataIndex: 'kgs',
    key: 'kgs',
  },
  {
    title: 'Total',
    dataIndex: 'total',
    key: 'total',
  },
];

const data = [
  {
    key: '1',
    description: 'Croqueta perro',
    kgs: 25,
    total: '$9,000',
    fecha: '23/05/24',
  },
  {
    key: '2',
    description: 'Croqueta gato',
    kgs: 10,
    total: '$2,000',
    fecha: '22/05/24',
  },
  {
    key: '3',
    description: 'Croqueta gato',
    kgs: 3,
    total: '$800',
    fecha: '15/05/24',
  },
  {
    key: '4',
    description: 'Croqueta perro',
    kgs: 15,
    total: '$4,800',
    fecha: '01/05/24',
  },
  {
    key: '5',
    description: 'Croqueta gato',
    kgs: 3,
    total: '$1,500',
    fecha: '28/04/24',
  },
  {
    key: '6',
    description: 'Croqueta gato',
    kgs: 5,
    total: '$3,500',
    fecha: '22/04/24',
  },
  {
    key: '7',
    description: 'Croqueta perro',
    kgs: 5,
    total: '$3,500',
    fecha: '15/04/24',
  },
];

const paymentColumns = [
  {
    title: 'Fecha',
    dataIndex: 'fecha',
    key: 'fecha',
  },
  {
    title: 'Referencia',
    dataIndex: 'referencia',
    key: 'referencia',
  },
  {
    title: 'Monto',
    dataIndex: 'cantidad',
    key: 'cantidad',
  },
];

const paymentData = [
  {
    key: '1',
    fecha: '25/05/24',
    referencia: '1234567890',
    cantidad: '$8,900.00',
  },
  {
    key: '2',
    fecha: '01/05/24',
    referencia: '1234567432',
    cantidad: '$6,200.00',
  },
  {
    key: '3',
    fecha: '30/04/24',
    referencia: '12345213',
    cantidad: '$5,000.00',
  },
];

function onChange(checked) {
  console.log(`switch to ${checked}`);
}

const ClientInfo = () => {

  return (<Row gutter={[24, 24]}>


    <Col span={20} lg={14} id="my-scroll-layout">
      <Card
        id="profile"
        className="card-profile-head"
        bordered={false}
        bodyStyle={{ display: "none" }}
        title={
          <Row justify="space-between" align="middle" gutter={[24, 0]}>
            <Col span={24} md={12} className="col-info">
              <Avatar.Group>
                <Avatar size={74} shape="square" src={profilavatar} />

                <div className="avatar-info">
                  <h4 className="font-semibold m-0">Comercial Cabrera</h4>
                </div>
              </Avatar.Group>
            </Col>
            <Col
              span={24}
              md={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <small className="font-regular mr-5">
                Activo
              </small>
              <Switch defaultChecked onChange={onChange} />
            </Col>
          </Row>
        }
      ></Card>

      <Card
        id="basic-info"
        className="mb-24 header-solid"
        bordered={false}
        title={[
          <h5 className="mb-0" key={0}>
            Información del cliente
          </h5>,
        ]}
      >
        <Form layout="vertical">
          <Row gutter={[30, 20]}>
            <Col span={24} lg={12}>
              <Form.Item label="Nombre del cliente" colon={false}>
                <Input placeholder="Comercial Cabrera" />
              </Form.Item>
            </Col>
            <Col span={24} lg={12}>
              <Form.Item label="Persona de contacto" colon={false}>
                <Input placeholder="Mario Gonzalez" />
              </Form.Item>
            </Col>
            <Col span={30} lg={20}>
              <Form.Item label="Dirección" colon={false}>
                <Input placeholder="Hidalgo #185, Tepatitlán" type="adress" />
              </Form.Item>
            </Col>
            <Col span={24} lg={12}>
              <Form.Item label="Email" colon={false}>
                <Input placeholder="comer.cabrera@gmail.com" type="email" />
              </Form.Item>
              <label htmlFor="tipoCliente" style={{ fontWeight: 'bold' }}>Tipo de cliente</label> <br />
              <Select multiple style={{ width: '80%', marginTop: '10px' }}>
                <option value="ConsolidatedClient">Cliente Consolidado</option>
                <option value="PotentialClient">Cliente Potencial</option>
              </Select>

              <div style={{marginTop:"20px"}}>
                <label style={{ fontWeight: 'bold' }}>Tipo de producto que vende</label>
                <Select multiple style={{ width: '80%', marginTop: '10px' }}>
                  <option value="adult-dog-food">Croquetas para perro adulto</option>
                  <option value="puppy-dog-food">Croquetas para perro cachorro</option>
                  <option value="cat-food">Croquetas para gatos</option>
                </Select>
              </div>

            </Col>
            <Col span={24} lg={12}>
            </Col>
          </Row>
        </Form>
      </Card>
    </Col>

    <Col span={12} lg={8} id="clientStats">
      <Card title="Balance Financiero" style={{ width: 300 }}>
        <Row gutter={16}>
          <Col span={12}>
            <Statistic
              value={"$10,200"}
              valueStyle={{ color: '#a7c957' }}
            />
          </Col>
        </Row>
      </Card> <br></br>
      <Card title="Estadísticas" style={{ width: 300 }}>
        <Row gutter={16}>
          <Col span={12}>
            <Statistic
              title="Venta en kilos"
              value={1128}
              valueStyle={{ color: '#a7c957' }}
              prefix={<ArrowUpOutlined />}
              suffix="%"
            />
          </Col>
          <Col span={12}>
            <Statistic
              title="Pago en tiempo"
              value={"-5 días"}
              valueStyle={{ color: '#cf1322' }}
              prefix={<ArrowDownOutlined />}
            />
          </Col>
        </Row>
      </Card>
      <Space>  </Space>
      <Card
        id="client-sales"
        className="mb-24 header-solid"
        bordered={false}
        title={
          <Space direction='horizontal' style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
            <h5 className="mb-0" key={0}>
              Compras
            </h5>
            <Statistic
              value={7}
            />
          </Space>
        }
      >
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          style={{ width: '10px' }}
          summary={() => (
            <Table.Summary.Row>
              <Table.Summary.Cell></Table.Summary.Cell>
              <Table.Summary.Cell>
                <h5 className="font-semibold">Total</h5>
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                <h5 className="font-semibold">$25,100</h5>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          )}
        />
      </Card>
      <Card
        id="cient-payments"
        className="mb-24 header-solid"
        bordered={false}
        title={<Space direction='horizontal' style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
          <h5 className="mb-0" key={0}>
            Pagos
          </h5>
          <Statistic
            value={3}
          />
        </Space>}
      >
        <Table
          columns={paymentColumns}
          dataSource={paymentData}
          pagination={false}
          style={{ width: '10px' }}
          summary={() => (
            <Table.Summary.Row>
              <Table.Summary.Cell></Table.Summary.Cell>
              <Table.Summary.Cell>
                <h5 className="font-semibold">Total</h5>
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                <h5 className="font-semibold">$25,100</h5>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          )}
        />
      </Card>
    </Col>
  </Row>)
}


export { ClientInfo }