import React, { useState, useEffect } from "react";
import { Layout, Drawer, Affix } from "antd";
import { Route, Routes, useLocation } from "react-router";
import routes from "../../utils/routes"; // Assuming your routes are defined here
import Navigation from "./navigation";
import FooterNEw from "../layout/FooterNEw";
import HeaderTop from "./HeaderTop";
import Parse from "parse";
import { UserContext } from "../../context/UserContext";

const { Header, Content } = Layout;
const { Sider } = Layout;

const Main = () => {
  const location = useLocation();
  const [visible, setVisible] = useState(false);
  const [placement, setPlacement] = useState("right");
  const [currentComponent, setCurrentComponent] = useState("");
  const [sidebarColor, setSidebarColor] = useState("#1890ff");
  const [sidenavType, setSidenavType] = useState("transparent");
  const [fixed, setFixed] = useState(false);
  const [name, setName] = useState("");
  const [subName, setSubName] = useState("");
  const [breadcrumb, setBreadcrumb] = useState("");
  const [collapsed, setCollapsed] = useState(false);
  const { user, loadingUser, permissions } = React.useContext(UserContext);

  // This hook replaces componentDidMount and componentDidUpdate
  useEffect(() => {
    console.log(location.pathname);
    if (location.pathname) {
      let page = location.pathname.split("/");
      page = page[page.length - 1];
      setCurrentComponent(page);
    }
  }, [location.pathname]); // Runs when location changes

  const capatlizeWord = (word) => {
    return word.replace(/\b\w/g, (l) => l.toUpperCase());
  };

  const OpenDrawer = () => {
    setVisible(!visible);
  };

  const handleSidenavType = (type) => {
    setSidenavType(type);
  };

  const handleSidebarColor = (color) => {
    setSidebarColor(color);
  };

  const handleFixedNavbar = (type) => {
    setFixed(type);
  };

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    if (!user && !loadingUser) {
      window.location.href = "/signin";
    }
  }, [user, loadingUser]);

  // Dynamically render the routes from the routes array
  const menu = routes.map((route, index) => {
    return route.component ? (
      <Route
        key={index}
        path={route.path}
        exact={route.exact}
        element={<route.component />}
      />
    ) : null;
  });

  return (
    <Layout
      className={`layout-dashboard ant-layout ant-layout-has-sider has-sidebar ${
        currentComponent === "all-projects" ||
        currentComponent === "profile-overview"
          ? "layout-profile"
          : ""
      } ${collapsed ? "sidebar-minimized" : ""} ${
        currentComponent === "new-product" ? "layout-profile" : ""
      } ${currentComponent === "rtl" ? "layout-dashboard-rtl" : ""}`}
    >
      <Drawer
        title={false}
        placement={placement === "right" ? "left" : "right"}
        closable={false}
        onClose={() => setVisible(false)}
        open={visible}
        key={placement === "right" ? "left" : "right"}
        width={250}
        className={`drawer-sidebar ${
          currentComponent === "rtl" ? "drawer-sidebar-rtl" : ""
        } `}
      >
        <Layout
          className={`layout-dashboard ${
            currentComponent === "rtl" ? "layout-dashboard-rtl" : ""
          }`}
        >
          <Sider
            trigger={null}
            width={250}
            theme="light"
            className={`sider-primary ant-layout-sider-primary ${
              sidenavType === "#fff" ? "active-route" : ""
            }`}
            style={{ background: sidenavType }}
          >
            <Navigation
              currentSelected={currentComponent}
              handleSidebarColor={handleSidebarColor}
            />
          </Sider>
        </Layout>
      </Drawer>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        trigger={null}
        width={250}
        theme="light"
        className={`sider-primary ant-layout-sider-primary ${
          sidenavType === "#fff" ? "active-route" : ""
        }`}
        style={{ background: sidenavType }}
      >
        <Navigation
          currentSelected={currentComponent}
          handleSidebarColor={handleSidebarColor}
        />
      </Sider>
      <Layout id="temp__div">
        {fixed ? (
          <Affix>
            <Header className={`${fixed ? "ant-header-fixed" : ""}`}>
              <HeaderTop
                onPress={OpenDrawer}
                breadcrumb={breadcrumb}
                name={name}
                subname={subName}
                handleSidebarColor={handleSidebarColor}
                handleSidenavType={handleSidenavType}
                handleFixedNavbar={handleFixedNavbar}
                toggleCollasped={toggleCollapsed}
              />
            </Header>
          </Affix>
        ) : (
          <Header className={`${fixed ? "ant-header-fixed" : ""}`}>
            <HeaderTop
              breadcrumb={breadcrumb}
              onPress={OpenDrawer}
              placement={placement}
              name={name}
              subname={subName}
              handleSidebarColor={handleSidebarColor}
              handleSidenavType={handleSidenavType}
              handleFixedNavbar={handleFixedNavbar}
              toggleCollasped={toggleCollapsed}
            />
          </Header>
        )}
        <Content className="content-ant">
          <Routes>{menu}</Routes>
        </Content>
        <FooterNEw />
      </Layout>
    </Layout>
  );
};

export default Main;
