import React, { useEffect } from "react";
import { Button, Space, Typography, Modal, Form, Input, Select, message } from "antd";
import Parse, { Role } from "parse";
import { generateRandomPassword } from "../utils/randomPassword";
import { setACL } from "../../../../utils/ACL";
import { findErrorMessage } from "../../../../utils/parseErrors";


const { Title } = Typography;

const NewSeller = ({ open, setOpen, refresh }) => {
    const [form] = Form.useForm();
    const [renderId, setRenderId] = React.useState(0);

    useEffect(() => {
        form.setFieldsValue({
            email: "",
            firstName: "",
            lastName: "",
        });
    }, [open]);

    const createSeller = () => {
        const seller = form.getFieldsValue();
        try {
            const Seller = Parse.Object.extend("_User");
            const newSeller = new Seller(seller);
            newSeller.set('username', seller.email?.trim()?.toLowerCase());
            newSeller.set('password', generateRandomPassword());
            newSeller.set('firstName', seller.firstName?.trim());
            newSeller.set('lastName', seller.lastName?.trim());
            newSeller.set('fullName', `${seller.firstName?.trim()} ${seller.lastName?.trim()}`);
            newSeller.setACL(setACL('Ventas'));
            newSeller.save(seller).then(
                (result) => {
                    const roleQuery = new Parse.Query(Role);
                    roleQuery.equalTo("name", "Ventas");
                    roleQuery.first().then((role) => {
                        role.getUsers().add(newSeller);
                        role.save();
                    });

                    console.log("Seller created", result);
                    message.success("Nuevo vendedor agregado correctamente");
                    refresh();
                },
                (error) => {
                    console.error("Error while creating Seller: ", error);
                    message.error("Error al agregar el nuevo vendedor");
                }
            );
        } catch (error) {
            console.error("Error while creating Seller: ", error);
            message.error("Error al agregar el nuevo vendedor");
        }
    }

    const handleSubmit = () => {
        form.validateFields().then((values) => {
            console.log(values);
            createSeller();
            setOpen(false);
        }).catch((error) => {
            console.log(error);
        });
    };

    return (
        <Modal
            title="Nuevo Vendedor"
            open={open}
            onCancel={() => setOpen(false)}
            footer={[
                <Button key="back" onClick={() => setOpen(false)}>
                    Cancelar
                </Button>,
                <Button key="submit" type="primary" onClick={handleSubmit}>
                    Guardar
                </Button>,
            ]}
        >
            <Form
                layout="vertical"
                form={form}
                name="basic"
            >
                <Form.Item
                    label="Nombre"
                    name="firstName"
                    rules={[{ required: true, message: 'Nombres' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Apellido"
                    name="lastName"
                    rules={[{ required: true, message: 'Nombres' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Correo electrónico"
                    name="email"
                    rules={[{ required: true, message: 'Correo electrónico' }]}
                >
                    <Input />
                </Form.Item>
                
            </Form>
        </Modal>
    );
}

export default NewSeller;
