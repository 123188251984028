/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-pascal-case */
import React, { useContext } from "react";
import { Layout, Button,  Card, Form, Input, Checkbox, Radio } from "antd";
import logo1 from "../images/logos-facebook.svg";
import logo2 from "../images/logo-apple.svg";
import logo3 from "../images/Google__G__Logo.svg.png";

import { Link } from "react-router-dom";

import Footer_Pro from "./Footer_Pro";
import Header_Pro from "./Header_Pro";

import Parse from "parse";
import { UserContext } from "../../context/UserContext";

const { Header, Footer, Content } = Layout;

const SignUp = () => {
  const { user, setCurrentUser } = useContext(UserContext);

  const onFinish = (values) => {
    console.log("Success:", values);
    Parse.User.signUp(values.email, values.password, { email: values.email, name: values.name, role: values.role, username: values.email })
      .then((user) => {
        console.log("User signed up", user);
        setCurrentUser(user);
        // window.location.href = "/sign-in";
      })
      .catch((error) => {
        console.error("Error while signing up user", error);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
    
    return (
      <>
        <div className="layout-default ant-layout layout-sign-up ">
          <Header>
          </Header>

          <Content className="p-0">
            <div className="sign-up-header">
              <div className="content">
                <h1 className="mb-3 font-bold mbt-5">Dasner Petcare</h1>
                <p className="text-lg" style={{ lineHeight: "24px" }}>
                  Haz una cuenta para darte de alta como cliente o proveedor.
                </p>
              </div>
            </div>

            <Card
              className="card-signup header-solid h-full ant-card pt-0"
              title={
                <h5 className="font-semibold text-center">Registro</h5>
              }
              bordered="false"
              bodyStyle={{ paddingTop: "0px" }}
            >
              {/* <div className="sign-up-gateways">
                <Button type="false">
                  <img src={logo1} alt="" />
                </Button>
                <Button type="false">
                  <img src={logo2} alt="" />
                </Button>
                <Button type="false">
                  <img src={logo3} alt="" />
                </Button>
              </div> */}
              {/* <div className="divider my-25">
                <hr className="gradient-line" />
                <p className="font-semibold text-muted">
                  <span className="label">Or</span>
                </p>
              </div> */}
              <Form
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                className="row-col"
              >
                <Form.Item
                  name="role"
                  rules={[
                    { required: true, message: "Por favor seleccione su categoría" },
                  ]}
                >
                  <Radio.Group 
                  options={[
                    { label: "Cliente", value: "Cliente" },
                    { label: "Proveedor", value: "Proveedor" },
                  ]} 
                  optionType="button"
                  buttonStyle="solid"
                  style={{ width: "100%", textAlign: "center" }}
                  />
                </Form.Item>
                <Form.Item
                  name="name"
                  rules={[
                    { required: true, message: "Por favor ingrese el nombre de la empresa" },
                  ]}
                >
                  <Input placeholder="Nombre de la empresa" />
                </Form.Item>
                
                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: "Por favor ingrese su correo" },
                    { type: "email", message: "Por favor ingrese un correo válido" },
                  ]}
                >
                  <Input placeholder="Correo electrónico" />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    { required: true, message: "Por favor ingrese su contraseña" },
                    { min: 6, message: "La contraseña debe tener al menos 6 caracteres" },
                  ]}
                >
                  <Input.Password placeholder="Contraseña" />
                </Form.Item>

                <Form.Item name="remember" valuePropName="checked" required>
                  <Checkbox>
                    Acepto los{" "}
                    <a href="#" className="font-bold text-dark">
                      Términos y Condiciones
                    </a>
                  </Checkbox>
                </Form.Item>

                <Form.Item>
                  <Button
                    style={{ width: "100%" }}
                    type="primary"
                    htmlType="submit"
                  >
                    REGISTRARSE
                  </Button>
                </Form.Item>
              </Form>
              <p className="font-semibold text-muted text-center">
                ¿Ya tienes una cuenta?{" "}
                <Link to="/signin" className="font-bold text-dark">
                  Inicia sesión
                </Link>
              </p>
            </Card>
          </Content>
          <Footer>
            <Footer_Pro />
          </Footer>
        </div>
      </>
    );
  }

export default SignUp;