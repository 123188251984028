import React, { Component, useEffect } from "react";
import { Card, Col, Row, Table, Typography, Tag, Button, FloatButton, Progress } from "antd";
import animalia from "../../images/animale.jpeg";
import NewInventoryEntry from "./components/NewInventoryEntry";
import { fetchPurchaseOrders } from "./utils/warehouseEntries";
import moment from "moment";

const { Title } = Typography;

const WarehouseEntryList = () => {
  const [newEntry, setNewEntry] = React.useState(false);
  const [entry, setEntry] = React.useState(null);
   const [dataSource, setDataSource] = React.useState([]);
   const [shouldRefresh, setShouldRefresh] = React.useState(false);

   useEffect(() => {
    fetchPurchaseOrders().then((purchaseOrders) => {
      // Sum  up all the received products from all the entries of each purchase order
      let newPurchaseOrders = purchaseOrders.map((order) => {
        let totalPurchase = order.products.reduce((acc, product) => acc + product.quantity, 0);
        let products = order.entries ? 
          order.entries.reduce((acc, entry) => {
          entry.products.forEach((product) => {
            let existingProduct = acc.find((p) => p.id === product.id);
            if (existingProduct) {
              existingProduct.remaining -= product.received;
            } else {
              acc.push({ ...product, remaining: product.quantity-product.received });
            }
          });
          return acc;
        }, []) : order.products;
        const totalRemaining = products.reduce((acc, product) => acc + product.remaining, 0);
        return { ...order, products, totalPurchase, totalReceived: totalPurchase - totalRemaining };
      });

      setDataSource(newPurchaseOrders);
      
    });
  }, [shouldRefresh]);

  const refresh = () => {
    setShouldRefresh(!shouldRefresh);
  };
  
    const columns = [
    
      {
        title: "Fecha",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (date) => (
          <>
            <h3>{moment(date).format("DD/MM/YYYY")}</h3>
          </>
        ),
      },
      {
        title: "Orden de compra",
        dataIndex: "folio",
        key: "folio",
        render: (text, entry) => (
         <Button type='link' onClick={() => {
          setNewEntry(true)
          setEntry(entry)}
          }>
            <p>{text}</p>
          </Button>
        ),
      },
      {
        title: "Proveedor",
        dataIndex: "supplier",
        key: "supplier",
        render: (supplier) => (
          <>
            <p>{supplier?.name}</p>
          </>
        ),
      },
      // {
      //   title: "Recibido por",
      //   dataIndex: "received",
      //   key: "client",
      //   render: (text) => (
      //     <>
      //       <p>{text}</p>
      //     </>
      //   ),
      // },
      {
        title: "Productos",
        dataIndex: "products",
        key: "products",
        render: (products) => (
          products?.map((product, index) => (
            <div key={index}>
              <p>{product.quantity?.toLocaleString()} {product.presentation?.toLowerCase()}{product.quantity !== 1 ? 's' : ''} de {product.name}</p>
            </div>
          ))
        ),
      }, 
      {
        title: "Recibido",
        key: "remaining",
        render: (entry) => (
          // Fix to no decimals
          <Progress percent={(entry.totalReceived / entry.totalPurchase).toFixed(2) * 100} />
        ),
      }
      
    ];

    return (
      <div className="layout-content">
        <NewInventoryEntry open={newEntry} setOpen={setNewEntry} entryInfo={entry} refresh={refresh} />
        <Row gutter={[24, 0]}>
          <Col lg={24} className="zindex">
            <h2 className="p-15 h2">Entradas de almacén pendientes</h2>

            <Card
              bordered={false}
              bodyStyle={{ padding: "0px" }}
              className="criclebox cardbody mb-24 header-solid"
            >
              <div className="ant-list-box table-responsive">
                <Table
                  dataSource={dataSource}
                  columns={columns}
                  rowKey={(record, index) => index}
                  pagination={true}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }


  export default WarehouseEntryList
