import React, { useState } from "react";
import { Select, Spin } from "antd";
import { debounce } from "lodash";

const { Option } = Select;

const DebounceSelect = ({ fetchOptions, debounceTimeout = 800, ...props }) => {
    const [fetching, setFetching] = useState(false);
    const [options, setOptions] = useState([]);
    const fetchRef = React.useRef(0);

    const loadOptions = (value) => {
        fetchRef.current += 1;
        const fetchId = fetchRef.current;
        setOptions([]);
        setFetching(true);
        fetchOptions(value).then((newOptions) => {
            if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return;
            }
            
            setOptions(newOptions);
            setFetching(false);
        });
        };

    const debounceFetcher = React.useMemo(() => {
        return debounce(loadOptions, debounceTimeout);
    }, [fetchOptions, debounceTimeout]);



    return (
        <Select
        allowClear={props.allowClear}
        showSearch={props.showSearch}
        filterOption={false}
        onSearch={debounceFetcher}
        onSelect={(value, option) => {
            props.onChange(value, option);
            
        }}
        notFoundContent={fetching ? <Spin size="small" /> : null}
        {...props}
        options={options}
        labelInValue
        onClick={() => loadOptions("")}
        >
        
        </Select>
    );
    }

export default DebounceSelect;