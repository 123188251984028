import React, { useEffect, useState } from 'react';
import { Button, Flex, message, Result, Skeleton } from 'antd';
import Parse from 'parse';
import { useParams, useNavigate } from 'react-router-dom';
import { UserContext } from '../../../../context/UserContext';
import Title from 'antd/es/typography/Title';
import dasner_logo_black from '../../../images/dasner_logo_black.png';

const AcceptQuote = () => {
    const [quote, setQuote] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const navigate = useNavigate();
    const { uuid } = useParams();
    const { user, loadingUser, logout } = React.useContext(UserContext);

    const fetchQuote = () => {
        const Quote = Parse.Object.extend('Sales');
        const query = new Parse.Query(Quote);
        query.equalTo('uuid', uuid);
        query.include(['seller']);
        query.equalTo('client', { __type: 'Pointer', className: '_User', objectId: user?.objectId });
        query.equalTo('active', true);
        try {
            setLoading(true);
            query.first().then((response) => {
                if (!response) {
                    setError(true);
                    setLoading(false);
                    return;
                }
                console.log(response);
                setQuote(response?.toJSON());
                setLoading(false);
            });
        }
        catch (e) {
            setError(true);
            setLoading(false);
        }
    };

    const acceptQuote = () => {
        const Quote = Parse.Object.extend('Sales');
        const quoteObject = new Quote();
        quoteObject.set('objectId', quote.objectId);
        quoteObject.set('status', 'Confirmada');
        quoteObject.save().then((response) => {
            console.log(response);
            message.success('Cotización aceptada');
            fetchQuote();
        });
    };

    useEffect(() => {
        if (!loadingUser && !user) {
            console.log('User not logged in');
            navigate('/signin', { state: { from: '/sales/AcceptQuote/' + uuid } });
        } else if (user) {
            fetchQuote();
        }
    }, [user, loadingUser]);

    if (error) {
        return (
            <Flex vertical align="center" justify="center">
                <img src={dasner_logo_black} alt="Dasner" style={{ width: 200, marginBottom: 20 }} onClick={() => navigate('/')} />
                <Result
                    status="error"
                    title="Error"
                    subTitle="No se encontró la cotización"
                />
                <Title level={4}>La cotización que intentas aceptar no existe o no tienes permisos para verla</Title>
                 <Button type="primary" onClick={logout}>Cerrar sesión</Button>
            </Flex>
        );
    }

    if (loading) {
        return (
            <Flex vertical align="center" justify="center">
                <img src={dasner_logo_black} alt="Dasner" style={{ width: 200, marginBottom: 20 }} onClick={() => navigate('/')} />
                <Skeleton active />
            </Flex>
        );
    }

    if (quote?.status === ' Cancelada') {
        return (
            <Flex vertical align="center" justify="center">
                <img src={dasner_logo_black} alt="Dasner" style={{ width: 200, marginBottom: 20 }} onClick={() => navigate('/')} />
                <Result
                    status="error"
                    title="Cotización cancelada"
                    subTitle="Esta cotización ha sido cancelada"
                />
            </Flex>
        );
    }

    if (quote.status === 'Confirmada') {
        return (
            <Flex vertical align="center" justify="center">
                <img src={dasner_logo_black} alt="Dasner" style={{ width: 200, marginBottom: 20 }} onClick={() => navigate('/')} />
                <Result
                    status="success"
                    title="Cotización aceptada"
                    subTitle="Esta cotización ha sido aceptada"
                />
            </Flex>
        );
    }

    return (
        <Flex vertical align="center" justify="center">
            <img src={dasner_logo_black} alt="Dasner" style={{ width: 200, marginBottom: 20 }} onClick={() => navigate('/')} />
            <h1>Cotización a aceptar</h1>
            <h2>{quote?.quoteNumber}-{quote?.version}</h2>
            <h2>Vendedor: {quote?.seller?.fullName}</h2>
            <h2>Total: ${quote?.total?.toLocaleString()}</h2>
            <Button type="primary" onClick={acceptQuote}>Aceptar cotización</Button>
        </Flex>
    );
};

export default AcceptQuote;