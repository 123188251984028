import React, { useEffect } from "react";
import { Button, Space, Typography, Modal, Form, Input, Select, message, DatePicker } from "antd";
import Parse, { Role } from "parse";
import { fetchClients } from "../utils/query";
import moment from "moment";
const { Title } = Typography;


const NewVisit = ({ open, setOpen, refresh }) => {
    const [form] = Form.useForm();
    const [renderId, setRenderId] = React.useState(0);
    const [clients, setClients] = React.useState([]);
    const [today, setToday] = React.useState(moment());

    const getClients = async () => {
        const clients = await fetchClients();
        console.log(clients);
        setClients(clients);
    };

    useEffect(() => {
        getClients();
    }, []);

    useEffect(() => {
        form.setFieldsValue({
            order: "",
            notes: "",
            date: today
        });
    }, [open]);


    const createVisit = () => {
        const visit = form.getFieldsValue();
        console.log(visit);
        const Visit = Parse.Object.extend("_User");
        const NewVisit = new Visit(visit);
        //NewVisit.set('username', visit.email);
        NewVisit.save(visit).then(
            (result) => {
                console.log("Visit created", result);
                message.success("Nuevo Vendedor agregado correctamente");
                refresh();
            },
            (error) => {
                console.error("Error while creating Visit: ", error);
                message.error("Error al agregar el nuevo vendedor");
            }
        );
    };

    const handleSubmit = () => {
        form.validateFields().then((values) => {
            console.log(values);
            createVisit();
            setOpen(false);
        }).catch((error) => {
            console.log(error);
        });
    };

    return (
        <Modal
            title="Nuevo Visita"
            open={open}
            onCancel={() => setOpen(false)}
            footer={[
                <Button key="back" onClick={() => setOpen(false)}>
                    Cancelar
                </Button>,
                <Button key="submit" type="primary" onClick={handleSubmit}>
                    Guardar
                </Button>,
            ]}
        >
            <Form
                layout="vertical"
                form={form}
                name="basic"
            >
                <Form.Item
                    label="No. de Orden"
                    name="order"
                    rules={[{ required: true, message: 'Número de Orden' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Fecha"
                    name="date"
                    rules={[{ required: true, message: 'Por favor seleccione una fecha' }]}
                >
                    {/* TODO: Fix date picker going infinite forward */}
                    <DatePicker defaultValue={today} format="DD-MM-YYYY" />
                </Form.Item>
                <Form.Item
                    label="Cliente"
                    name="client"
                    rules={[{ required: true, message: 'Por favor seleccione un cliente' }]}
                >
                    <Select
                        placeholder="Seleccione un cliente"
                        onChange={(value) => form.setFieldsValue({ client: value })}
                    >
                        {clients.map((client) => (
                            <Select.Option key={client.id} value={client.id}>
                                {client.name}
                            </Select.Option>
                        ))}
                    </Select>

                </Form.Item>
                <Form.Item
                    label="Comentarios"
                    name="notes"
                    rules={[{ required: false }]}
                >
                    <Input.TextArea rows={4} placeholder="Comentarios de la visita" />
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default NewVisit;
