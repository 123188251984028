import React from "react";
import { Row, Col, Card, Table, Statistic } from "antd";

const comissionPercentage = 30;

const getComissionPercentage = (amount) => {
    const numericAmount = parseFloat(amount.replace(/[$,]/g, ''));
    const comission = numericAmount * comissionPercentage / 100;
    return "$$"; {comission.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })};
};

const columns = [
    {
      title: 'No. de Orden',
      dataIndex: 'order',
      key: 'order',
    },
    {
      title: 'Fecha',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Monto',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Comisión',
      dataIndex: 'comission',
      key: 'comission',
      render: (text, record) => <span>{record.comission()}</span>,
    },
];

const data = [
    {
      key: '1',
      date: '25/05/24',
      order: 'D0001',
      amount: '$8,900.00',
      comission() { return getComissionPercentage(this.amount); },
    },
    {
      key: '2',
      date: '06/06/24',
      order: 'D0003',
      amount: '$6,200.00',
      comission() { return getComissionPercentage(this.amount); },
    },
    {
      key: '3',
      date: '20/06/24',
      order: 'D0006',
      amount: '$10,300.00',
      comission() { return getComissionPercentage(this.amount); },
    },
];



const SellerComissions = () => {
    return (
        <Row gutter={[24, 0]}>
            <Col span={24} lg={50} className="zindex">      
                <Card title={<h2>Comisión</h2>}>
                <Row style={{ paddingLeft: 20, paddingBottom: 20 }}>
                    <Col>
                        <Statistic
                            title={<h3>Porcentaje de Comisión</h3>}
                            value={comissionPercentage}
                            formatter={(value) => <span>{value} %</span>}
                        />
                    </Col>
                </Row>
                </Card> 
            </Col>
            <Col span={24} lg={50} className="zindex">      
                <Card> 
                <h2>Historial de Comisiones</h2>
                    <Table columns={columns} dataSource={data} />
                </Card>
            </Col>
        </Row>
    )
}

export {SellerComissions};