import Parse from "parse";

const fetchProducts = async (search) => {
    return new Promise((resolve) => {
        const Product = Parse.Object.extend("Product");
        const query = new Parse.Query(Product);
        query.include("presentations");
        query.matches("name", search, "i");
        query.find().then((results) => {
            resolve(results.map((result) => { return {
                ...result.toJSON(),
                value: result.get("name"),
                label: result.get("name"),
            }}));
        });
    });
}

const fetchClient = async (search) => {
    return new Promise((resolve, reject) => {
        const Role = Parse.Object.extend("_Role");
        const roleQuery = new Parse.Query(Role);
        roleQuery.equalTo("name", "Cliente");
        
        roleQuery.first().then((role) => {
            if (!role) {
                return resolve([]); // Role not found, return empty array
            }
            
            const usersRelation = role.relation("users"); // Access the relation for users
            const userQuery = usersRelation.query();
            search && userQuery.matches("fullName", search, "i"); // Add search constraint
            
            userQuery.find().then((results) => {
                resolve(
                    results.map((result) => ({
                        ...result.toJSON(),
                        value: result.get("fullName"),
                        label: result.get("fullName"),
                        key: result.id,
                    }))
                );
            }).catch((error) => {
                reject(error); // Handle errors in fetching users
            });
        }).catch((error) => {
            reject(error); // Handle errors in fetching the role
        });
    });
};

const fetchSeller = async (search) => {
    return new Promise((resolve, reject) => {
        const Role = Parse.Object.extend("_Role");
        const roleQuery = new Parse.Query(Role);
        roleQuery.equalTo("name", "Ventas");
        
        roleQuery.first().then((role) => {
            if (!role) {
                return resolve([]); // Role not found, return empty array
            }
            console.log("role", role);
            
            const usersRelation = role.relation("users"); // Access the relation for users
            const userQuery = usersRelation.query();
            search && userQuery.matches("fistName", search, "i"); // Add search constraint
            
            userQuery.find().then((results) => {
                console.log("results", results);
                resolve(
                    results.map((result) => ({
                        ...result.toJSON(),
                        value: result.get("firstName") + " " + result.get("lastName"),
                        label: result.get("firstName") + " " + result.get("lastName"),
                        key: result.id,
                    }))
                );
            }).catch((error) => {
                reject(error); // Handle errors in fetching users
            });
        }).catch((error) => {
            reject(error); // Handle errors in fetching the role
        });
    });
}

export { fetchProducts, fetchClient, fetchSeller };