import React from 'react';
import { Row, Col, Card, Statistic, Progress, Table, Flex, Button } from 'antd';
import AddIngredientModal from './Modals/AddIngredientModal';
import AddFinePowders from './Modals/AddFinePowders';
import SaveFinePowders from './Modals/SaveFinePowders';

const dataSource = [
    { ingredient: "Grasa 30/70", quantity: "40kg" },
    { ingredient: "Digesta A20", quantity: "10kg" },
];



const FatDigestWeightDetail = ({ production }) => {
    const [showAddIngredientModal, setShowAddIngredientModal] = React.useState(false);
    const [showAddFinePowders, setShowAddFinePowders] = React.useState(false);
    const [showSaveFinePowders, setShowSaveFinePowders] = React.useState(false);

const columns = [
    {
        title: "Ingrediente",
        dataIndex: "name",
        key: "name",
        render: (text) => <h3>{text}</h3>,
    },
    {
        title: "Cantidad",
        dataIndex: "quantity",
        key: "quantity",
        render: (text, product) => <h3>{text * production?.productionQuantity} {product.basePresentation}</h3>,
    },
    {
        title: "Agregado",
        dataIndex: "added",
        key: "added",
        render: (text, ingredient) => <Progress percent={(text / ingredient.total) * 100} format={() => `${text} / ${ingredient.total}`} />,
    }
];

    return (
        <Row gutter={24}>
            <AddIngredientModal open={showAddIngredientModal} setOpen={setShowAddIngredientModal} production={production} warehouse="Producción" />
            <SaveFinePowders open={showSaveFinePowders} setOpen={setShowSaveFinePowders} production={production} />
            <AddFinePowders open={showAddFinePowders} setOpen={setShowAddFinePowders} production={production} />
            <Col span={24}>
            <Row gutter={12}>
                <Col span={6} md={6} xs={24}>
                    <Card
                        bordered={false}
                        bodyStyle={{ padding: '10px' }}
                        style={{ margin: '10px', width: "100%" }}
                        className="criclebox cardbody mb-24 header-solid"
                        onClick={() => setShowAddFinePowders(true)}
                        title={
                            <Statistic
                                title="Polvos finos agregados"
                                value={production?.addedFinePowders}
                                suffix="KG"
                                style={{ fontSize: '20px' }}
                            />
                        }
                    />
                </Col>
                <Col span={6} md={6} xs={24}>
                <Card
                        bordered={false}
                        bodyStyle={{ padding: '10px' }}
                        style={{ margin: '10px', width:"100%"}}
                        className="criclebox cardbody mb-24 header-solid"
                        >
                            <Statistic
                                title="Grasa y digesta agregados"
                                value={production?.ingredients?.filter(ingredient => ingredient.warehouse === 'Producción').reduce((acc, ingredient) => acc + parseFloat(ingredient.added), 0)}
                                suffix="KG"
                                style={{ fontSize: '20px' }}
                            />
                        </Card>
                </Col>
                <Col span={6} md={6} xs={24}>
                    <Card
                        bordered={false}
                        bodyStyle={{ padding: '10px' }}
                        style={{ margin: '10px', width: "100%" }}
                        className="criclebox cardbody mb-24 header-solid"
                        title={
                            <Statistic
                                title="Peso Grasa y Digesta"
                                value={production?.totalFat}
                                suffix="KG"
                                style={{ fontSize: '20px' }}
                            />
                        }
                    />
                </Col>
                </Row>
                <Col span={24} md={24} xs={24}>
                    <Card
                        bordered={false}
                        bodyStyle={{ padding: "0px" }}
                        style={{ padding: 20, width: "100%" }}
                        className="criclebox cardbody mb-24 header-solid"
                        title={<Flex justify="space-between" align="middle"><h2>Grasa y digesta</h2><Button type="primary" onClick={() => setShowAddIngredientModal(true)}>Agregar ingrediente</Button></Flex>}
                        >
                        <div className="ant-list-box table-responsive">
                            <Table
                                style={{ width: '100%' }}
                                dataSource={production?.ingredients?.filter(ingredient => ingredient.warehouse === 'Producción')}
                                columns={columns}
                                rowKey={(record, index) => index}
                                pagination={true}
                            />
                        </div>
                    </Card>
                </Col>
            </Col>
        
            
        </Row>
    )
}

export default FatDigestWeightDetail;