import Parse from "parse";
import React, { createContext, useEffect, useState } from "react";

const UserContext = createContext();

const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [role, setRole] = useState(null);
    const [permissions, setPermissions] = useState([]);
    const [loadingUser, setLoadingUser] = useState(true);

    useEffect(() => {
        const currentUser = Parse.User.current();
        if (currentUser) {
            queryRole(currentUser);  // Fetch the role and permissions on user load
            console.log("Setting user", currentUser);
            setUser(currentUser?.toJSON());
        }
        setLoadingUser(false);
    }, []);

    useEffect(() => {
        console.log("User changed:", user);
        console.log("Role changed:", role);
        console.log("Permissions changed:", permissions);
    }, [user, role, permissions]);

    // Query user role and merge permissions
    const queryRole = async (user) => {
        console.log('Querying user roles');
        if (user) {
            const Role = Parse.Object.extend('_Role');
            const query = new Parse.Query(Role);
            query.containedIn('users', [user]);
            query.include(['permissions']);  // Include permissions from the role
            try {
                const role = await query.first();
                if (role) {
                    setRole(role);
                    const rolePermissionsRelation = role.relation('permissions');
                    const rolePermissionsQuery = rolePermissionsRelation.query();
                    const rolePermissions = await rolePermissionsQuery.find();

                    // Fetch the user's own permissions
                    const userPermissionsRelation = user.relation('permissions');
                    const userPermissionsQuery = userPermissionsRelation.query();
                    const userPermissions = await userPermissionsQuery.find();

                    // Merge the role permissions and user permissions, removing duplicates
                    const mergedPermissions = [
                        ...new Set([
                            ...rolePermissions.map(p => p.toJSON()),   // Use the permission id to ensure uniqueness
                            ...userPermissions.map(p => p.toJSON()),   // Add user-specific permissions
                        ])
                    ];

                    // Set merged permissions (user and role combined)
                    setPermissions(mergedPermissions);

                    console.log('Merged Permissions:', mergedPermissions);
                }
            } catch (error) {
                console.error('Error fetching user roles:', error);
            }
        }
    }

    const login = async (username, password) => {
        try {
            const user = await Parse.User.logIn(username, password);
            console.log("Logged in user:", user);
            queryRole(user);  // Fetch the role and permissions for the logged-in user
            return user;
        }
        catch (error) {
            console.error("Error while logging in:", error);
            throw error;
        }
    }

    const setCurrentUser = (currentUser) => {
        console.log("Setting user", currentUser);
        setUser(currentUser?.toJSON());
    }

    const logout = async () => {
        await Parse.User.logOut();
        setUser(null);
        setRole(null);
        setPermissions([]);
        window.location.href = "/signin";
    }
    
    return (
        <UserContext.Provider value={{ user, permissions, setCurrentUser, login, role, loadingUser, logout }}>
            {children}
        </UserContext.Provider>
    );
}

export { UserContext, UserProvider };
