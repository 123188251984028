/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Breadcrumb as AntdBreadcrumb } from "antd";

import menuItems from "../../../utils/menu-items";
import config from "../../../utils/config";

const Breadcrumb = () => {
  const location = useLocation();
  const [main, setMain] = useState([]);
  const [item, setItem] = useState([]);

  useEffect(() => {
    filter();
  }, [location.pathname]); // Runs when the location changes

  const filter = () => {
    menuItems.items.map((menuItem) => {
      if (menuItem.type && menuItem.type === "group") {
        getCollapse(menuItem);
      }
      return null;
    });
  };

  const getCollapse = (menuItem) => {
    if (menuItem.children) {
      menuItem.children.filter((collapse) => {
        if (collapse.type && collapse.type === "collapse") {
          getCollapse(collapse);
        } else if (collapse.type && collapse.type === "item") {
          if (location.pathname === config.basename + collapse.url) {
            setItem(collapse);
            setMain(menuItem);
          }
        }
        return false;
      });
    }
  };

  let mainBreadcrumb, itemBreadcrumb;
  let breadcrumb = "";
  let title = "Welcome";

  if (main && main.type === "collapse") {
    mainBreadcrumb = (
      <AntdBreadcrumb.Item>
        <NavLink to="/">{main.title}</NavLink>
      </AntdBreadcrumb.Item>
    );
  }

  if (item && item.type === "item") {
    title = item.title;
    itemBreadcrumb = (
      <AntdBreadcrumb.Item>
        <NavLink to="/">{title}</NavLink>
      </AntdBreadcrumb.Item>
    );

    if (item.breadcrumbs !== false) {
      breadcrumb = (
        <div className="page-header">
          <div className="page-block">
            <div className="row align-items-center">
              <div className="col-md-12">
                <AntdBreadcrumb>
                  {mainBreadcrumb}
                  {itemBreadcrumb}
                </AntdBreadcrumb>
                <div className="ant-page-header-heading">
                  <span className="ant-page-header-heading-title">
                    {title}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
  return null
  return <div>{breadcrumb}</div>;
};

export default Breadcrumb;
