import React from 'react';
import { Row, Col, Card, Statistic, Progress, Table, Flex, Button } from 'antd';
import AddPackedModal from './Modals/AddPackedModal';

const FatDigestWeightDetail = ({ production }) => {
    const [showPackModal, setShowPackModal] = React.useState(false);


const columns = [
    {
        title: "Ingrediente",
        dataIndex: "name",
        key: "name",
        render: (text) => <h3>{text}</h3>,
    },
    {
        title: "Cantidad",
        dataIndex: "quantity",
        key: "quantity",
        render: (text) => <h3>{text}</h3>,
    },
    {
        title: "Empacado",
        dataIndex: "added",
        key: "added",
        render: (added, packaging) => <Progress percent={added / packaging.quantity * 100} format={() => `${added} / ${packaging.quantity}`} />,
    }
];


    return (
        <Row gutter={24}>
            <AddPackedModal open={showPackModal} setOpen={setShowPackModal} production={production} />
                <Col span={6} md={6} xs={24}>
                    <Card
                        bordered={false}
                        bodyStyle={{ padding: '10px' }}
                        style={{  width: "100%" }}
                        className="criclebox cardbody mb-24 header-solid"
                        title={
                            <Statistic
                                title="Merma"
                                value={5}
                                suffix="pz"
                                style={{ fontSize: '20px' }}
                            />
                        }
                    />
                </Col>
                <Col span={6} md={6} xs={24}>
                    <Card
                        bordered={false}
                        bodyStyle={{ padding: '10px' }}
                        style={{  width: "100%" }}
                        className="criclebox cardbody mb-24 header-solid"
                        title={
                            <Statistic
                                title="Producido"
                                value={production.finalWeight}
                                suffix="kg"
                                style={{ fontSize: '20px' }}
                            />
                        }
                    />
                </Col>
            <Col span={24} md={24} xs={24}>
                    <Card
                        bordered={false}
                        bodyStyle={{ padding: "0px" }}
                        style={{ padding: 20, width: "100%" }}
                        className="criclebox cardbody mb-24 header-solid"
                        title={<Flex justify="space-between" align="middle"><h2>Empacado</h2><Button type="primary" onClick={() => setShowPackModal(true)}>Agregar empaque</Button></Flex>}
                        >
                        <div className="ant-list-box table-responsive">
                            <Table
                                style={{ width: '100%' }}
                                dataSource={production?.presentations}
                                columns={columns}
                                rowKey={(record, index) => index}
                                pagination={true}
                            />
                        </div>
                    </Card>
                </Col>
            
        </Row>
    )
}

export default FatDigestWeightDetail;