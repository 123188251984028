import React, { useEffect, useState } from 'react';
import { Modal, Button, Table, Input, Select, message } from 'antd';
import Parse from 'parse';

const { Option } = Select;

const saveIngredient = (production, packages, setOpen) => {
    const toSave = [];
    const Packaging = Parse.Object.extend('Packaging');
    const packagingObject = new Packaging();
    packagingObject.set('ingredients', packages.map(item => ({ name: item.name, quantity: item.added, objectId: item.objectId })));
    packagingObject.set('type', 'addPackages');
    packagingObject.save().then((result) => {
        const ProductionOrder = Parse.Object.extend('ProductionOrder');
        const productionOrder = new ProductionOrder();
        productionOrder.set('objectId', production.objectId);
        productionOrder.addUnique('packaging', result);
        productionOrder.increment('packed', packages.reduce((acc, item) => acc + parseFloat(item.added), 0));
        toSave.push(productionOrder);

        const Presentation = Parse.Object.extend('Presentation');
        packages.forEach(pack => {
            const presentation = new Presentation();
            presentation.set('objectId', pack.objectId);
            presentation.increment('stock', parseFloat(pack.added));
            toSave.push(presentation);
        });

        Parse.Object.saveAll(toSave).then(() => {
            message.success('Empacado agregado correctamente');
            setOpen(false);
        }, (error) => {
            console.error('Error while creating ProductionOrder: ', error);
            message.error('Error al agregar empacado');
        });   
    }, (error) => {
        console.error('Error while creating ProductionOrder: ', error);
        message.error('Error al agregar empacado');
    });
}

const AddPackedModal = ({ open, setOpen, production }) => {
    const [packed, setPacked] = useState(undefined);

    useEffect(() => {
        setPacked(production?.presentationPointers);
    }, [production]);

    return (
        <Modal
            title="Agregar empacado"
            visible={open}
            onOk={() => setOpen(false)}
            onCancel={() => setOpen(false)}
            footer={[
                <Button key="back" onClick={() => saveIngredient(production, packed, setOpen)}>
                    Confirmar
                </Button>,
            ]}
        >
            <Table 
                dataSource={packed}
                columns={[
                    {
                        title: "Presentación",
                        dataIndex: "name",
                        key: "name",
                        render: (text) => <h3>{text}</h3>,
                    },
                    {
                        title: "Cantidad",
                        dataIndex: "quantity",
                        key: "quantity",
                        render: (text, ingredient) => <h3>{ingredient.quantity}</h3>,
                    },
                    {
                        title: "Unidad",
                        dataIndex: "basePresentation",
                        key: "basePresentation",
                        render: (text) => <h3>{'pz'}</h3>,
                    },
                    {
                        title: "Agregado",
                        dataIndex: "added",
                        key: "added",
                        render: (text, ingredient) => <h2>{text}</h2>,
                    },
                    {
                        title: "Agregar",
                        dataIndex: "added",
                        key: "added",
                        render: (text, ingredient) => <Input 
                            type="number" 
                            value={text}
                            onChange={(e) => setPacked(packed.map(item => item.objectId === ingredient.objectId ? { ...item, added: e.target.value } : item))}
                        />,
                    }
                ]}
            />
        </Modal>
    );
}

export default AddPackedModal;