import React, { useState } from 'react';
import { Modal, Button, Form, Input, Select, message } from 'antd';
import Parse from 'parse';
const { Option } = Select;

const saveFinePowders = (production, finePowders, setOpen) => {
    const ProductionOrder = Parse.Object.extend('ProductionOrder');
    const productionOrder = new ProductionOrder();
    productionOrder.set('objectId', production.objectId);
    productionOrder.set('retrievedFinePowders', finePowders);
    productionOrder.save().then((result) => {
        message.success('Polvos finos agregados correctamente');
        setOpen(false);
    }, (error) => {
        console.error('Error while creating ProductionOrder: ', error);
        message.error('Error al agregar polvos finos');
    });
}

const SaveFinePowders = ({ open, setOpen, production }) => {
    const [finePowders, setFinePowders] = useState(null);

    return (
        <Modal
            title="Agregar polvos finos"
            visible={open}
            onOk={() => setOpen(false)}
            onCancel={() => setOpen(false)}
            footer={[
                <Button key="back" onClick={() => saveFinePowders(production, finePowders, setOpen)}>
                    Confirmar
                </Button>,
            ]}
        >
          <Input type='number' placeholder="Cantidad" onChange={(e) => setFinePowders(e.target.value)} value={finePowders} suffix="Kg"/>
        </Modal>
    );
}

export default SaveFinePowders;