import React, { Component, useEffect } from "react";
import { Card, Col, Row, Table, Typography, Tag, Button, FloatButton, Progress } from "antd";
import animalia from "../../images/animale.jpeg";
import NewInventoryEntry from "./components/NewInventoryEntry";
import { fetchWarehouseEntries } from "./utils/warehouseEntries";
import WarehouseEntryDetail from "./WarehouseEntryDetail";
import moment from "moment";

const { Title } = Typography;

const WarehouseEntryList = () => {
  const [openEntryModal, setOpenEntryModal] = React.useState(false);
  const [entry, setEntry] = React.useState(null);
   const [dataSource, setDataSource] = React.useState([]);

   useEffect(() => {
    fetchWarehouseEntries().then((warehouseEntries) => {
     setDataSource(warehouseEntries);
    });
    }, []);
  
    const columns = [
    
      {
        title: "Fecha",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (date) => (
          <>
            <h3>{moment(date).format("DD/MM/YYYY")}</h3>
          </>
        ),
      },
      {
        title: "Orden de compra",
        dataIndex: "folio",
        key: "folio",
        render: (text, entry) => (
         <Button type='link' onClick={() => {
          setOpenEntryModal(true)
          setEntry(entry)}
          }>
            <p>{text}</p>
          </Button>
        ),
      },
      // {
      //   title: "Proveedor",
      //   dataIndex: "supplier",
      //   key: "supplier",
      //   render: (supplier) => (
      //     <>
      //       <p>{supplier.name}</p>
      //     </>
      //   ),
      // },
      {
        title: "Recibido por",
        dataIndex: "user",
        key: "user",
        render: (text) => (
          <>
            <p>{text?.name}</p>
          </>
        ),
      },
      {
        title: "Productos",
        dataIndex: "products",
        key: "products",
        render: (products) => (
          products?.map((product, index) => (
            <div key={index}>
              <p>{product.received?.toLocaleString()} {product.presentation?.toLowerCase()}{product.received !== 1 ? 's' : ''} de {product.name?.toLowerCase()}</p>
            </div>
          ))
        ),
      }, 
     
      
    ];

    return (
      <div className="layout-content">
        <WarehouseEntryDetail open={openEntryModal} setOpen={setOpenEntryModal} entryInfo={entry} />
        <Row gutter={[24, 0]}>
          <Col lg={24} className="zindex">
            <h2 className="p-15 h2">Entradas de almacén</h2>

            <Card
              bordered={false}
              bodyStyle={{ padding: "0px" }}
              className="criclebox cardbody mb-24 header-solid"
            >
              <div className="ant-list-box table-responsive">
                <Table
                  dataSource={dataSource}
                  columns={columns}
                  rowKey={(record, index) => index}
                  pagination={true}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }


  export default WarehouseEntryList
