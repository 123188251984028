import React, { useEffect } from "react";
import { Card, Col, Row, Table, Typography, Statistic, FloatButton, Button } from "antd";
import ReactApexChart from "react-apexcharts";
import { useNavigate } from "react-router-dom";
import NewProduct from "./components/NewProduct";
import Parse from "parse";

const { Title } = Typography;

const fetchProducts = async () => {
  const Product = Parse.Object.extend("Product");
  const query = new Parse.Query(Product);
  query.include(["recipe", "presentations"]);
  const results = await query.find();
  return results.map((result) => result.toJSON());
};

const ProductInventory = () => {
  const [newProductModal, setNewProductModal] = React.useState(false);
  const [products, setProducts] = React.useState([]);
  const [shouldRefresh, setShouldRefresh] = React.useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchProducts().then((results) => setProducts(results));
  }, [shouldRefresh]);
  
  const refresh = () => {
    setShouldRefresh(!shouldRefresh);
  };
 
  // // Calculando la suma de TotalValue de todos los elementos en dataSource
  // const totalValueSum = dataSource.reduce((acc, item) => acc + item.TotalValue, 0);
  // const totalVolumeSum = dataSource.reduce((acc, item) => acc + item.quantity, 0);

  // Definición de columnas para la tabla de productos
  const columns = [
    
    {
      title: "Producto",
      dataIndex: "name",
      key: "name",
      render: (text, product) => <Button type="link" onClick={() => navigate(`/inventory/product-details/${product.objectId}`)}>{text}</Button>,
    },
    {
      title: "Stock",
      dataIndex: "stock",
      key: "stock",
      render: (text, product) => <p>{product.presentations.reduce((acc, item) => acc + item.stock, 0)} pz</p>,
    },
    {
      title: "Volúmen",
      dataIndex: "stock",
      key: "stock",
      render: (text, product) => <p>{product.presentations.reduce((acc, item) => acc + item.stock * item.content, 0)} kg</p>,
    },
    {
      title: "Producción Último Mes",
      dataIndex: "ProductionLastMonth",
      key: "ProductionLastMonth",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Ventas Último Mes",
      dataIndex: "SalesLastMonth",
      key: "SalesLastMonth",
      render: (text) => <p>{text}</p>,
    },
  ];

  // // Preparar datos para el gráfico de líneas
  // const chartData = {
  //   series: [
  //     {
  //       name: "Valor Total",
  //       data: dataSource.map(item => ({ x: item.date, y: item.TotalValue })),
  //     },
  //     {
  //       name: "Volumen Total",
  //       data: dataSource.map(item => ({ x: item.date, y: item.quantity })),
  //     },
  //   ],
  //   options: {
  //     chart: {
  //       type: 'line',
  //       height: 350,
  //       toolbar: {
  //         show: false,
  //       },
  //     },
  //     dataLabels: {
  //       enabled: false,
  //     },
  //     stroke: {
  //       curve: 'smooth',
  //     },
  //     xaxis: {
  //       type: 'datetime',
  //       categories: dataSource.map(item => item.date),
  //     },
  //     yaxis: [
  //       {
  //         title: {
  //           text: 'Valor ($)',
  //         },
  //       },
  //       {
  //         opposite: true,
  //         title: {
  //           text: 'Volumen (Unidades)',
  //         },
  //       },
  //     ],
  //     tooltip: {
  //       x: {
  //         format: 'dd/MM/yyyy',
  //       },
  //       y: {
  //         formatter: function (val, opts) {
  //           try {
  //             return opts.seriesIndex === 0 ? `$ ${val.toLocaleString()}` : `${val} unidades`;  
  //           } catch (error) {
  //             return `${val} unidades`;  
  //           }
  //         },
  //       },
  //     },
  //   },
  // };

  return (
    <div className="layout-content">
      <FloatButton onClick={() => setNewProductModal(true)} />
      <NewProduct open={newProductModal} setOpen={setNewProductModal} refresh={refresh} />
      <Row gutter={[24, 0]}>
        <Col lg={24} className="zindex">
          <h2 className="p-15 h2">Inventario de productos</h2>

          <Card
            bordered={false}
            bodyStyle={{ padding: "0px" }}
            className="criclebox cardbody mb-24 header-solid"
            title={<h3>Productos</h3>}
          >
            <div className="ant-list-box table-responsive">
              <Table
                dataSource={products}
                columns={columns}
                rowKey={(record, index) => index}
                pagination={true}
                expandable={{
                  expandedRowRender: (record) => (
                   <Table 
                      dataSource={record.presentations}
                      columns={[
                        {
                          title: "Presentación",
                          dataIndex: "name",
                          key: "name",
                          render: (text) => <p>{text}</p>,
                        },
                        {
                          title: "Stock",
                          dataIndex: "stock",
                          key: "stock",
                          render: (text) => <p>{text} pz</p>,
                        },
                        {
                          title: "Costo Unitario",
                          dataIndex: "totalCost",
                          key: "totalCost",
                          render: (text) => <p>${text?.toLocaleString()}</p>,
                        },
                        {
                          title: "Precio Unitario",
                          dataIndex: "unitPrice",
                          key: "unitPrice",
                          render: (text) => <p>{text}</p>,
                        },
                      ]}
                      rowKey={(record, index) => index}
                      pagination={false}
                    />
                  ),
                  rowExpandable: (record) => record.name,
                }}
              />
            </div>
          </Card>
        </Col>
      </Row>

      <Row gutter={[24, 0]}>
        {/* <Col lg={12} md={24} className="zindex">
          <Card
            bordered={false}
            bodyStyle={{ padding: "20px" }}
            className="criclebox cardbody mb-24 header-solid"
            title={<h3>Valor en $</h3>}
          >
            <Statistic title="Total" value={totalValueSum} prefix="$" style={{ fontSize: '20px' }} />
          </Card>
        </Col>
        <Col lg={12} md={24} className="zindex">
          <Card
            bordered={false}
            bodyStyle={{ padding: "20px" }}
            className="criclebox cardbody mb-24 header-solid"
            title={<h3>Volumen</h3>}
          >
            <Statistic title="Total" value={totalVolumeSum} suffix="unidades" style={{ fontSize: '20px' }} />
          </Card>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Card
            bordered={false}
            bodyStyle={{ padding: "20px" }}
            className="criclebox cardbody mb-24 header-solid"
            title={<h3>Estadísticas de Inventario</h3>}
          >
            <ReactApexChart
              options={chartData.options}
              series={chartData.series}
              type="line"
              height={350}
            />
          </Card> */}
        {/* </Col> */}
      </Row>
    </div>
  );
};

export default ProductInventory;
