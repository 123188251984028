import React from "react";
import { Row, Col, Card, Table } from "antd";
import { MinusOutlined } from '@ant-design/icons';
import ReactApexChart from "react-apexcharts";
import { Statistic } from 'antd';


const dataSource = [
  {
    Date: "16/06/2024",
    Order: "D0001",
    Product: 'Croqueta para perro',
    Quantity: '100 kg',
    cost: '$2,450.00',
  },
];

const columns = [
  {
    title: "Fecha",
    dataIndex: "Date",
    key: "Date",
    render: (text) => <h3>{text}</h3>,
  },
  {
    title: "Orden de compra",
    dataIndex: "Order",
    key: "Order",
    render: (text) => <h3>{text}</h3>,
  },
  {
    title: "Producto",
    dataIndex: "Product",
    key: "Product",
    render: (text) => <h3>{text}</h3>,
  },
  {
    title: "Cantidad",
    dataIndex: "Quantity",
    key: "Quantity",
    render: (text) => <h3>{text}</h3>,
  },
  {
    title: "Precio",
    dataIndex: "cost",
    key: "cost",
    render: (text) => <h3>{text}</h3>,
  },
];

const options = {
  chart: {
    width: "100%",
    height: 350,
    type: "area",
    toolbar: {
      show: false,
    },
  },
  legend: {
    show: false,
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
    colors: ["#1890FF", "#B37FEB"],
    width: 3,
  },
  yaxis: {
    labels: {
      style: {
        fontSize: "14px",
        fontWeight: 600,
        colors: ["#8c8c8c"],
      },
      offsetY: -50,
    },
  },
  xaxis: {
    labels: {
      style: {
        fontSize: "14px",
        fontWeight: 600,
        colors: [
          "#8c8c8c",
          "#8c8c8c",
          "#8c8c8c",
          "#8c8c8c",
          "#8c8c8c",
          "#8c8c8c",
          "#8c8c8c",
          "#8c8c8c",
          "#8c8c8c",
        ],
      },
    },
    categories: [
      "Ene",
      "Feb",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ],
  },
  grid: {
    show: true,
    borderColor: "rgba(0, 0, 0, .2)",
    strokeDashArray: 6,
    position: "back",
    xaxis: {
      lines: {
        show: false,
      },
    },
    yaxis: {
      lines: {
        show: true,
      },
    },
    row: {
      colors: undefined,
      opacity: 0.5,
    },
    column: {
      colors: undefined,
      opacity: 0.5,
    },
    padding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  },
  tooltip: {
    y: {
      formatter: function (val) {
        return val;
      },
    },
  },
};

const series = [
  {
    name: "Compras en pesos",
    data: [50, 40, 300, 220, 500, 250],
    offsetY: 0,
  },
  {
    name: "Kilos",
    data: [30, 90, 40, 140, 290, 290],
    offsetY: 0,
  },
];

const ClientPurchases = () => {
  return (
    <Row gutter={[24, 0]}>
      <Col span={8} lg={8} className="zindex">
        <Card title={<h2>Objetivos</h2>} style={{padding:20}}
        bordered={false}
        className="criclebox cardbody mb-24 header-solid">
              <Statistic
              style={{ padding: 20 }}
                title="Venta en kilos"
                value={"200 Kg"}
               />
        </Card>
      </Col>
      <Col span={32} lg={24} className="zindex">
        <Card
          style={{ padding: 20 }}
          bordered={false}
          bodyStyle={{ padding: "0px" }}
          className="criclebox cardbody mb-24 header-solid"
          title={<h2>Compras</h2>}
        >
          <div className="ant-list-box table-responsive">
            <div className="linechart">
              <div></div>
              <div className="sales">
                <ul>
                  <li>{<MinusOutlined />} Kilos</li>
                  <li>{<MinusOutlined />} Pesos</li>
                </ul>
              </div>
            </div>

            <ReactApexChart
              className="full-width"
              options={options}
              series={series}
              type="area"
              height={350}
              width={"100%"}
            />
          </div>
        </Card>
      </Col>
      <Col span={32} lg={24} className="zindex">
        <Card
          bordered={false}
          bodyStyle={{ padding: "0px" }}
          style={{ padding: 20 }}
          className="criclebox cardbody mb-24 header-solid"
          title={<h2> Detalle de compras</h2>}
        >
          <div className="ant-list-box table-responsive">
            <Table
              style={{ width: '100%' }}
              dataSource={dataSource}
              columns={columns}
              rowKey={(record, index) => index}
              pagination={true}
            />
          </div>
        </Card>
      </Col>
    </Row>

  );
};


export { ClientPurchases };


