import React, { useEffect, useState } from "react";
import { Flex, Button, Typography, Table, Modal, Checkbox, message, Select, Switch, FloatButton, Form, Input } from "antd";
import Parse from "parse";
import { findErrorMessage } from "../../../utils/parseErrors";
import { setACL } from "../../../utils/ACL";
import { createUser } from "./utils/users";

// Fetch users and their permissions
const queryUsersWithPermissions = async () => {
    const query = new Parse.Query(Parse.User);
    const users = await query.find();

    // Fetch permissions for each user
    const usersWithPermissions = await Promise.all(users.map(async (user) => {
        console.log('User:', user.toJSON());
        const permissionsRelation = user.relation('permissions');
        const userPermissions = await permissionsRelation.query().find();
        return {
            ...user.toJSON(), // Add user data
            permissions: userPermissions.map(permission => permission.toJSON()) // Add permissions data
        };
    }));

    return usersWithPermissions;
};

// Fetch roles with users and permissions
const queryRoles = async () => {
    const query = new Parse.Query(Parse.Role);
    query.include(['users', 'permissions']);
    const roles = await query.find();
    const rolesWithDetails = await Promise.all(roles.map(async (role) => {
        const usersRelation = role.relation('users');
        const users = await usersRelation.query().find();

        const permissionsRelation = role.relation('permissions');
        const permissions = await permissionsRelation.query().find();

        return {
            ...role.toJSON(),
            users: users?.map(user => user.toJSON()), // Convert to array of user names
            permissions: permissions.map(permission => permission.toJSON()), // Convert to array of permission names
        };
    }));

    return rolesWithDetails;
};

// Fetch all permissions (global permissions)
const queryPermissions = async () => {
    const query = new Parse.Query('Permission');
    const permissions = await query.find();
    return permissions?.map((permission) => permission.toJSON());
};

const RoleModal = ({ open, setOpen }) => {
    if (open) return (
        <Modal
            title={open?.name}
            open={open}
            onCancel={() => setOpen(false)}
            footer={[
                <Button key="close" onClick={() => setOpen(false)}>
                    Close
                </Button>
            ]}
        >
            <ul>
                {open?.permissions.map(permission => (
                    <li key={permission.objectId}>{permission.groupName}: {permission.name}</li>
                ))}
            </ul>
        </Modal>
    );
};


const UsersList = () => {
    const [users, setUsers] = useState(undefined);
    const [roles, setRoles] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [role, setRole] = useState(null);
    const [newUser, setNewUser] = useState(null);

    useEffect(() => {
        if (newUser) return;
        // Fetch users with their individual permissions and roles
        const getUsersAndRoles = async () => {
            const usersWithPermissions = await queryUsersWithPermissions();
            const roles = await queryRoles();
            const permissions = await queryPermissions();

            // Merge role permissions with user permissions
            const usersWithMergedPermissions = usersWithPermissions.map(user => {
                // Collect all permissions from roles
                console.log('Roles:', roles);
                const rolePermissions = roles
                    .filter(role => role.users.some(roleUser => roleUser.objectId === user.objectId))
                    .flatMap(role => role.permissions); // Flatten all role permissions

                console.log('Role permissions:', rolePermissions);

                // Merge role permissions with user-specific permissions
                const mergedPermissions = [...new Set([...rolePermissions, ...user.permissions])]; // Remove duplicates with Set

                return {
                    ...user,
                    role: roles.find(role => role.users.some(roleUser => roleUser.objectId === user.objectId)),
                    rolePermissions,
                    userPermissions: user.permissions, 
                    mergedPermissions,
                    permissions: mergedPermissions
                };
            });

            console.log('Users with merged permissions:', usersWithMergedPermissions);

            setUsers(usersWithMergedPermissions);
            setRoles(roles);
            setPermissions(permissions);
        };

        getUsersAndRoles();
    }, [newUser]);

    const renderPermissions = (user) => {
        const permissionGroups = permissions.reduce((acc, permission) => {
            const group = acc.find(group => group.groupName === permission.groupName);
            if (group) {
                group.permissions.push(permission);
            } else {
                acc.push({
                    groupName: permission.groupName,
                    permissions: [permission]
                });
            }
            return acc;
        }, []);

        
        // Render different columns for each permissionGroup with the permissions
        return  <Table dataSource={permissionGroups} columns={[
            {
                title: 'Grupo',
                dataIndex: 'groupName',
                key: 'groupName',
            },
            {
                title: 'Permisos',
                dataIndex: 'permissions',
                key: 'permissions',
                render: (text, record) => {
                    return (
                        <ul>
                            {record.permissions.map(permission => {
                                return (
                                    
                                    <Checkbox 
                                        key={permission.objectId} 
                                        checked={user.permissions.some(p => p.objectId === permission.objectId)} 
                                        disabled={user.rolePermissions.some(p => p.objectId === permission.objectId)}
                                        onChange={async (e) => {
                                            const userQuery = new Parse.Query(Parse.User);
                                            const Permission = Parse.Object.extend('Permission');
                                            const permissionQuery = new Parse.Query(Permission);
                                            permissionQuery.equalTo('objectId', permission.objectId);
                                            
                                            try {
                                                const permissionObject = await permissionQuery.first();
                                                if (!permissionObject) {
                                                    console.error('Permission object not found');
                                                    return;
                                                }
                                            
                                                console.log('Permission:', permission);
                                            
                                                // Fetch the user object to ensure it's saved
                                                const userObject = await userQuery.get(user.objectId);
                                                console.log('User object:', userObject);
                                            
                                                // Add or remove the permission
                                                const permissionsRelation = userObject.relation('permissions');
                                                console.log('Permissions relation:', permissionsRelation);
                                            
                                                let newUser;
                                                if (e.target.checked) {
                                                    permissionsRelation.add(permissionObject);
                                                    newUser = {
                                                        ...user,
                                                        permissions: [...user.permissions, permission]
                                                    };
                                                } else {
                                                    permissionsRelation.remove(permissionObject);
                                                    newUser = {
                                                        ...user,
                                                        permissions: user.permissions.filter(p => p.objectId !== permission.objectId)
                                                    };
                                                }
                                            
                                                // Save the user object
                                                await userObject.save();
                                                console.log('Updated user permissions in Parse');
                                            
                                                // Update local state
                                                setUsers(users.map(u => (u.objectId === user.objectId ? newUser : u)));
                                            } catch (error) {
                                                console.error('Error updating permissions:', error);
                                                message.error('Error al actualizar los permisos');
                                            }
                                            
                                        }}
                                    >{permission.groupName}: {permission.name}</Checkbox>
                                );
                            })}
                        </ul>
                    );
                }
            }
            
        ]} />
    };

    const selectRole = (user) => {
        return <Select 
            style={{ width: 200 }}
            value={user.role?.name}
            onChange={async (role) => {
                const userQuery = new Parse.Query(Parse.User);
                const userObject = await userQuery.get(user.objectId);
                console.log('User object:', userObject);

                const previousRoleQuery = new Parse.Query(Parse.Role);
                previousRoleQuery.containedIn('users', [userObject]);

                try {

                    const previousRoleObject = await previousRoleQuery.first();
                    if (previousRoleObject) {
                        console.log('Previous role object:', previousRoleObject);
                        const previousRoleRelation = previousRoleObject.relation('users');
                        previousRoleRelation.remove(userObject);
                        await previousRoleObject.save();
                        console.log('User removed from previous role:', previousRoleObject.get('name'));
                    } else {
                        console.log('User did not have a previous role.');
                    }

                    const roleQuery = new Parse.Query(Parse.Role);
                    roleQuery.equalTo('name', role);
                    const roleObject = await roleQuery.first();
                    console.log('Role object:', roleObject);

                    const roleRelation = roleObject.relation('users');
                    roleRelation.add(userObject);
                    await roleObject.save();
                    console.log('User added to new role:', roleObject.get('name'));

                    let newUsers = users.map(u => {
                        if (u.objectId === user.objectId) {
                            return { ...u, role: roles.find(r => r.name === role) };
                        }
                        return u;
                    });       
                    
                    console.log('New users:', newUsers);

                    setUsers([...newUsers]);
                }
                catch (error) {
                    console.error('Error updating user role:', error);
                    message.error('Error al actualizar el rol del usuario');
                }

            }
        }
        >
            {roles.map(role => (
                <Select.Option key={role.objectId} value={role.name}>{role.name}</Select.Option>
            ))}
        </Select>
    };

    const NewUserModal = ({ open, setOpen }) => {
        const [form] = Form.useForm();
        return (
            <Modal
                title="Nuevo usuario"
                open={open}
                onCancel={() => setOpen(false)}
                footer={[
                    <Button key="close" onClick={() => setOpen(false)}>
                        Cancelar
                    </Button>,
                     <Button key="close" onClick={() => form.submit()} type="primary">
                     Crear usuario
                 </Button>
                ]}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={async (values) => createUser(values, setOpen)
                }
                >
                    <Form.Item
                        label="Nombres"
                        name="firstName"
                        rules={[{ required: true, message: 'Por favor ingrese el nombre del usuario' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Apellidos"
                        name="lastName"
                        rules={[{ required: true, message: 'Por favor ingrese el apellido del usuario' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[{ required: true, message: 'Por favor ingrese el email del usuario' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Contraseña"
                        name="password"
                        rules={[{ required: true, message: 'Por favor ingrese la contraseña del usuario' }]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        label="Rol"
                        name="role"
                        rules={[{ required: true, message: 'Por favor seleccione el rol del usuario' }]}
                    >
                        <Select>
                            {roles.map(role => (
                                <Select.Option key={role.objectId} value={role.name}>{role.name}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    
                </Form>
            </Modal>
        );
    };



    

    return (
        <Flex vertical gap={20}>
            <RoleModal open={role} setOpen={setRole} />
            <NewUserModal open={newUser} setOpen={setNewUser} />
            <FloatButton onClick={() => setNewUser(true)} />
            <Typography.Title level={2}>Usuarios</Typography.Title>
            <Table
                dataSource={users?.map((user, index) => ({ ...user, key: user.objectId || index }))}
                expandable={{
                    expandedRowRender: user => {
                        return (
                            <div>
                               
                            <ul>
                               {renderPermissions(user)}
                            </ul>
                            </div>
                        );
                    },
                    
                }}
                loading={!users}
                columns={[
                    {
                        title: 'Nombre',
                        dataIndex: 'name',
                        key: 'name',
                        render: (text, record) => record.firstName ? `${record.firstName} ${record.lastName}` : record.name,
                        sorter: (a, b) => a.name.localeCompare(b.firstName)
                    },
                    {
                        title: 'Email',
                        dataIndex: 'email',
                        key: 'email',
                    },
                    {
                        title: 'Rol',
                        dataIndex: 'role',
                        key: 'role',
                        render: (text, record) => selectRole(record),
                        sorter: (a, b) => a.role?.name.localeCompare(b.role?.name)
                        
                    },
                    {
                        title: 'Activo',
                        dataIndex: 'active',
                        key: 'active',
                        render: (text, record) => <Switch checked={record.active} onChange={async (checked) => {
                            const userQuery = new Parse.Query(Parse.User);
                            const userObject = await userQuery.get(record.objectId);
                            userObject.set('active', checked);
                            await userObject.save();
                            setUsers(users.map(u => (u.objectId === record.objectId ? { ...u, active: checked } : u)));
                        }
                        } />
                    }
                ]}
            />
        </Flex>
    );
};

export default UsersList;
