import React, { useEffect, useState } from "react";
import { Button, Card, Input, Flex, Typography, Modal, Form, DatePicker, Select, message, Table} from "antd";
import Parse from "parse";
import moment from "moment";

const { Title } = Typography;


const NewWarehouseOrder = ({open, setOpen, object, parseClass, refresh}) => {
    const [disableSave, setDisableSave] = useState(false);
    const [order, setOrder] = useState({});

    const createOrder = async () => {
    }

    useEffect(() => {
        setOrder(object?.products?.map(product => ({ objectId: product.objectId, added: 0, pending: parseInt(product.quantity), quantity: parseInt(product.quantity) })))
    }, [object])

    if (!object) return null;

    const allowSave = () => {
        if (!object) return false;
        if (!order) return false;
        if (order?.length === 0) return false;
        // if (order?.some(product => product?.pending - product?.added < 0)) return false;
        return true;
    }

    if (!object) return null;

    return <Modal
        open={open}
        onCancel={() => setOpen(false)}
        footer={null}
        style={{ top: 20, width: "90%" }}
    >
        <Title level={4}>Nueva orden de almacén</Title>
        <Table dataSource={object?.products} columns={[
            {
                title: 'Producto',
                dataIndex: 'product',
                key: 'product',
                render: (product, record) => <p>{product?.name}</p>
            },
            {
                title: 'Pendiente',
                dataIndex: 'pending',
                key: 'pending',
                render: (text, record) => <p>{record?.quantity}</p>
            },
            {
                title: 'Cantidad',
                dataIndex: 'quantity',
                key: 'quantity',
                render: (text, record) => <Input type="number" value={record?.added} onChange={(e) => {
                    const newOrder = order.map(product => {
                        if (product.objectId === record.objectId) {
                            return { ...product, added: parseInt(e.target.value) }
                        }
                        return product;
                    })
                    console.log("📦 New Order: ", newOrder)
                    setOrder(newOrder);
                }} />
            }
        ]} />
        <Flex justify="end" gap={20}>
            <Button type="default" onClick={() => setOpen(false)}>Cancelar</Button>
            <Button type="primary" onClick={createOrder} disabled={!allowSave()}>Guardar</Button>
        </Flex>
    </Modal>
}

export default NewWarehouseOrder;