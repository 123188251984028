import Parse from "parse"
import { v4 as uuidv4 } from 'uuid';
import { message } from "antd";
import { setACL } from "../../../../utils/ACL";
import { findErrorMessage } from "../../../../utils/parseErrors";

const createUser = async (values, setOpen) => {
    console.log('Form values:', values);
        const userObject = new Parse.User();
        userObject.set('firstName', values.firstName?.trim());
        userObject.set('lastName', values.lastName?.trim());
        userObject.set('email', values.email?.trim());
        userObject.set('username', values.email?.trim());
        userObject.set('password', values.password?.trim());
        userObject.set('active', true);
        userObject.set('uuid', uuidv4());
        const acl = setACL(values.role);
        userObject.setACL(acl);

        try {
            const user = await userObject.save();
            console.log('User created:', user);
            const roleQuery = new Parse.Query(Parse.Role);
            roleQuery.equalTo('name', values.role);
            const role = await roleQuery.first();
            console.log('Role:', role);
            const roleRelation = role.relation('users');
            roleRelation.add(user);
            await role.save();
            message.success('Usuario creado exitosamente');
            setOpen(false);
        } catch (error) {
            const errorMessage = findErrorMessage(error);
            console.error('Error creating user:', error);
            message.error(errorMessage);
            
    }
}

export {
    createUser
}
                