import React, { useState } from "react";
import {
  Card,
  Row,
  Col,
  Button,
  Avatar,
  Timeline,
  Descriptions,
  Space,
  Typography
} from "antd";

const { Title, Text }  = Typography
const PurchaseOrder = () =>{

      return (
        <Card
          className="card-order header-solid mx-auto "
          title={[<h6 className="mb-0">Orden de Compra</h6>]}
          bordered={false}
          style={{ maxWidth: "800px" }}
        >
          <Row gutter={[0, 0]}>
            <Col span={0} md={0}>
              <p className="mb-0">
                Orden número # <strong>D0005</strong> Fecha: 
                <strong>10/06/2024</strong>
              </p>
            </Col>
            <Space style={{ justifyContent: 'space-around'}}>
                <Col span={0} md={0} className="ml-auto text-right">
                </Col>
            </ Space>
          </Row>
          <hr className="gradient-line" />
          <Row gutter={[0, 0]} className="order-products">
            <Col span={24} md={12} style={{ paddingBottom: 20 }}>
              <div className="avat-flex">
                <Avatar
                  src="https://http2.mlstatic.com/D_NQ_NP_721393-MLM71541852890_092023-O.webp"
                  shape="square"
                  size={110}
                  alt="photo-12"
                  className="mr-15"
                />
                <div>
                  <h6 className="mb-0 mt-10 font-semibold">Crouetas perro adulto</h6>
                  <p className="mb-15">Orden generada hace 5 días</p>
                  <p className="mb-15">Cantidad: 2 costales 25 kg</p>
                  <p className="mb-15">Total: $3,000</p>
                </div>
                
              </div>
            </Col>
            </Row>
            <Row>
            <Col span={24} md={12}>
              <div className="avat-flex">
                <Avatar
                  src= "https://http2.mlstatic.com/D_NQ_NP_992454-MLU75646953095_042024-O.webp"
                  shape="square"
                  size={110}
                  alt="photo-12"
                  className="mr-15"
                />
                <div>
                  <h6 className="mb-0 mt-10 font-semibold">Croquetas perro cachorro</h6>
                  <p className="mb-15">Orden generada hace 5 días</p>
                  <p className="mb-15">Cantidad: 2 costales 25 kg</p>
                  <p className="mb-15">Total $2,500</p>
                </div>
                
              </div>
            </Col>
            </Row>
            <Row>
            <Col span={24} md={12} className="text-right">
            </Col>
          </Row>
          <hr className="gradient-line" />
          <Row gutter={[24, 0]}>
            <Col span={24} md={12} lg={6}>
              <h5 className="mb-15">Historial de la orden</h5>
              <Timeline>
                <Timeline.Item color="green">
                  Orden Recibida
                  <small>10 Junio 2024 10:20 AM</small>
                </Timeline.Item>
                <Timeline.Item color="green">
                  Orden de compra generada #D0005
                  <small> 10 Junio 2024 10:45 AM</small>
                </Timeline.Item>
                <Timeline.Item>
                  Adelanto de pago
                  <small>13 Junio 4:35 PM</small>
                  <small>$3,000 </small>
                </Timeline.Item>
                <Timeline.Item>
                  Producto recibido
                  <small>15 Junio 4:54 PM</small>
                </Timeline.Item>
                <Timeline.Item>
                  Finiquito de pago total
                  <small>15 Junio 5:00 pm </small>
                  <small>$2,500 </small>
                </Timeline.Item>
              </Timeline> 
            </Col>
            <Col span={24} md={12} lg={10}>
              <h6 className="mb-15">Información de cliente</h6>
              <Card bordered={false} className="card-billing-info">
                <Descriptions title="Veterinaria Animale">
                  <Descriptions.Item label="Contacto" span={3}>
                    Juan Perez
                  </Descriptions.Item>
                  <Descriptions.Item label="Correo electrónico" span={3}>
                    Juan@animale.com
                  </Descriptions.Item>
                  <Descriptions.Item label="Telefono" span={3}>
                    3781234567
                  </Descriptions.Item>
                  <Descriptions.Item label="RFC" span={3}>
                    JPD872311H03
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            </Col>
            <Col className="ml-auto" span={24} md={12} lg={6}>
              <h6 className="mb-15">Desglose de cuenta</h6>
              <div className="mb-10 ant-order-flex">
                <span className="text-muted"> Precio Total </span>
                <span className="text-lg text-dark font-semibold ml-auto">
                  $5,500
                </span>
              </div>
              <div className="mb-10 ant-order-flex">
                <span className="text-muted"> Flete </span>
                <span className="text-lg text-dark font-semibold ml-auto">
                  $1,000
                </span>
              </div>
              <div className="mb-10 ant-order-flex">
                <span className="text-muted"> Impuestos.: </span>
                <span className="text-lg text-dark font-semibold ml-auto">
                  $880
                </span>
              </div>
              <div className="mb-10 ant-order-flex">
                <h5 className="font-regular text-muted"> Total: </h5>
                <h5 className="text-dark font-semibold ml-auto">$7,380</h5>
              </div>
              <div className="mb-10 ant-order-flex">
                <span className="text-muted"> Pagado </span>
                <span className="text-lg text-dark font-semibold ml-auto">
                  $5,500
                </span>
              </div>
              <div className="mb-10 ant-order-flex">
                <span className="text-muted"> Saldo restante </span>
                <span className="text-lg text-dark font-semibold ml-auto">
                  $1,880
                </span>
              </div>
            </Col>
          </Row>
        </Card>
      )
}

export default PurchaseOrder; 