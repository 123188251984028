import React, { useEffect, useState } from "react";
import { Table, Typography, Button, Space, Input, message, Select, Badge } from "antd";
import DebounceSelect from "../../../form/DebounceSelect";
import { PlusOutlined } from "@ant-design/icons";
import NewPresentation from "./NewPresentation";
import { presentations } from "../utils/proto";
import Ribbon from "antd/es/badge/Ribbon";
const { Title, Text } = Typography;

const PresentationList = ({ recipe, presentations, setPresentations }) => {
    const [products, setProducts] = useState([]);
    const [newPresentation, setNewPresentation] = useState(false);
    const [presentation, setPresentation] = useState(null);
    const [renderId, setRenderId] = React.useState(0);

    console.log('Presentations', presentations);

  
    return (
        <Space direction="horizontal" style={{ width: "100%" }}>
            <NewPresentation open={newPresentation} setOpen={setNewPresentation} addPresentation={(presentation) => {
                setPresentations([...presentations, presentation]);
                setRenderId(renderId + 1);
            }}
            recipe={recipe}
            presentation={presentation}
            />
            {presentations?.map((presentation, index) => (
                <Badge key={index} count={'X'} onClick={() => {
                    presentations.splice(index, 1);
                    setPresentations(presentations);
                }}
                style={{ cursor: 'pointer' }}>
                <Button key={index} style={{ width: "180px", height: "240px" }} onClick={() => {
                    setNewPresentation(true)
                    setPresentation(presentation);
                }}>
                    <Space direction="vertical">
                        <Title level={5}>{presentation.name}</Title>
                        <Text level={5}>{presentation.content} kg</Text>
                        <Text level={3} type="secondary">${presentation.minimumPrice}</Text>
                        {presentation?.packaging?.map((pack, index) => (
                            <>
                            <Text key={index}>{pack.quantity} {pack.name}</Text>
                            {/* <Text key={index}>{pack.quantity} {pack.unit}</Text> */}
                            </>
                        ))}
                    </Space>
                </Button>
                </Badge>
            ))}
            <Button
                style={{ width: "180px", height: "240px" }}
                type="dashed"
                onClick={() => setNewPresentation(true)}
            >
                <Space direction="vertical">
                    <PlusOutlined />
                    <Title level={5}>Agregar presentación</Title>
                </Space>
            </Button>
       
        </Space>
    );
}

export default PresentationList;
