import React, { useEffect, useState } from "react";
import { Table, Typography, Button, Space, Input, message, Select } from "antd";
import DebounceSelect from "../../../form/DebounceSelect";
import { fetchIngredients } from "../utils/inventory";
import { PlusOutlined } from "@ant-design/icons";

const { Title } = Typography;

const IngredientList = ({ form, setRenderId }) => {
    const [ingredients, setIngredients] = useState([]);

    const unitCost = () => {
        const totalCost = ingredients.reduce((acc, ingredient) => acc + ingredient.baseCost * ingredient.quantity, 0);
        let unitCost = totalCost/form.getFieldValue("finalWeight");
        isNaN(unitCost) && (unitCost = 0);
        return unitCost.toFixed(2);
    }

    const totalCost = () => {
        let totalCost = ingredients.reduce((acc, ingredient) => acc + ingredient.baseCost * ingredient.quantity, 0);
        isNaN(totalCost) && (totalCost = 0);
        return totalCost.toFixed(2);
    }


    const onIngredientSelected = (objectId, ingredient) => {
        console.log("ingredient", ingredient);
        if (ingredients.find((p) => p.objectId === ingredient.objectId)) {
            return;
        }
        ingredients.push({
            ...ingredient,
            quantity: 1,
            total: 0,
        });
        setIngredients(ingredients);
        setRenderId((prev) => prev + 1);
    };

    return (
        <Space direction="vertical" style={{ width: "100%" }}>
            <DebounceSelect
                fetchOptions={fetchIngredients}
                onChange={onIngredientSelected}
                placeholder="Buscar ingrediente"
                style={{ width: "100%" }}
                allowClear
                showSearch
            />
        <Table
            dataSource={ingredients}
            rowKey="objectId"
            pagination={false}
            scroll={{ x: true }}
        >
            <Table.Column
                title="Insumo"
                dataIndex="name"
                key="name"
            />
           
          
            <Table.Column
                title="Cantidad"
                dataIndex="quantity"
                key="quantity"
                render={(text, record) => (
                    <Input
                        type="number"
                        value={text}
                        onClick={(e) => e.target.select()}
                        style={{ width: 80 }}
                        sufix={record?.basePresentation}
                        onChange={(e) => {
                            const value = e.target.value;
                            const ingredient = ingredients.find((p) => p.objectId === record.objectId);
                            ingredient.quantity = value;
                            ingredient.total = ingredient.lastCost * value;
                            setRenderId((prev) => prev + 1);
                        }}
                    />
                )}
            />

            <Table.Column
                title="Costo unitario"
                dataIndex="baseCost"
                key="baseCost"
                render={(text, record) => (
                    <p>${text.toFixed(2)}</p>
                )}
            />

            <Table.Column
                title="Costo total"
                dataIndex="total"
                key="total"
                render={(text, record) => (
                    <p>${(record.baseCost * record.quantity).toFixed(2)}</p>
                )}
            />
            
           
            <Table.Column
                title="Acción"
                key="action"
                render={(text, record) => (
                    <Button
                        type="danger"
                        onClick={() => {
                            setIngredients(ingredients.filter((p) => p.objectId !== record.objectId));
                            setRenderId((prev) => prev + 1);
                        }}
                    >
                        Eliminar
                    </Button>
                )}
            />
        </Table>
        <Title level={5}>Costo total: ${totalCost()}</Title>
        <Title level={5}>Costo por Kg: ${unitCost()}</Title>
        </Space>
    );
}

export default IngredientList;
