import React, { Component } from "react";
import {
  Card,
  Row,
  Col,
  Button,
  Avatar,
  Tag,
  Timeline,
  Tooltip,
  Descriptions,
  Space,
  Typography
} from "antd";
import photo12 from "../../images/photo-12.jpg";
import mastercard from "../../images/mastercard-logo.png";
import { InfoOutlined, IdcardFilled } from "@ant-design/icons";
import { Receipt } from 'react-bootstrap-icons'
import santander from "../../images/Santander.png";
import proteina from "../../images/proteina.png";
import trigo from "../../images/trigo.png";

const { Title, Text }  = Typography
export default class OrderDetails extends Component {
  render() {
    return (
      <>
      {/* ToDo:  */}
        <Card
          className="card-order header-solid mb-24 mx-auto mt-20 mb-50"
          title={[<h6 className="mb-0">Detalles de Orden</h6>]}
          bordered={false}
          style={{ maxWidth: "800px" }}
        >
          <Row gutter={[24, 0]}>
            <Col span={24} md={8}>
              <p className="mb-0">
                Orden número # <strong>001234</strong> Fecha: 
                <strong>23/02/2021</strong>
              </p>
            </Col>

            <Space style={{ justifyContent: 'space-around'}}>
                <Col span={24} md={8} className="ml-auto text-right">
                <Button style={{ height: '100%'}}>
                    <Row>
                        <Col>
                    <Receipt style={{ fontSize: 20 }} />
                    </Col>
                    <Col>
                        <Row>
                        <Text style={{ fontSize: 16, fontWeight: 'bold' }}>Factura</Text>
                        </Row>
                        <Row>
                        <Text>2 de junio del 2024</Text>
                        </Row>
                    </Col>
                    </Row>
                </Button>
                </Col>
                <Col span={24} md={8} className="ml-auto text-right">
                <Button>Complemento de pago</Button>
                </Col>
            </ Space>
          </Row>
          <hr className="gradient-line" />
          <Row gutter={[24, 0]} className="order-products">
            <Col span={24} md={12} style={{ paddingBottom: 20 }}>
              <div className="avat-flex">
                <Avatar
                  src={trigo}
                  shape="square"
                  size={110}
                  alt="photo-12"
                  className="mr-15"
                />
                <div>
                  <h6 className="mb-0 mt-10 font-semibold">Trigo</h6>
                  <p className="mb-15">Orden generada hace 5 días</p>
                  <p className="mb-15">Cantidad: 10 kilos</p>
                  <p className="mb-15">Total: $10,000</p>
                </div>
                
              </div>
            </Col>
            </Row>
            <Row>
            <Col span={24} md={12}>
              <div className="avat-flex">
                <Avatar
                  src={proteina}
                  shape="square"
                  size={110}
                  alt="photo-12"
                  className="mr-15"
                />
                <div>
                  <h6 className="mb-0 mt-10 font-semibold">Proteína</h6>
                  <p className="mb-15">Orden generada hace 5 días</p>
                  <p className="mb-15">Cantidad: 5 kilos</p>
                  <p className="mb-15">Total $10,000</p>
                </div>
                
              </div>
            </Col>
            </Row>
            <Row>
            <Col span={24} md={12} className="text-right">
            </Col>
          </Row>
          <hr className="gradient-line" />
          <Row gutter={[24, 0]}>
            <Col span={24} md={12} lg={6}>
              <h5 className="mb-15">Historial de la orden</h5>
              <Timeline>
                <Timeline.Item color="green">
                  Orden Recibida
                  <small>10 Junio 2024 10:20 AM</small>
                </Timeline.Item>
                <Timeline.Item color="green">
                  Orden de compra generada #1832412
                  <small> 10 Junio 2024 10:45 AM</small>
                </Timeline.Item>
                <Timeline.Item>
                  Adelanto de pago
                  <small>13 Junio 4:35 PM</small>
                  <small>$12,000 </small>
                </Timeline.Item>
                <Timeline.Item>
                  Producto recibido
                  <small>15 Junio 4:54 PM</small>
                </Timeline.Item>
                <Timeline.Item>
                  Finiquito de pago total
                  <small>15 Junio 5:00 pm </small>
                  <small>$8,000 </small>
                </Timeline.Item>
              </Timeline> 
            </Col>
            <Col span={24} md={12} lg={10}>
              <h5 className="mb-15">Pago </h5>
              <Card className="payment-method-card mb-24">
                <img src={santander} alt="" />
                <h6 className="card-number">**** **** **** 7362</h6>
              </Card>
              <h6 className="mb-15">Información de cliente</h6>
              <Card bordered={false} className="card-billing-info">
                <Descriptions title="Veterinaria Animale">
                  <Descriptions.Item label="Contacto" span={3}>
                    Juan Perez
                  </Descriptions.Item>
                  <Descriptions.Item label="Correo electrónico" span={3}>
                    Juan@animale.com
                  </Descriptions.Item>
                  <Descriptions.Item label="Telefono" span={3}>
                    3781234567
                  </Descriptions.Item>
                  <Descriptions.Item label="RFC" span={3}>
                    JPD872311H03
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            </Col>
            <Col className="ml-auto" span={24} md={12} lg={6}>
              <h6 className="mb-15">Desglose de cuenta</h6>
              <div className="mb-10 ant-order-flex">
                <span className="text-muted"> Precio Total </span>
                <span className="text-lg text-dark font-semibold ml-auto">
                  $15,000
                </span>
              </div>
              <div className="mb-10 ant-order-flex">
                <span className="text-muted"> Flete </span>
                <span className="text-lg text-dark font-semibold ml-auto">
                  $1,000
                </span>
              </div>
              <div className="mb-10 ant-order-flex">
                <span className="text-muted"> Impuestos.: </span>
                <span className="text-lg text-dark font-semibold ml-auto">
                  $4,000
                </span>
              </div>
              <div className="mb-10 ant-order-flex">
                <h5 className="font-regular text-muted"> Total: </h5>
                <h5 className="text-dark font-semibold ml-auto">$20,000</h5>
              </div>
              <div className="mb-10 ant-order-flex">
                <span className="text-muted"> Pagado </span>
                <span className="text-lg text-dark font-semibold ml-auto">
                  $12,000
                </span>
              </div>
              <div className="mb-10 ant-order-flex">
                <span className="text-muted"> Saldo restante </span>
                <span className="text-lg text-dark font-semibold ml-auto">
                  $8,000
                </span>
              </div>
            </Col>
          </Row>
        </Card>
      </>
    );
  }
}
