import React, { useEffect } from 'react';
import { Row, Col, Card, Statistic, Progress, Flex, Divider } from 'antd';
import SaveFinePowders from './Modals/SaveFinePowders';
import SetFinalWeightModal from './Modals/SetFinalWeightModal';
import SetColorsModal from './Modals/SetColorsModal';


const FatDigestWeightDetail = (props) => {
    const [showSaveFinePowders, setShowSaveFinePowders] = React.useState(false);
    const [showSetFinalWeight, setShowSetFinalWeight] = React.useState(false);
    const [showSetColors, setShowSetColors] = React.useState(false);
    const [production, setProduction] = React.useState(props.production);

    useEffect(() => {
        if (!production?.colors) {
            const colors = [{
                color: 'Rojo',
                percent: 0,
                colorCode: '#ff0000'
            },
            {   
                color: 'Verde',
                percent: 0,
                colorCode: '#00ff00'
            },
            {
                color: 'Azul',
                percent: 0,
                colorCode: '#0000ff'
            },
            {
                color: 'Amarillo',
                percent: 0,
                colorCode: '#ffff00'
            
            }]
            production.colors = colors;
            setProduction(production);
        }
    }, [production]);
                
        
    return (
        <Row gutter={24}>
            <SaveFinePowders open={showSaveFinePowders} setOpen={setShowSaveFinePowders} production={production} />
            <SetFinalWeightModal open={showSetFinalWeight} setOpen={setShowSetFinalWeight} production={production} />
            <SetColorsModal open={showSetColors} setOpen={setShowSetColors} production={production} colors={production?.colors} />
            <Col span={24}>
            <Row gutter={24}>
               
                <Col span={6} md={6} xs={24}>
                    <Card
                        bordered={false}
                        bodyStyle={{ padding: '10px' }}
                        style={{ margin: '10px', width: "100%" }}
                        className="criclebox cardbody mb-24 header-solid"
                        onClick={() => setShowSaveFinePowders(true)}
                        title={
                            <Statistic
                                title="Polvos finos obtenidos"
                                value={production?.retrievedFinePowders}
                                suffix="KG"
                                style={{ fontSize: '20px' }}
                            />
                        }
                    />
                </Col>
               
                <Col span={6} md={6} xs={24}>
                    <Card
                        bordered={false}
                        bodyStyle={{ padding: '10px' }}
                        style={{ margin: '10px', width: "100%" }}
                        className="criclebox cardbody mb-24 header-solid"
                        title={
                            <Statistic
                                title="Merma"
                                value={.5}
                                suffix="KG"
                                style={{ fontSize: '20px' }}
                            />
                        }
                    />
                </Col>
                <Col span={24} md={6} xs={24}>
                    <Card
                        bordered={false}
                        bodyStyle={{ padding: '10px' }}
                        style={{ margin: '10px', width: "100%" }}
                        className="criclebox cardbody mb-24 header-solid"
                        onClick={() => setShowSetFinalWeight(true)}
                        title={
                            <Statistic
                                title="Total producido"
                                value={production?.finalWeight}
                                suffix="KG"
                                style={{ fontSize: '20px' }}
                            >
                                <Progress percent={50} />
                            </Statistic>
                        }
                    />
                </Col>
                </Row>
               
            </Col>
            <Row gutter={24} onClick={() => setShowSetColors(true)}>
                {production?.colors?.map(color => (
                    <Col span={6} md={6} xs={24}>
                        <Card
                            bordered={false}
                            bodyStyle={{ padding: '10px' }}
                            style={{ margin: '10px', width: "100%" }}
                            className="criclebox cardbody mb-24 header-solid"
                            title={
                                <Statistic
                                    title={color.color}
                                    value={color.percent}
                                    suffix="%"
                                    style={{ fontSize: '20px' }}
                                />
                            }
                        >
                            <Progress percent={color.percent} strokeColor={color.colorCode} />
                        </Card>
                    </Col>
                ))}
            </Row>
            </Row>
        );
}

export default FatDigestWeightDetail;