import React, { useState, useRef } from "react";
import { Modal, Button, Space } from "antd";
import {Camera} from "react-camera-pro";

const CameraModal = ({ open, setOpen, onImageTook}) => {
    const camera = useRef(null);
    const [numberOfCameras, setNumberOfCameras] = useState(0);


    if (!open) {
        return null;
    }
  return (
    <Modal
      title="Tomar foto"
      open={open}

      footer={[
        <Button key="back" onClick={() => setOpen(false)}>
          Cancelar
        </Button>,
        <Button key="submit" type="primary" onClick={() => {
            const image = camera.current.takePhoto()
            onImageTook(prev => [...prev, image])
            setOpen(false)
        } }>
          Tomar foto
        </Button>,
        <Button key="change" onClick={() => camera.current.switchCamera()} hidden={numberOfCameras < 2}>
            Cambiar cámara
        </Button>
      ]}
    >
        <Space direction="vertical" style={{width: "20vw", height: "40vh"}}>
            <Camera  aspectRatio={9 / 16} ref={camera} numberOfCamerasCallback={setNumberOfCameras} />
        </Space>
    </Modal>
  );
}

export default CameraModal;
   