import React, { useRef } from 'react';
import { Row, Col, Card, Table, Carousel, Avatar } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

const dataSource = {
    "id": 1,
    "name": "Croquetas para adulto",
    "ingredients": [
        {
            "ingredient": "Proteína",
            "quantity": "5 kg",
        },
        {
            "ingredient": "Grasa",
            "quantity": "2 kg",
        },
        {
            "ingredient": "Fibra",
            "quantity": "500 g",
        },
        {
            "ingredient": "Minerales",
            "quantity": "200 g",
        },
        {
            "ingredient": "Vitaminas",
            "quantity": "100 g",
        },
    ],
    "presentations": [
        {
            "description": "5 kilogramos",
            "cost": "$15",
            "sell": "$50",
            "supplies": [
                {
                    "supply": "Costal para 5kg",
                    "quantity": "5 unidades",
                },
                {
                    "supply": "Hilo",
                    "quantity": "50 metros",
                },
                {
                    "supply": "Ribete",
                    "quantity": "100 cm",
                },
            ]
        },
        {
            "description": "10 kilogramos",
            "cost": "$30",
            "sell": "$100",
            "supplies": [
                {
                    "supply": "Costal para 10kg",
                    "quantity": "10 unidades",
                },
                {
                    "supply": "Hilo",
                    "quantity": "100 metros",
                },
                {
                    "supply": "Ribete",
                    "quantity": "200 cm",
                },
            ]
        },
        {
            "description": "20 kilogramos",
            "cost": "$50",
            "sell": "$180",
            "supplies": [
                {
                    "supply": "Costal para 20kg",
                    "quantity": "20 unidades",
                },
                {
                    "supply": "Hilo",
                    "quantity": "200 metros",
                },
                {
                    "supply": "Ribete",
                    "quantity": "400 cm",
                },
            ]
        }
    ]
};

const ingredientColumns = [
    {
        title: "Ingrediente",
        dataIndex: "ingredient",
        key: "ingredient",
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Cantidad",
        dataIndex: "quantity",
        key: "quantity",
        render: (text) => <p>{text}</p>,
    },
];

const supplyColumns = [
    {
        title: "Insumo",
        dataIndex: "supply",
        key: "supply",
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Cantidad",
        dataIndex: "quantity",
        key: "quantity",
        render: (text) => <p>{text}</p>,
    },
];

const presentationColumns = [
    {
        title: "Costo",
        dataIndex: "cost",
        key: "cost",
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Venta",
        dataIndex: "sell",
        key: "sell",
        render: (text) => <p>{text}</p>,
    },
];

const NextArrow = ({ onClick }) => {
    return (
        <div className="custom-arrow custom-arrow-right" onClick={onClick} style={{ marginTop: '10px', cursor: 'pointer' }}>
            <RightOutlined />
        </div>
    );
};

const PrevArrow = ({ onClick }) => {
    return (
        <div className="custom-arrow custom-arrow-left" onClick={onClick} style={{ marginTop: '10px', cursor: 'pointer' }}>
            <LeftOutlined />
        </div>
    );
};

const Recipe = () => {
    const carouselRef = useRef();

    return (
        <Row>
            <h1 style={{ fontWeight: "bold", fontSize: "30px" }}>Receta</h1>
            <Card title={
                <Avatar.Group>
                    <Avatar size={74} shape="square" src="https://http2.mlstatic.com/D_NQ_NP_961228-MLM72596894113_102023-O.webp" />
                    <div className="avatar-info">
                        <h4 className="font-semibold m-0">{`${dataSource.name}`}</h4>
                    </div>
                </Avatar.Group>
            } style={{ width: "100%" }}>
                <Row gutter={16}>
                    <Col span={12} md={12} lg={12}>
                        <Card
                            bordered={false}
                            bodyStyle={{ padding: "0px" }}
                            className="criclebox cardbody mb-24 header-solid"
                            title={<h3>Ingredientes</h3>}
                        >
                            <div className="ant-list-box table-responsive">
                                <Table
                                    dataSource={dataSource.ingredients}
                                    columns={ingredientColumns}
                                    rowKey={(record, index) => index}
                                    pagination={true}
                                />
                            </div>
                        </Card>
                    </Col>
                    <Col span={12} md={12} lg={12}>
                        <Card
                            bordered={false}
                            bodyStyle={{ padding: "0px" }}
                            className="criclebox cardbody mb-24 header-solid"
                            title={<h3>Presentaciones</h3>}
                        >
                            <Carousel dots={true} arrows={false} style={{ margin: "20px 20px" }} ref={carouselRef}>
                                {dataSource.presentations.map((presentation, index) => (
                                    <div key={index}>
                                        <h4>{presentation.description}</h4>
                                        <Table
                                            dataSource={presentation.supplies}
                                            columns={supplyColumns}
                                            rowKey={(record, index) => index}
                                            pagination={false}
                                        />
                                        <h4 style={{ marginTop: "20px" }}>Relación Costo-Venta</h4>
                                        <Table
                                            dataSource={[presentation]}
                                            columns={presentationColumns}
                                            rowKey={(record, index) => index}
                                            pagination={false}
                                        />
                                    </div>
                                ))}
                            </Carousel>
                            <Row style={{ textAlign: 'center', marginTop: '10px' }}>
                                <Col span={12}>
                                    <PrevArrow onClick={() => carouselRef.current.prev()} />
                                </Col>
                                <Col span={12}>
                                    <NextArrow onClick={() => carouselRef.current.next()} />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Card>
        </Row>
    );
};

export default Recipe;
