import React, { useEffect, useState } from "react";
import { Table, Typography, Button, Space, Input, message, Select } from "antd";
import DebounceSelect from "../../../form/DebounceSelect";
import { fetchPackaging } from "../utils/inventory";
import { PlusOutlined } from "@ant-design/icons";

const { Title } = Typography;

const PackagingList = ({ presentation, recipe, setPresentation, setRenderId }) => {
    const [packaging, setPackaging] = useState([])

    useEffect(() => {
        if (packaging?.length) {
            setPresentation({
                ...presentation,
                packaging,
            });
        }
    }, [packaging]);
    

    const onItemSelected = (objectId, item) => {
        console.log("item", item);
        if (packaging?.find((p) => p.objectId === item.objectId)) {
            return;
        }
        packaging.push({
            ...item,
            quantity: 1,
            total: item.lastCost,
        });
        setPackaging(packaging);
        setRenderId((prev) => prev + 1);
    };

    return (
        <Space direction="vertical" style={{ width: "100%" }}>
            <DebounceSelect
                fetchOptions={fetchPackaging}
                onChange={onItemSelected}
                placeholder="Buscar insumo"
                style={{ width: "100%" }}
                allowClear
                showSearch
            />
        <Table
            dataSource={packaging}
            rowKey="objectId"
            pagination={false}
            scroll={{ x: true }}
        >
            <Table.Column
                title="Insumo"
                dataIndex="name"
                key="name"
            />
           
          
            <Table.Column
                title="Cantidad"
                dataIndex="quantity"
                key="quantity"
                render={(text, record) => (
                    <Input
                        type="number"
                        value={text}
                        onClick={(e) => e.target.select()}
                        style={{ width: 80 }}
                        sufix={record.basePresentation}
                        onChange={(e) => {
                            console.log("e", record);
                            const value = e.target.value;
                            let item = packaging.find((p) => p.objectId === record.objectId);
                            item.quantity = value;
                            item.total = item.lastCost * value;
                            console.log("item", item);
                            const newItems = packaging.map((p) => (p.objectId === record.objectId ? item : p));
                            setPackaging(newItems);
                            setRenderId((prev) => prev + 1);
                        }}
                    />
                )}
            />
            
           
            <Table.Column
                title="Acción"
                key="action"
                render={(text, record) => (
                    <Button
                        type="danger"
                        onClick={() => {
                            setPackaging(packaging.filter((p) => p.objectId !== record.objectId));
                            setRenderId((prev) => prev + 1);
                        }}
                    >
                        Eliminar
                    </Button>
                )}
            />
        </Table>
        </Space>
    );
}

export default PackagingList;
