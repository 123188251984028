import React, { useState } from "react";
import { Row, Col, Card, Table, Switch } from "antd";

const SupplierSales = () => {
  const [dataSource, setData] = useState([
    {
      key: '1',
      Date: "16/06/2024",
      Product: "Costales 10 x 30 impreso 1 cara",
      Quantity: '1,000',
      UnitCost: "$10.00", 
      total: "$10,000.00"
    },
    {
      key: '1',
      Date: "16/06/2024",
      Product: "Costales 10 x 20 impreso 1 cara",
      Quantity: '500',
      UnitCost: "$5.00", 
      total: "$2,500.00"
    },
    {
      key: '1',
      Date: "12/06/2024",
      Product: "Costales 10 x 30 blanco",
      Quantity: '1,000',
      UnitCost: "$5.00", 
      total: "$5,000.00"
    },
    {
      key: '1',
      Date: "05/06/2024",
      Product: "Costales 50 x 50 impreso 1 cara",
      Quantity: '500',
      UnitCost: "$20.00", 
      total: "$10,000.00"
    },
    {
      key: '1',
      Date: "25/05/2024",
      Product: "Costales 25 x 30 impreso 1 cara",
      Quantity: '1,000',
      UnitCost: "$10.00", 
      total: "$10,000.00"
    },

  
  ]);

  const columns = [
    {
      title: "Fecha",
      dataIndex: "Date",
      key: "Date",
      render: (text) => <h3>{text}</h3>,
    },
    {
      title: "Producto",
      dataIndex: "Product",
      key: "Product",
      render: (text) => <h3>{text}</h3>,
    },
    {
      title: "Cantidad",
      dataIndex: "Quantity",
      key: "Quantity",
      render: (text) => <h3>{text}</h3>,
    },
    {
      title: "Costo Unitario",
      dataIndex: "UnitCost",
      key: "UnitCost",
      render: (text) => <h3>{text}</h3>,
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (text) => <h3>{text}</h3>,
    },
  ];

  return (
    <Row gutter={[24, 0]}>
      <Col span={24} lg={24} className="zindex">
        <Card
          bordered={false}
          bodyStyle={{ padding: "0px" }}
          className="criclebox cardbody mb-24 header-solid"
          title={<h2>Compras</h2>}
        >
          <div className="ant-list-box table-responsive">
            <Table
              style={{ width: '100%' }}
              dataSource={dataSource}
              columns={columns}
              rowKey="key"
              pagination={true}
            />
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export {SupplierSales};
