import React, { useEffect, useState } from "react";
import { Form, Typography, Button, Space, Input, message, Modal } from "antd";
import PackagingList from "./PackagingList";

const { Title } = Typography;

const NewPresentation = ({ open, setOpen, addPresentation, recipe }) => {
    const [presentation, setPresentation] = useState([]);
    const [renderId, setRenderId] = useState(0);
    const [form] = Form.useForm();

    const handleSubmit = () => {
        let presentationObject = form.getFieldsValue();
        form.validateFields().then((values) => {
            presentationObject.packaging = presentation.packaging;
            presentationObject.baseCost = parseFloat(recipe?.baseCost);
            presentationObject.totalCost = parseFloat(totalCost());
            presentationObject.minPrice = parseFloat(values.minimumPrice);
            console.log("values", values);
            addPresentation(presentationObject);
            setOpen(false);
        }).catch((error) => {
            console.log(error);
        });
    }

    const totalCost = () => {
        console.log("presentation", presentation);
        let totalCost = presentation?.packaging?.reduce((acc, item) => acc + parseFloat(item.baseCost) * parseFloat(item.quantity), 0);
        console.log("totalCost", totalCost);
        totalCost = totalCost + (recipe?.baseCost * parseFloat(form.getFieldValue("content")));
        console.log("totalCost", totalCost);
        isNaN(totalCost) && (totalCost = 0);
        return totalCost.toFixed(2);
    }

    return (
      <Modal 
        title="Nueva presentación"
        open={open}
        onCancel={() => setOpen(false)}
        footer={[
            <Button key="back" onClick={() => setOpen(false)}>
                Cancelar
            </Button>,
            <Button key="submit" type="primary" onClick={handleSubmit}>
                Guardar
            </Button>,
        ]}
        >
        <Form
            layout="vertical"
            form={form}
            name="basic"
        >
            <Form.Item
                label="Nombre"
                name="name"
                rules={[{ required: true, message: 'Por favor ingrese el nombre de la presentación' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Contenido"
                name="content"
                rules={[{ required: true, message: 'Por favor ingrese el contenido de la presentación' }]}
                shouldUpdate
            >
                <Input suffix="kg" />
            </Form.Item>
            <Form.Item
                label="Precio mínimo"
                name="minimumPrice"
                rules={[{ required: true, message: 'Por favor ingrese el precio de la presentación' }]}
            >
                <Input />
            </Form.Item>
            <Title level={5}>Empaque</Title>

            <PackagingList recipe={recipe} presentation={presentation} setPresentation={setPresentation} setRenderId={setRenderId} />
        </Form>

        <Title level={5}>Costo total: ${totalCost()}</Title>
        
        </Modal>
    );
}

export default NewPresentation;