import React from "react";
import { Row, Col, Card, Table, Statistic, Space } from "antd";

const comissionPercentage = 30;

const getComissionPercentage = (amount) => {
    const numericAmount = parseFloat(amount.replace(/[$,]/g, ''));
    const comission = numericAmount * comissionPercentage / 100;
    return `$${comission.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
};

const columns = [
    {
        title: 'No. de Orden',
        dataIndex: 'order',
        key: 'order',
    },
    {
        title: 'Fecha',
        dataIndex: 'date',
        key: 'date',
    },
    {
        title: 'Total',
        dataIndex: 'total',
        key: 'total',
    },
    {
        title: 'Pago',
        dataIndex: 'paid',
        key: 'paid'
    },
];

const data = [
    {
        key: '1',
        date: '25/05/24',
        order: 'D0001',
        paid: '$8,900.00',
        total: "$20,000",
    },
    {
        key: '2',
        date: '06/06/24',
        order: 'D0003',
        paid: '$6,200.00',
        total: "$15,000",
    },
    {
        key: '3',
        date: '20/06/24',
        order: 'D0006',
        paid: '$10,300.00',
        total: "$28,000"
    },
];


const SupplierComissions = () => {
    return (
        <Row gutter={[24, 0]}>
            <Col span={8} lg={50} className="zindex">
                <Card title={<h2>Crédito</h2>}>
                    <Row style={{ paddingLeft: 20, paddingBottom: 20 }}>
                        <Col>
                            <Statistic
                                title={<h3>Monto de crédito máximo</h3>}
                                value="$1 000 000"
                            />
                        </Col>
                    </Row>
                </Card>
            </Col>
            <Col span={8} lg={50} className="zindex">
                <Card title={<h2>Plazos</h2>}>
                    <Row style={{ paddingLeft: 20, paddingBottom: 20 }}>
                        <Col>
                            <Statistic
                                title={<h3>Plazo de crédito</h3>}
                                value="6 meses"
                            />
                        </Col>
                    </Row>
                </Card>
            </Col>
            <Col span={8} lg={50} className="zindex">
                <Card title={<h2>Porcentajes</h2>}>
                    <Row style={{ paddingLeft: 20, paddingBottom: 20 }}>
                        <Col>
                            <Statistic
                                title={<h3>Porcentaje por plazos</h3>}
                                value="75%"
                            />
                        </Col>
                    </Row>
                </Card>
            </Col>
            <Col span={24} lg={50} className="zindex">
                <Card>
                    <h2>Historial de Comisiones</h2>
                    <Table columns={columns} dataSource={data} />
                </Card>
            </Col>
            <Space />
            <Col span={6} lg={7} className="zindex">
                <Card title={<h2>Saldo</h2>}>
                    <Row style={{ paddingLeft: 1, paddingBottom: 1 }}>
                        <Col>
                            <Statistic
                                title={<h3>Saldo a favor</h3>}
                                value="$0"
                                valueStyle={{ color: 'green' }}
                            />
                        </Col>
                    </Row>
                </Card>
            </Col>
            <Col span={6} lg={7} className="zindex">
                <Card title={<h2>Deuda</h2>}>
                    <Row style={{ paddingLeft: 1, paddingBottom: 1 }}>
                        <Col>
                            <Statistic
                                title={<h3>Saldo deudor</h3>}
                                value="$2,450"
                                valueStyle={{ color: 'red' }}
                            />
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    )
}

export { SupplierComissions };