import React, { useEffect } from 'react';
import { Row, Col, Card, Statistic, message, Flex, Button, Modal, Input } from 'antd';
import Parse from 'parse';

const saveColors = (production, colors, setOpen) => {
    const ProductionOrder = Parse.Object.extend('ProductionOrder');
    const productionOrder = new ProductionOrder();
    productionOrder.set('objectId', production.objectId);
    productionOrder.set('colors', colors);
    productionOrder.save().then((result) => {
        console.log('Colors saved');
        message.success('Colores guardados correctamente');
        setOpen(false);
    }, (error) => {
        console.error('Error while creating ProductionOrder: ', error);
        message.error('Error al guardar los colores');
    });
}

const SetColorsModal = ({ open, setOpen, production, colors }) => {
    const [updatedColors, setUpdatedColors] = React.useState(colors);

    return (
        <div>
            <Modal
                title="Colores de croqueta"
                visible={open}
                onCancel={() => setOpen(false)}
                footer={[<Button onClick={() => setOpen(false)}>Cerrar</Button>, 
                <Button 
                    type="primary"
                    onClick={() => saveColors(production, updatedColors, setOpen)}
                    disabled={updatedColors?.reduce((acc, color) => acc + color.percent, 0) !== 100}
                >
                    Guardar
                </Button>]}
            >
                <Row gutter={24}>
                    {updatedColors?.map((color, index) => (
                        <Col span={6} key={index}>
                            <Card
                                bordered={false}
                                bodyStyle={{ padding: '10px' }}
                                style={{ margin: '10px', width: "100%" }}
                                className="criclebox cardbody mb-24 header-solid"
                                title={color.color}
                            >
                                <Input type="number" value={color.percent} onChange={(e) => {
                                    let updated = [...updatedColors];
                                    updated[index].percent = parseInt(e.target.value, 10);
                                    setUpdatedColors(updated);
                                }} />
                            </Card>
                        </Col>
                    ))}
                </Row>
                    <h2>Total: {updatedColors?.reduce((acc, color) => acc + color.percent, 0)}%</h2>                    
            </Modal>
        </div>
    )
}

export default SetColorsModal;