import React, { useState, useEffect } from 'react';
import { Card, Col, Row, Table, Typography, FloatButton } from 'antd';
import ReactApexChart from 'react-apexcharts';
import Parse from 'parse';
import NewPurchaseOrder from './components/NewPurchaseOrder';

const { Title } = Typography;

const PurchasesListt = () => {
  const [newPurchaseModal, setNewPurchaseModal] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [shouldRefresh, setShouldRefresh] = useState(false);

  useEffect(() => {
    const PurchaseOrder = Parse.Object.extend('PurchaseOrder');
    const query = new Parse.Query(PurchaseOrder);
    query.include(['supplier', 'products']);
    query.find().then((results) => {
      console.log('results', results);
      const data = results.map((result) => ({
        date: result.get('createdAt').toLocaleDateString(),
        folio: result.get('folio'),
        supplier: result.get('supplier').get('name'),
        products: result.get('products').map((product) => product.name).join(', '),
        total: result.get('total'),
        status: result.get('status'),
      }));
      setDataSource(data);
    });
  }, [shouldRefresh]);
  

  const refresh = () => {
    setShouldRefresh(!shouldRefresh);
  };

  // Obtener los totales como números para la gráfica
  const totals = dataSource.map(item => parseFloat(item.total.replace('$', '').replace(',', '')));

  // Calcular la suma total de los totales
  const totalSum = totals.reduce((acc, curr) => acc + curr, 0);

  const columns = [
    {
      title: 'Folio',
      dataIndex: 'folio',
      key: 'folio',
      render: (text) => <p>{text}</p>,
    },
    {
      title: 'Fecha',
      dataIndex: 'date',
      key: 'date',
      render: (text) => <h3>{text}</h3>,
    },
    
    {
      title: 'Proveedor',
      dataIndex: 'supplier',
      key: 'supplier',
      render: (text) => <p>{text}</p>,
    },
    {
      title: 'Productos',
      dataIndex: 'products',
      key: 'products',
      render: (text) => <p>{text}</p>,
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      render: (text) => <h3>${text}</h3>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => <h3>{text}</h3>,
    },
  ];



  const tableDataSource = [...dataSource]; // Crear un nuevo arreglo con la fila adicional

  const options = {
    chart: {
      width: '100%',
      height: 350,
      type: 'area',
      toolbar: {
        show: false,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      colors: ['#1890FF'],
      width: 3,
    },
    yaxis: {
      labels: {
        style: {
          fontSize: '14px',
          fontWeight: 600,
          colors: ['#8c8c8c'],
        },
        offsetY: -50,
      },
    },
    xaxis: {
      categories: dataSource.map(item => item.date),
      labels: {
        style: {
          fontSize: '14px',
          fontWeight: 600,
          colors: ['#8c8c8c'],
        },
      },
    },
    grid: {
      show: true,
      borderColor: 'rgba(0, 0, 0, .2)',
      strokeDashArray: 6,
      position: 'back',
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
      row: {
        colors: undefined,
        opacity: 0.5,
      },
      column: {
        colors: undefined,
        opacity: 0.5,
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return `$${val}`;
        },
      },
    },
  };

  return (
    <div className="layout-content">
      <NewPurchaseOrder open={newPurchaseModal} setOpen={setNewPurchaseModal} refresh={refresh} />
      <FloatButton onClick={() => setNewPurchaseModal(true)} />
      {/* <Row>
        <Card
          style={{ padding: 20, width: '100%' }}
          bordered={false}
          bodyStyle={{ padding: '0px' }}
          className="criclebox cardbody mb-24 header-solid"
          title={<h2>Gráfica de Compras</h2>}
        >
          <div className="ant-list-box table-responsive">
            <ReactApexChart
              className="full-width"
              options={options}
              series={[{ data: totals }]}
              type="area"
              height={350}
              width={'100%'}
            />
          </div>
        </Card>
      </Row> */}
      <Row gutter={[24, 0]}>
        <Col lg={24} className="zindex">
          <h2 className="p-15 h2">Compras</h2>

          <Card
            bordered={false}
            bodyStyle={{ padding: '0px' }}
            className="criclebox cardbody mb-24 header-solid"
          >
            <div className="ant-list-box table-responsive">
              <Table
                dataSource={tableDataSource}
                columns={columns}
                rowKey={(record, index) => index}
              />
            </div>
            <h3 style={{ padding: 20, textAlign: 'right' }}>{`Total: $${totalSum.toFixed(2)}`}</h3>
          </Card>
        </Col>
      </Row>
      
    </div>
  );
};

export default PurchasesListt;
