import React, { Component, useEffect, useState } from "react";
import { Card, Col, Row, Table, Typography, Tag, Avatar, FloatButton } from "antd";
import animalia from "../../images/animale.jpeg";
import NewSeller from "./components/NewSeller";
import { fetchSellers } from "./utils/query";

const { Title } = Typography;

const SellersList = () => {
  const [dataSource, setDataSource] = useState([]);
  const [newSellerModal, setNewSellerModal] = React.useState(false);
  const [shouldRefresh, setShouldRefresh] = React.useState(false);

  useEffect(() => {
    const getSellers = async () => {
      const sellers = await fetchSellers();
      console.log(sellers);
      setDataSource(sellers);
    };

    getSellers();
  }, []);

  const refresh = () => {
    setShouldRefresh(!shouldRefresh);
  };


  useEffect(() => {
    const getSellers = async () => {
      const sellers = await fetchSellers();
      console.log(sellers);
      setDataSource(sellers);
    };

    getSellers();
  }, []);
  
    const columns = [
      {
        dataIndex: "img",
        key: "img",
        render: (img) => <img src={img} alt="" className="avatar-sm mr-10" style={{width: "45px"}}/>,
      },
      {
        title: "Nombre",
        dataIndex: "fullName",
        key: "fullName",
        render: (text) => (
          <>
            <h3>{text}</h3>
          </>
        ),
      },
      { // TODO: Verify EMAIL data from Parse
        title: "Correo electrónico",
        dataIndex: "email",
        key: "email",
        render: (text) => (
          <>
            <p>{text}</p>
          </>
        ),
      },
      {
        title: "Número de teléfono",
        dataIndex: "phone",
        key: "phone",
        render: (text) => (
          <>
            <p>{text}</p>
          </>
        ),
      },
      {
        title: "Volumen de ventas",
        dataIndex: "sales",
        key: "sales",
        render: (text) => (
          <>
            <p>{text}</p>
          </>
        ),
      },
      {
        title: "Balance financiero",
        dataIndex: "balance",
        key: "balance",
        render: (text) => (
          <>
            <h3>{text}</h3>
          </>
        ),
      },
    ];

    return (
      <div className="layout-content">
        <FloatButton onClick={() => setNewSellerModal(true)}/>
        <NewSeller open={newSellerModal} setOpen={setNewSellerModal} refresh={refresh} />
        <Row gutter={[24, 0]}>
          <Col lg={24} className="zindex">
            <h2 className="p-15 h2">Vendedores</h2>

            <Card
              bordered={false}
              bodyStyle={{ padding: "0px" }}
              className="criclebox cardbody mb-24 header-solid"
              title={<h3>Vendedores</h3>}
            >
              <div className="ant-list-box table-responsive">
                <Table
                  dataSource={dataSource}
                  columns={columns}
                  rowKey={(record, index) => index}
                  pagination={true}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }


  export default SellersList
