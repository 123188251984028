/*!
=========================================================
* Muse Ant Design Dashboard PRO - v1.1.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard-pro
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import "antd/dist/antd.min.css";
// import "antd-button-color/dist/css/style.css";
import "./App.css";
import "./responsive.css";
import { createBrowserRouter, Route, RouterProvider } from "react-router-dom";
import { useLoadScript } from "@react-google-maps/api";

import SignUp from "./component/pages/SignUp";
import Main from "./component/layout/main";
import Pricing from "./component/pages/Pricing";
import Cover from "./component/authentication/signup/Cover";
import Illustration from "./component/authentication/signup/Illustration";
import PageFound from "./component/pages/PageFound";
import Parse from "parse";
import { UserContext, UserProvider } from "./context/UserContext";
import SignIn from "./component/pages/SignIn";
import AcceptQuote from "./component/pages/sales/components/AcceptQuote";

const libraries = ["places"];

Parse.initialize("0fd71bab-a87f-4534-af06-a59d173902b1", "77279e63-3d12-4b83-9d64-4ee439e118e8");
Parse.serverURL = "https://app.dasnerpetcare.com/parse";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
  },
  {
    path: "/signup",
    element: <SignUp />,
  },
  {
    path: "/signin",
    element: <SignIn />,
  },
  {
    path: "/sales/AcceptQuote/:uuid",
    exact: true,
    element: <AcceptQuote />,
  },
  {
    path:"/*",
    element: <Main />
  }
  
]);


function App() {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyCVDVOKrLpK3K7Lg-EfIYDPNm3LloJ1FuU", // Add your API key here
    libraries,
  });

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps</div>;

  return (
    <div className="App">
      <UserProvider>
        <RouterProvider router={router} />
      </UserProvider>
    </div>
  );
}

export default App;
