/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { Layout, Row, Col } from "antd";
import { HeartFilled } from "@ant-design/icons";
const { Footer } = Layout;
export default class FooterNEw extends Component {
  render() {
    return (
      <>
        <Footer style={{ background: "#fafafa" }}>
          <Row className="just">
            <Col xs={24} md={12} lg={12}>
              <div className="copyright">
                2024
               
                <a href="#" className="font-weight-bold" target="_blank">
                  devTek ®
                </a>
              </div>
            </Col>
            
          </Row>
        </Footer>
      </>
    );
  }
}
