import React from "react";
import { useEffect, useState } from "react";
import { Row, Col, Card, Table, Statistic, FloatButton } from "antd";
import { fetchVisits } from "../utils/query";
import NewVisit from "../components/NewVisit";

const columns = [
    {
      title: 'No. de Orden',
      dataIndex: 'order',
      key: 'order',
    },
    {
      title: 'Fecha',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Cliente',
      dataIndex: 'client',
      key: 'client',
    },
    {
      title: 'Comentarios',
      dataIndex: 'notes',
      key: 'notes',
    }
];

const data = [
    {
      key: '1',
      date: '25/05/24',
      order: 'D0001',
      client: 'Cliente 1',
    },
    {
      key: '2',
      date: '06/06/24',
      order: 'D0003',
      client: 'Cliente 2',
    },
    {
      key: '3',
      date: '20/06/24',
      order: 'D0006',
      client: 'Cliente 3',
    },
];

const SellerVisits = () => {
  const [data, setDataSource] = useState([]);
  const [newVisitModal, setNewVisitModal] = React.useState(false);
  const [shouldRefresh, setShouldRefresh] = React.useState(false);

  useEffect(() => {
    const getVisits = async () => {
      const visits = await fetchVisits();
      console.log(visits);
      setDataSource(visits);
    };

    getVisits();
  }, []);

  const refresh = () => {
    setShouldRefresh(!shouldRefresh);
  };


  useEffect(() => {
    const getVisits = async () => {
      const visits = await fetchVisits();
      console.log(visits);
      setDataSource(visits);
    };

    getVisits();
  }, []);
    return (
        <Row gutter={[24, 0]}>
          <FloatButton onClick={() => setNewVisitModal(true)}/>
          <NewVisit open={newVisitModal} setOpen={setNewVisitModal} refresh={refresh} />
            <Col span={24} lg={50} className="zindex">      
                <Card title={<h2>Número de Visitas</h2>}>
                <Row style={{ paddingLeft: 20, paddingBottom: 20 }}>
                    <Col>
                        <Statistic
                            value={data.length}
                            formatter={(value) => <span>{value} Visitas</span>}
                        />
                    </Col>
                </Row>
                </Card> 
            </Col>
            <Col span={24} lg={50} className="zindex">      
                <Card> 
                <h2>Historial de Visitas</h2>
                    <Table columns={columns} dataSource={data} />
                </Card>
            </Col>
        </Row>
    )
}

export {SellerVisits};