import React, { useState } from 'react';
import { Modal, Button, Flex } from 'antd';

const ImageGallery = ({ open, setOpen, images, index }) => {
    const [currentImage, setCurrentImage] = useState(index);

    const next = () => {
        setCurrentImage((currentImage + 1) % images.length);
    }

    const prev = () => {
        setCurrentImage((currentImage - 1 + images.length) % images.length);
    }

    // Capture keyboard events for left and right arrow keys
    document.onkeydown = (e) => {
        if (e.key === "ArrowRight") {
            next();
        } else if (e.key === "ArrowLeft") {
            prev();
        }
    }
    

    return (
        <Modal
            title="Galería de imágenes"
            visible={open}
            onCancel={() => setOpen(false)}
            footer={null}
            width={"80%"}
            style={{ top: 20, justifyContent: 'center' }}
            
        >
           <Flex justifyContent="center">
                <img src={images[currentImage]} style={{ height: "80%", objectFit: 'cover' }} />
            </Flex>
           
            <Flex justifyContent="space-between" style={{ marginTop: 10 }}>
                {images && images.length > 0 && images.map((image, index) =>
                    <img src={image} style={{ width: 100, height: 150, padding: 10, objectFit: 'cover' }} onClick={() => setCurrentImage(index)} />
                )}
            </Flex>
            <p style={{ textAlign: 'center' }}>{currentImage + 1} de {images.length}</p>
        </Modal>
    );
}

export default ImageGallery;

