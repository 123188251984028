import React from "react"
import { Row, Col, Card, Table, Space, Statistic, Avatar, Modal } from "antd"
import Title from "antd/lib/skeleton/Title";
import { FontSizeOutlined } from "@ant-design/icons";
import ImageGallery from "./ImageGallery";
import moment from "moment";

const columns = [
    {
        title: "Producto",
        dataIndex: "name",
        key: "name",
        render: (text) => (
            <>
                <p>{text}</p>
            </>
        ),
    },
    {
        title: "Cantidad",
        dataIndex: "quantity",
        key: "quantity",
        render: (text) => (
            <>
                <p>{text}</p>
            </>
        ),
    },
    {
        title: "Presentación",
        dataIndex: "presentation",
        key: "presentation",
        render: (text) => (
            <>
                <p>{text}</p>
            </>
        ),
    },
    {
        title: "Recibido",
        dataIndex: "received",
        key: "received",
        render: (text) => (
            <>
                <p>{text}</p>
            </>
        ),
    },
    {
        title: "Faltante",
        dataIndex: "remaining",
        key: "remaining",
        render: (text, product) => (
            <>
                <p>{text||product.quantity-product.received}</p>
            </>
        ),
    },

];

const WarehouseEntryDetail = ({ open, setOpen, entryInfo }) => {
    const [openGallery, setOpenGallery] = React.useState(false);
    const [imageIndex, setImageIndex] = React.useState(0);
    return (
        <Modal title={<h1 className="ant-card-head-title" style={{ fontSize: "30px", fontWeight: "bold" }}>Detalle de Recepción</h1>} open={open} onCancel={() => setOpen(false)} footer={null} width={"80%"}>
        <ImageGallery open={openGallery} setOpen={setOpenGallery} images={entryInfo?.images} index={imageIndex} />
        <Row gutter={[24, 0]} style={{ width: "100%" }}>
            <Col span={18}>
                <Row>
                    <p style={{ fontWeight: "bold" }}>Número de Entrada: </p>
                    <p>{entryInfo?.folio}</p>
                </Row>
                <Row>
                    <p style={{ fontWeight: "bold" }}>Fecha:</p>
                    <p>12/07/2024</p>
                </Row>
            </Col>
            <Col span={6}>
                <Col span={3} md={12}>
                    <span style={{ verticalAlign: "left" }}>Recibido por</span>
                </Col>
                <Col span={3} md={12}>
                    <Avatar size="small" className="mr-10">
                        {entryInfo?.user?.name?.charAt(0)}
                    </Avatar>
                    <span style={{ verticalAlign: "bottom" }}>{entryInfo?.user?.name}</span>
                </Col>
            </Col>
            <Row>
                <Col span={24} lg={50} className="zindex">
                    <Card>
                        <h2>Orden de compra</h2>
                        <Card>
                            <h6>{entryInfo?.purchaseOrder?.folio}</h6>
                            <p> Fecha: {moment(entryInfo?.purchaseOrder?.createdAt).format("DD/MM/YYYY")}</p>
                            <p> Provedor: {entryInfo?.purchaseOrder?.supplier?.name}</p>
                        </Card>
                    </Card>
                </Col>
                <Col span={24} lg={50} className="zindex">
                    <Card title={<h2>Productos</h2>}>
                        <div className="ant-list-box table-responsive">
                            <Table
                                dataSource={entryInfo?.products}
                                columns={columns}
                                rowKey={(record, index) => index}
                                pagination={true}
                            />
                        </div>
                    </Card>
                </Col>
                <Col span={8} lg={50} className="zindex">
                    <Card style={{ height: 350, overflowX: "scroll", overflowY: "hidden" }}>
                        <h2>Imágenes</h2>
                        {entryInfo?.images?.length ? entryInfo?.images?.map((image, index) => (
                            <img key={index} src={image} style={{ height: 200, padding: 10 }} onClick={() => {
                                setImageIndex(index);
                                setOpenGallery(true);
                            }
                            } />
                        )) : <p>No hay imágenes</p>}
                    </Card>
                </Col>
                <Col span={8} lg={50} className="zindex">
                    <Card>
                        <h2>Documentos</h2>
                        {entryInfo?.documents?.length ? entryInfo?.documents?.map((document, index) => (
                            <p key={index}>{document}</p>
                        )) : <p>No hay documentos</p>}
                    </Card>
                </Col>
               
            </Row>
        </Row>
        </ Modal>
    )
}

export default WarehouseEntryDetail; 