import Parse from "parse"

const aclFields = {
    'Admin': [
    {role: 'Admin', read: true, write: true}, 
],
    'Almacén': [
    {role: 'Admin', read: true, write: true},
],
    'Cliente': [
    {role: 'Admin', read: true, write: true}, 
    {role: 'Cobranza', read: true, write: true},
    {role: 'Ventas', read: true, write: true},
],
    'Cobranza': [
    {role: 'Admin', read: true, write: true},
],
    'Compras': [
    {role: 'Admin', read: true, write: true},
],
    'Pagos': [
    {role: 'Admin', read: true, write: true},
],
   'Producción': [
    {role: 'Admin', read: true, write: true},
],
    'Proveedor': [
    {role: 'Admin', read: true, write: true},
    {role: 'Compras', read: true, write: true},
    {role: 'Pagos', read: true, write: true},
],
    'Ventas': [
    {role: 'Admin', read: true, write: true},
    {role: 'Cliente', read: true, write: false},
    {role: 'Cobranza', read: true, write: false},
],
}
   
const roleNames = [
    'Admin', 'Almacén', 'Cliente', 'Cobranza', 'Compras', 'Pagos', 'Producción', 'Proveedor', 'Ventas'
]

const setACL = (userRole) => {
    const acl = new Parse.ACL();
    acl.setPublicReadAccess(false);
    acl.setPublicWriteAccess(false);
    aclFields[userRole].forEach((field) => {
        acl.setRoleReadAccess(field.role, field.read);
        acl.setRoleWriteAccess(field.role, field.write);
    });
    return acl;
}

export {
    setACL,
    roleNames
}