import React, { useEffect } from "react";
import { Card, Col, Row, Table, Typography, Statistic, Button } from "antd";
import ReactApexChart from "react-apexcharts";
import NewInventoryExit from "./components/NewInventoryExit";
import Parse from "parse";
import moment from "moment";

const { Title } = Typography;

const fetchSaleOrders = async () => {
  const SaleOrder = Parse.Object.extend('Sales');
  const query = new Parse.Query(SaleOrder);
  query.include('products');
  query.include('client');
  query.include("products.presentation");
  query.equalTo('status', 'Venta');
  const results = await query.find();
  return results?.map((result) => result.toJSON());
}
  

const WarehouseOutputList = () => {
  const [saleOrders, setSaleOrders] = React.useState([]);
  const [newOutput, setNewOutput] = React.useState(false);
  const [output, setOutput] = React.useState(null);

  useEffect(() => {
    fetchSaleOrders().then((results) => {
      setSaleOrders(results);
    });
  }, []);


  const dataSource = [
    {
      date: '10/06/2024',
      SalesOrder: 'ODC012',
      Products: [{
        name: 'croqueta gato 10kg',
        quantity: 100,
        unitCost: "3",
        baseUnit: 'Piezas',
        total: '$300.00'
      }],
      Client: "Comercial Cabrera",
      DeliveryOptions: 'Flete',
    },
    {
      date: '10/06/2024',
      SalesOrder: 'ODC011',
      Products: [{
        name: 'croqueta perro 15kg',
        quantity: 100,
        unitCost: "7",
        baseUnit: 'Piezas',
        total: '$700.00'
      }],
      Client: "Previtep",
      DeliveryOptions: 'Tienda',
    },
    {
      date: '07/06/2024',
      SalesOrder: 'ODC010',
      Products: [{
        name: 'croqueta gato 20kg',
        quantity: 200,
        unitCost: "10",
        baseUnit: 'Piezas',
        total: '$2000.00'
      }],
      Client: "Super Victoria",
      DeliveryOptions: 'Pie de fabrica',
    },
    {
      date: '05/06/2024',
      SalesOrder: 'ODC009',
      Products: [{
        name: 'croqueta perro 25kg',
        quantity: 100,
        unitCost: "15",
        baseUnit: 'Piezas',
        total: '$1500.00'
      }],
      Client: "Veterinaria Docmaine",
      DeliveryOptions: 'Flete',
    },
    {
      date: '01/06/2024',
      SalesOrder: 'ODC008',
      Products: [{
        name: 'croqueta senior 25kg',
        quantity: 100,
        unitCost: "15",
        baseUnit: 'Piezas',
        total: '$1500.00'
      }],
      Client: "Vimitep",
      DeliveryOptions: "Tienda",
    },
    {
      date: '05/06/2024',
      SalesOrder: 'ODC017',
      Products: [{
        name: 'croqueta perro 25kg',
        quantity: 100,
        unitCost: "15",
        baseUnit: 'Piezas',
        total: '$1500.00'
      }],
      Client: "Super Victoria",
      DeliveryOptions: 'Pie de fabrica',
    },
  ];

  // Ordenar dataSource por la fecha (de más viejo a más nuevo)
  dataSource.sort((a, b) => new Date(a.date) - new Date(b.date));

  const columns = [
    {
      title: "Fecha",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => <h3>{moment(text).format("DD/MM/YYYY")}</h3>,
    },
    {
      title: "Orden de venta",
      dataIndex: "saleNumber",
      key: "saleNumber",
      render: (text, output) => <Button type='link' onClick={() => {
        setNewOutput(true)
        setOutput(output)}
      } >
        <p>{text}</p>
      </Button>,
    },
    {
      title: "Productos",
      dataIndex: "products",
      key: "products",
      render: (products) => (
          products?.map((product) => (
              <p>{product.quantity?.toLocaleString()} {product.name}</p>
          ))
      ),
    },
    {
      title: "Cliente",
      dataIndex: "client",
      key: "client",
      render: (text) => <p>{text.name}</p>,
    },
    {
      title: "Lugar de entrega",
      dataIndex: "deliveryPlace",
      key: "deliveryPlace",
      render: (text) => <h3>{text}</h3>,
    },
  ];

  // Agrupar y sumar las salidas por fecha
  const groupedData = dataSource.reduce((acc, curr) => {
    const date = curr.date;
    if (acc[date]) {
      acc[date]++;
    } else {
      acc[date] = 1;
    }
    return acc;
  }, {});

  // Preparar datos para la gráfica de líneas de salidas por día
  const chartData = {
    series: [
      {
        name: "Salidas por día",
        data: Object.keys(groupedData).map((date) => ({
          x: new Date(date).getTime(),
          y: groupedData[date],
        })),
      },
    ],
    options: {
      chart: {
        type: 'line',
        height: 350,
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        type: 'datetime',
      },
      yaxis: {
        title: {
          text: 'Número de Salidas',
        },
      },
      tooltip: {
        x: {
          format: 'dd/MM/yyyy',
        },
      },
    },
  };

  // Calcular el total de salidas
  const totalOutputs = dataSource.length;

  return (
    <div className="layout-content">
      <NewInventoryExit open={newOutput} setOpen={setNewOutput} saleInfo={output} />
      <Row gutter={[24, 0]}>
        <Col lg={24} className="zindex">
          <Card
            bordered={false}
            bodyStyle={{ padding: "0px" }}
            className="criclebox cardbody mb-24 header-solid"
            title={<h3>Salidas de Almacén</h3>}
          >
            <div className="ant-list-box table-responsive">
              <Table
                dataSource={saleOrders}
                columns={columns}
                rowKey={(record, index) => index}
                pagination={true}
              />
            </div>
          </Card>
        </Col>
      </Row>
      <Row gutter={[24, 0]}>
        <Col lg={24} className="zindex">
          <Card
            bordered={false}
            bodyStyle={{ padding: "20px" }}
            className="criclebox cardbody mb-24 header-solid"
            title={<h3>Estadísticas de Salidas</h3>}
          >
            <Statistic title="Total de Salidas" value={totalOutputs} />
          </Card>
        </Col>
      </Row>
      <Row gutter={[24, 0]}>
        <Col lg={24} className="zindex">
          <Card
            bordered={false}
            bodyStyle={{ padding: "20px" }}
            className="criclebox cardbody mb-24 header-solid"
            title={<h3>Salidas por día</h3>}
          >
            <ReactApexChart
              options={chartData.options}
              series={chartData.series}
              type="line"
              height={350}
            />
          </Card>
        </Col>
      </Row>


    </div>
  );
};

export default WarehouseOutputList;
