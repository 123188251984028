const findErrorMessage = (error) => {
        switch (error.code) {
            case 100: // ConnectionFailed
                return "No se pudo conectar al servidor. Por favor, verifica tu conexión a internet.";
            case 101: // ObjectNotFound
                return "El objeto solicitado no fue encontrado.";
            case 102: // InvalidQuery
                return "La consulta es inválida. Por favor, verifica la sintaxis de la consulta.";
            case 103: // InvalidClassName
                return "El nombre de la clase proporcionado es inválido.";
            case 104: // MissingObjectId
                return "El ID del objeto es obligatorio pero falta.";
            case 105: // InvalidKeyName
                return "El nombre de una de las claves es inválido.";
            case 106: // InvalidPointer
                return "El puntero es inválido.";
            case 107: // InvalidJSON
                return "El formato JSON es inválido.";
            case 108: // CommandUnavailable
                return "Esta funcionalidad no está disponible.";
            case 109: // NotInitialized
                return "El SDK de Parse no se ha inicializado correctamente.";
            case 111: // IncorrectType
                return "El tipo de dato es incorrecto para uno de los campos.";
            case 112: // InvalidChannelName
                return "El nombre del canal es inválido.";
            case 114: // PushMisconfigured
                return "La configuración para notificaciones push es incorrecta.";
            case 116: // ObjectTooLarge
                return "El objeto es demasiado grande para ser guardado.";
            case 119: // OperationForbidden
                return "Esta operación está prohibida.";
            case 120: // CacheMiss
                return "El objeto no fue encontrado en la caché.";
            case 121: // InvalidNestedKey
                return "Una de las claves anidadas es inválida.";
            case 122: // InvalidFileName
                return "El nombre del archivo es inválido.";
            case 123: // InvalidACL
                return "La ACL es inválida.";
            case 124: // Timeout
                return "La solicitud excedió el tiempo límite.";
            case 125: // InvalidEmailAddress
                return "La dirección de correo electrónico es inválida.";
            case 137: // DuplicateValue
                return "Existe un valor duplicado para un campo único.";
            case 139: // InvalidRoleName
                return "El nombre del rol es inválido.";
            case 200: // UsernameMissing
                return "El nombre de usuario es obligatorio.";
            case 201: // PasswordMissing
                return "La contraseña es obligatoria.";
            case 202: // UsernameTaken
                return "El nombre de usuario ya está en uso.";
            case 203: // EmailTaken
                return "El correo electrónico ya está en uso.";
            case 204: // EmailMissing
                return "La dirección de correo electrónico es obligatoria.";
            case 205: // EmailNotFound
                return "La dirección de correo electrónico no está registrada.";
            case 206: // SessionMissing
                return "La sesión del usuario falta o es inválida.";
            case 207: // MustCreateUserThroughSignup
                return "Debes registrarte para crear un nuevo usuario.";
            case 208: // AccountAlreadyLinked
                return "Esta cuenta ya está vinculada.";
            case 209: // InvalidSessionToken
                return "El token de sesión es inválido.";
            case 250: // LinkedIdMissing
                return "El ID vinculado es obligatorio.";
            case 251: // InvalidLinkedSession
                return "La sesión vinculada es inválida.";
            case 252: // UnsupportedService
                return "El servicio no es compatible.";
            default:
                return "Ocurrió un error desconocido. Por favor, inténtalo de nuevo.";
        }
    }

export {
    findErrorMessage
}