import React, { useState } from 'react';
import { Modal, Button, Form, Input, Select, message } from 'antd';
import Parse from 'parse';

const { Option } = Select;

const saveHopper = (production, option, setOpen) => {
    const ProductionOrder = Parse.Object.extend('ProductionOrder');
    const productionOrder = new ProductionOrder();
    productionOrder.set('objectId', production.objectId);
    productionOrder.set('hopper', option);
    productionOrder.save().then((result) => {
        message.success('Tolva seleccionada correctamente');
        setOpen(false);
    }, (error) => {
        console.error('Error while creating ProductionOrder: ', error);
        message.error('Error al seleccionar la tolva');
    });
}


const HopperModal = ({ open, setOpen, production }) => {
    const [hopper, setHopper] = useState(null);

    return (
        <Modal
            title="Seleccionar tolva"
            visible={open}
            onOk={() => setOpen(false)}
            onCancel={() => setOpen(false)}
            footer={[
                <Button key="back" onClick={() => saveHopper(production, hopper, setOpen)}>
                    Confirmar
                </Button>,
            ]}
        >
           <Select
                style={{ width: 200 }}
                placeholder="Seleccionar tolva"
                optionFilterProp="children"
                onChange={(value) => setHopper(value)}
                value={hopper}
            >
                <Option value="1">Tolva 1</Option>
                <Option value="2">Tolva 2</Option>
                <Option value="3">Tolva 3</Option>
            </Select>
        </Modal>
    );
}   

export default HopperModal;