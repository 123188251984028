/* eslint-disable react/jsx-pascal-case */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Layout,
  Button,
  Row,
  Col,
  Typography,
  Form,
  Input,
  Switch,
  message
} from "antd";
import signinbg from "../images/img-signin.png";
import Header_Pro from "./Header_Pro";
import Footer_Pro from "./Footer_Pro";
import Parse from "parse";
import { UserContext } from "../../context/UserContext";
import { useNavigate, useLocation } from "react-router-dom";

function onChange(checked) {
  console.log(`switch to ${checked}`);
}
const { Title } = Typography;
const { Header, Footer, Content } = Layout;


const SignIn = () => {
  const { user, setCurrentUser, login } = React.useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();

    const onFinish = async (values) => {
      console.log("Success:", values);
      try {
        console.log('User context:', UserContext);
        console.log(login)
        const user = await login(values.username, values.password);
        console.log("Logged in user:", user);
        setCurrentUser(user);
        message.success("Inicio de sesión exitoso");
        setTimeout(() => {
        if (location.state && location.state.from) {
            navigate(location.state.from);
          } else {
            navigate("/dashboard");
          }
        }, 1000);
      }
      catch (error) {
        console.error("Error while logging in:", error);
        message.error("Error al iniciar sesión");
      }
    }


    const onFinishFailed = (errorInfo) => {
      console.log("Failed:", errorInfo);
    };

    return (
      <>
        <Layout className="layout-default layout-signin">
          {/* <Header>
            <Header_Pro />
          </Header> */}
          <Content className="signin">
            <Row gutter={[24, 0]} justify="space-around">
              <Col
                xs={{ span: 24, offset: 0 }}
                lg={{ span: 6, offset: 2 }}
                md={{ span: 12 }}
              >
                <Title className="mb-15">Iniciar sesión</Title>
                <Title className="font-regular text-muted" level={5}>
                  Ingresa tu usuario y contraseña para iniciar sesión
                </Title>
                <Form
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  layout="vertical"
                  className="row-col"
                >
                  <Form.Item
                    className="username"
                    label="Usuario"
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingrese su usuario",
                      },
                    ]}
                  >
                    <Input placeholder="Usuario" />
                  </Form.Item>

                  <Form.Item
                    className="username"
                    label="Contraseña"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingrese su contraseña",
                      },
                    ]}
                  >
                    <Input.Password placeholder="Contraseña" />
                  </Form.Item>

                  <Form.Item
                    name="remember"
                    className="aligin-center"
                    valuePropName="checked"
                  >
                    <Switch defaultChecked onChange={onChange} />
                    Recordarme
                  </Form.Item>

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ width: "100%" }}
                    >
                      Iniciar sesión
                    </Button>
                  </Form.Item>
                  {/* <p className="font-semibold text-muted">
                    Don't have an account?{" "}
                    <Link to="/sign-up" className="text-dark font-bold">
                      Sign Up
                    </Link>
                  </p> */}
                </Form>
              </Col>
              <Col
                className="sign-img"
                style={{ padding: 12 }}
                xs={{ span: 24 }}
                lg={{ span: 12 }}
                md={{ span: 12 }}
              >
                <img src={signinbg} alt="" />
              </Col>
            </Row>
          </Content>
          <Footer>
            <Footer_Pro />
          </Footer>
        </Layout>
      </>
    );
  
}

export default SignIn;