import React, { useEffect, useState } from "react";
import { Table, Typography, Button, Space, Input, message, Select } from "antd";
import DebounceSelect from "../../../form/DebounceSelect";
import { fetchProducts } from "../utils/query";
import { PlusOutlined } from "@ant-design/icons";
import moment from "moment";

const { Title } = Typography;

const ProductList = ({ form, setRenderId }) => {
    const [products, setProducts] = useState([]);

    useEffect(() => {
        form.getFieldValue("products") && setProducts(form.getFieldValue("products"));
    }, [form]);
    

    const onProductSelected = (objectId, product) => {
        console.log("product", product);
        const products = form.getFieldValue("products");
        if (products.find((p) => p.objectId === product.objectId)) {
            return;
        }
        products.push({
            ...product,
            quantity: 1,
            objectId: product.objectId,
        });
        console.log("products", products);
        form.setFieldValue("products", products);
        setProducts(products);
        setRenderId((prev) => prev + 1);
    };

    return (
        <Space direction="vertical" style={{ width: "100%" }}>
            <DebounceSelect
                fetchOptions={fetchProducts}
                onChange={onProductSelected}
                placeholder="Buscar producto"
                style={{ width: "100%" }}
                allowClear
                showSearch
            />
        <Table
            dataSource={products}
            rowKey="objectId"
            pagination={false}
            scroll={{ x: true }}
        >
            <Table.Column
                title="Producto"
                dataIndex="name"
                key="name"
            />
            <Table.Column
                title="Presentación"
                dataIndex="presentation"
                key="presentation"
                render={(text, product, key) => 
                    <Select
                        style={{ width: 200 }}
                        onChange={(value, object) => {
                            console.log("key", key);
                            console.log("value", value);
                            console.log("object", object);
                            let products = form.getFieldValue("products");
                            console.log("products", products);
                            let product = products[key];
                            console.log("product", product);    
                            product.presentation = product.presentations?.find((p) => p.objectId === value);
                            console.log("product", product);
                            product.price = product.presentation.minPrice;
                            product.minPrice = product.presentation.minPrice;
                            product.lastClientPrice = product.presentation.lastClientPrice;
                            product.lastPurchase = product.presentation.lastPurchase;
                            product.total = product.quantity * product.price;
                            console.log("product", product);
                            console.log("products", products);
                            products = products.map((p) => (p.objectId === object.objectId ? product : p));
                            form.setFieldValue("products", products);
                            setRenderId((prev) => prev + 1);
                        }}
                        options={product.presentations.map((p) => ({
                            label: p.name,
                            value: p.objectId,
                            ...p,
                        }))}
                    />
                }      
            />
            <Table.Column
                title="Precio mínimo"
                dataIndex="minPrice"
                key="minPrice"
                render={(text) => <p>${text?.toLocaleString()}</p>}
            />
            <Table.Column
                title="Última compra"
                dataIndex="lastPurchase"
                key="lastPurchase"
                render={(text, product) => <p>{text ? moment(text).format("DD/MM/YYYY") : "Sin compras previas"}</p>}
                colSpan={(text) => text ? 1 : 2}
            />
            <Table.Column
                title="Último precio"
                dataIndex="lastClientPrice"
                key="lastClientPrice"
                render={(text) => <p>{text ? '$' + text.toLocaleString() : ""}</p>}
            />
            <Table.Column
                title="Precio"
                dataIndex="price"
                key="price"
                render={(text, record) => (
                    <Input
                        type="number"
                        value={text}
                        onClick={(e) => e.target.select()}
                        style={{ width: 80 }}
                        onChange={(e) => {
                            const value = e.target.value;
                            const products = form.getFieldValue("products");
                            const product = products.find((p) => p.objectId === record.objectId);
                           
                            product.price = value;
                            product.total = product.quantity * value;
                            form.setFieldValue("products", products);
                            setRenderId((prev) => prev + 1);
                        }}
                        onBlur={(e) => {
                            const value = e.target.value;
                            if (value < record.minPrice) {
                                message.error("El precio no puede ser menor al mínimo");
                                e.target.value = record.minPrice;
                                const products = form.getFieldValue("products");
                                const product = products.find((p) => p.objectId === record.objectId);
                                product.price = record.minPrice;
                                product.total = product.quantity * record.minPrice;
                                form.setFieldValue("products", products);
                                setProducts(products);
                                setRenderId((prev) => prev + 1);
                            }
                        }}
                    />
                )}
            />
            <Table.Column
                title="Cantidad"
                dataIndex="quantity"
                key="quantity"
                render={(text, record) => (
                    <Input
                        type="number"
                        value={text}
                        onClick={(e) => e.target.select()}
                        style={{ width: 80, color: record.inventory < text ? "orange" : "" }}
                        onChange={(e) => {
                            const value = e.target.value;
                            const products = form.getFieldValue("products");
                            const product = products.find((p) => p.objectId === record.objectId);
                            product.quantity = value;
                            product.total = product.price * value;
                            form.setFieldValue("products", products);
                            setRenderId((prev) => prev + 1);
                        }}
                    />
                )}
            />
            <Table.Column
                title="Total"
                dataIndex="total"
                key="total"
                render={(text) => <p>${text?.toLocaleString()}</p>}
            />
            <Table.Column
                title="Acción"
                key="action"
                render={(text, record) => (
                    <Button
                        type="danger"
                        onClick={() => {
                            const products = form.getFieldValue("products");
                            form.setFieldValue(
                                "products",
                                products.filter((p) => p.objectId !== record.objectId)
                            );
                            setProducts(products.filter((p) => p.objectId !== record.objectId));
                            setRenderId((prev) => prev + 1);
                        }}
                    >
                        Eliminar
                    </Button>
                )}
            />
        </Table>
        </Space>
    );
}

export default ProductList;
