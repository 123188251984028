import React, { useEffect, useState } from "react";
import { Form, Typography, Button, Space, Input, Table, Modal, Flex } from "antd";
import DebounceSelect from "../../../form/DebounceSelect";
import Parse from "parse";
import NewRecipe from "./NewRecipe";

const { Title } = Typography;


const RecipeList = ({ parentForm, open, setOpen, recipe, setRecipe }) => {
    const [form] = Form.useForm();
    const [newRecipe, setNewRecipe] = useState(false);
    const [recipes, setRecipes] = useState([]);

    const fetchRecipes = async (search) => {
        const Recipe = Parse.Object.extend("Recipe");
        const query = new Parse.Query(Recipe);
        query.contains("name", search);
        const results = await query.find();
        console.log("results", results);
        const recipes = results.map((result) => ({
            value: result.id,
            label: result.get("name"),
            ...result.toJSON()
        }));
        setRecipes(recipes);
        return recipes;
    }

    useEffect(() => {
        if (recipe) {
            form.setFieldsValue({
                name: recipe.name,
                description: recipe.description,
                ingredients: recipe.ingredients,
            });
        }
    }, [recipe]);

    return (
        <Flex vertical gap="10px">
            <NewRecipe open={newRecipe} setOpen={setNewRecipe} addRecipe={(recipe) => {
                console.log("Received r", recipe);
                setRecipe(recipe);
                setNewRecipe(false);
            }} />
            <Flex gap="10px">
            
                <DebounceSelect
                fetchOptions={fetchRecipes}
                onChange={(recipe) => {
                    const fullRecipe = recipes.find((r) => r.value === recipe.value);
                    setRecipe(fullRecipe);
                }}
                placeholder="Buscar receta"
                style={{ width: "100%" }}
                allowClear
                showSearch
            />

            <Button type="primary" onClick={() => setNewRecipe(true)}>+ Nueva receta</Button>
        </Flex>
        <Table dataSource={recipe?.ingredients} rowKey="objectId" pagination={false} scroll={{ x: true }}>
            <Table.Column title="Insumo" dataIndex="name" key="name" />
            <Table.Column title="Cantidad" dataIndex="quantity" key="quantity" render = {(text, record) => (
                <Input type="number" value={record.quantity} onChange={(e) => {
                    const newQuantity = e.target.value;
                    const newIngredients = recipe.ingredients.map((ingredient) => {
                        if (ingredient.objectId === record.objectId) {
                            return { ...ingredient, quantity: newQuantity };
                        }
                        return ingredient;
                    });
                    let baseCost = 0;
                    newIngredients.forEach((ingredient) => {
                        baseCost += ingredient.baseCost * ingredient.quantity;
                    });
                    let totalCost = baseCost * recipe.baseQuantity;
                    setRecipe({ ...recipe, ingredients: newIngredients, baseCost, totalCost });
                }
                } />
            )} />
            <Table.Column title="Costo unitario" dataIndex="baseCost" key="baseCost" />
            <Table.Column title="Costo total" dataIndex="totalCost" key="totalCost" render={(text, record) => (
                <p>${(record.baseCost * record.quantity).toFixed(2)}</p>
            )} />
        </Table>

        <h3>Costo por {recipe?.baseUnit}: ${recipe?.baseCost.toFixed(2)}</h3>
        </Flex>

    );
}

export default RecipeList;