import Parse from "parse";

const fetchSellers = async () => {
    return new Promise((resolve, reject) => {
        const Role = new Parse.Role();
        const roleQuery = new Parse.Query(Role);
        roleQuery.equalTo("name", "Ventas");
        roleQuery.first()
            .then((role) => {
                const userRelation = role.relation("users");
                const userQuery = userRelation.query();
                return userQuery.find();
            })
            .then((results) => {
                resolve(results.map((result) => {
                    return {
                        ...result.toJSON(),
                    };
                }));
            })
            .catch((error) => {
                console.error("Error al obtener los vendedores:", error); // Verifica si hay algún error
                reject(error);
            });
        
    } );
}


export { fetchSellers };