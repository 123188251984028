import React from "react"
import { Row, Col, Card, Table, Space, Statistic } from "antd"

const columns = [
    {
      title: 'Fecha',
      dataIndex: 'fecha',
      key: 'fecha',
    },
    {
      title: 'Ordenes',
      dataIndex: 'ordenes',
      key: 'ordenes',
    },
    {
      title: 'Monto',
      dataIndex: 'monto',
      key: 'monto',
    },
    {
      title: 'Cumplimiento',
      dataIndex: 'cumplimiento',
      key: 'cumplimiento',
    },
  ];
  
  const data = [
    {
      key: '1',
      fecha: '25/05/24',
      ordenes: 'D0001, D008, D015',
      monto: '$8,900.00',
      cumplimiento: 'A tiempo',
    },
    {
      key: '2',
      fecha: '06/06/24',
      ordenes: 'D0003, D0019, D0004',
      monto: '$6,200.00',
      cumplimiento: 'Anticipado',
    },
    {
      key: '3',
      fecha: '20/06/24',
      ordenes: 'D0006, D009',
      monto: '$10,300.00',
      cumplimiento: 'Retraso por 1 día',
    },
  ];

const ClientPayments = () => {
    return (
        <Row gutter={[24, 0]}>
            <Col span={24} lg={50} className="zindex">      
                <Card> 
                <h2>Pagos</h2>
                    <Table columns={columns} dataSource={data} />
                </Card>
            </Col>
            <Col span={24} lg={50} className="zindex">      
                <Card title={<h2>Crédito</h2>}>
                <Row style={{ paddingLeft: 20, paddingBottom: 20 }}>
                    <Col>
                        <Statistic
                            title={<h3>Días de crédito</h3>}
                            value={30}
                        />
                    </Col>
                </Row>
                <Row style={{ justifyContent: 'space-between', paddingLeft: 20, paddingRight: 20}}>
                    <Col>
                        <Statistic
                            title={<h3>Anticipo</h3>}
                            value={'20%'}
                        />
                    </Col>
                    <Col>
                        <Statistic
                            title={<h3>Día de entrega</h3>}
                            value={'30%'}
                        />
                    </Col>
                    <Col>
                        <Statistic
                            title={<h3>15 días</h3>}
                            value={'25%'}
                        />
                    </Col>
                    <Col>
                        <Statistic
                            title={<h3>30 días</h3>}
                            value={'25%'}
                        />
                    </Col>
                    </Row>
                </Card> 
            </Col>
        </Row>
    )
}

export { ClientPayments }