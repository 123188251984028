/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import logo from "../../../images/logo-ct-black.png";
import dasner_logo_black from "../../../images/dasner_logo_black.png";

const navLogo = (props) => {
  return (
    <>
      <div className=" brand" style={{ display: "flex", alignItems: "center" }}>
        <img src={dasner_logo_black} alt="" style={{ width: "200px" }} />
        {/* <span className="b-title">Dasner Petcare</span> */}
      </div>
    </>
  );
};

export default navLogo;
