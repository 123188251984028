import React, { useEffect, useState } from "react";
import { Button, Card, Input, Space, Typography, Modal, Form, DatePicker, Select, message} from "antd";
import Parse from "parse";
import moment from "moment";

const { Title } = Typography;


const NewPayment = ({open, setOpen, object, parseClass, refresh}) => {
    const [form] = Form.useForm();
    const [disableSave, setDisableSave] = useState(false);

    const savePayment = async () => {
        form.validateFields();

        const values = form.getFieldsValue();
        console.log("💸 Payment: ", values, object, parseClass)
        const Payment = Parse.Object.extend('Payment');
        const payment = new Payment();
        payment.set('paymentDate', moment(values.paymentDate).toDate());
        payment.set('amount', parseFloat(values.amount));
        payment.set('method', values.paymentType);
        payment.set('notes', values.notes);
        payment.set('sale', {
            __type: 'Pointer',
            className: parseClass,
            objectId: object.objectId
        });
    
        try {
            await payment.save();
            const ParseClass = Parse.Object.extend(parseClass);
            const parseObject = new ParseClass();
            parseObject.set('objectId', object.objectId);
            parseObject.addUnique('payments', payment);
            parseObject.increment('totalPaid', parseFloat(values.amount));
            await parseObject.save();
    
            const _User = Parse.Object.extend('_User');
            const user = new _User();
            user.set('objectId', object.client.objectId);
            user.addUnique('payments', payment);
            parseClass === 'Sales' ? user.decrement('balance', parseFloat(values.amount)) : user.increment('balance', parseFloat(values.amount));
            // await user.save();   
            refresh();
            message.success('Pago creado correctamente');
            setOpen(false);
        } catch (error) {
            console.error('Error while creating Payment: ', error);
            message.error('Error al crear el pago');
        }
    }

    return <Modal
        open={open}
        onCancel={() => setOpen(false)}
        footer={null}
        style={{ top: 20, width: "90%" }}
    >
        <Title level={4}>Nuevo pago</Title>
        <Form
            form={form}
            layout="vertical"
            onFinish={savePayment}
        >
            <Space direction="vertical" style={{ width: "100%" }}>
                <Form.Item
                    label="Fecha de pago"
                    name="paymentDate"
                    rules={[{ required: true, message: "Por favor, ingrese la fecha de pago" }]}
                >
                    <DatePicker style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item
                    label="Monto"
                    name="amount"
                    rules={[{ required: true, message: "Por favor, ingrese el monto del pago" }]}
                >
                    <Input type="number" style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item
                    label="Tipo de pago"
                    name="paymentType"
                    rules={[{ required: true, message: "Por favor, seleccione el tipo de pago" }]}
                >
                    <Select style={{ width: "100%" }}>
                        <Select.Option value="Efectivo">Efectivo</Select.Option>
                        <Select.Option value="Transferencia">Transferencia</Select.Option>
                        <Select.Option value="Cheque">Cheque</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Observaciones"
                    name="notes"
                >
                    <Input.TextArea style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item>
                    <Space>
                        <Button onClick={() => setOpen(false)}>Cancelar</Button>
                        <Button type="primary" htmlType="submit" disabled={disableSave}>Guardar</Button>
                    </Space>
                </Form.Item>
            </Space>
        </Form>
    </Modal>
}

export default NewPayment;