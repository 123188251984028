import React, { useEffect, useState } from "react";
import { Button, Card, Input, Flex, Typography, Modal, Form, DatePicker, Select, message} from "antd";
import Parse from "parse/lib/browser/Parse";
import moment from "moment";

const { Title } = Typography;



const Comments = ({open, setOpen, object, parseClass, refresh, shouldRefresh}) => {
    const [comment, setComment] = useState({});
    const [renderId, setRenderId] = useState(0);

    useEffect(() => {
        setRenderId(Math.random());
    }, [shouldRefresh]);


    const saveComment = async (comment, object, parseClass) => {
        const Comment = Parse.Object.extend('Comment');
        const commentObject = new Comment();
        commentObject.set('content', comment.content);
        commentObject.set('author', Parse.User.current());
        
        try {
            const saved = await commentObject.save();
    
            const ParseClass = Parse.Object.extend(parseClass);
            const parseObject = new ParseClass();
            parseObject.set('objectId', object.objectId);
            parseObject.addUnique('comments', saved);
            await parseObject.save();
            message.success('Comentario guardado correctamente');
            refresh();
        }
        catch (error) {
            console.error('Error while creating Comment: ', error);
            message.error('Error al guardar el comentario');
        }
    }

    return  <Flex justify="end" vertical style={{ width: "100%", height: "100%" }} gap={20}>
                        {object?.comments?.map((comment, index) => (
                            <Card key={index} title={<Flex justify="space-between" align="center"><h3>{comment.author.name}</h3>
                                <Flex align="flex-end" vertical>
                                    <p>{moment(comment.createdAt).format('DD/MM/YYYY')}</p>
                                    <p>{moment(comment.createdAt).format('HH:mm')}</p>
                                </Flex>
                            </Flex>} style={{ width: "100%" }}>
                                <p>{comment.content}</p>
                                {console.log("📝 Comment: ", comment)}
                            </Card>
                        ))}
                        <Card title="Nuevo comentario" style={{ width: "100%" }}>
                         <Flex justify="end" vertical style={{ width: "100%" }} gap={20}>
                            <Input.TextArea onChange={(e) => setComment({ ...comment, content: e.target.value })} value={comment.content} />
                            <Button type="primary" onClick={() => saveComment(comment, object, parseClass)}>Guardar</Button>
                        </Flex>
                        </Card>
                    </Flex>
}

export default Comments;