import React, { Component } from "react";
import {
  Card,
  Row,
  Col,
  Button,
  Avatar,
  Tag,
  Timeline,
  Tooltip,
  Descriptions,
  Space,
  Typography,
  Select,
} from "antd";
import photo12 from "../../images/photo-12.jpg";
import mastercard from "../../images/mastercard-logo.png";
import { InfoOutlined, IdcardFilled } from "@ant-design/icons";
import { Receipt } from 'react-bootstrap-icons'
import santander from "../../images/Santander.png";
import proteina from "../../images/proteina.png";
import trigo from "../../images/trigo.png";

const { Title, Text } = Typography
export default class OrderDetails extends Component {
  render() {
    return (
      <>
        {/* ToDo:  */}
        <Card
          className="card-order header-solid mb-24 mx-auto mt-20 mb-50"
          title={[<h6 className="mb-0">Detalles de cotización</h6>]}
          bordered={false}
          style={{ maxWidth: "800px" }}
        >
          <Row gutter={[24, 0]}>
            <Col span={24} md={8}>
              <p className="mb-0">
                Cotización numero  # <strong>001234</strong> Fecha:
                <strong>20/06/2024</strong>
              </p>
            </Col>
            <Col>
              <label htmlFor="tipoCliente" style={{ fontWeight: 'bold'}}>Status de cotización</label> <br />
              <Select style={{width:"100%"}}>
                <option value="Accept">Aceptada</option>
                <option value="Denied">Negada</option>
                <option value="Waiting">En espera</option>
              </Select>
            </Col>
          </Row>
          <hr className="gradient-line" />
          <Row gutter={[24, 0]} className="order-products">
            <Col span={24} md={12} style={{ paddingBottom: 20 }}>
              <Row>
                <div className="avat-flex">
                  <Col span={12} md={12}>
                    <Avatar
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRiPe5bbfssvVB68uLGwTkej8dLKbxwhvHcjw&s"
                      shape="square"
                      size={110}
                      alt="photo-12"
                      className="mr-15"
                    />
                  </Col>
                  <Col span={12} md={12}>
                    <div>
                      <h6 className="mb-0 mt-10 font-semibold">Croqueta para gato</h6>
                      <p className="mb-15">Cantidad: 10 costales 10 kg</p>
                      <p className="mb-15">Total: $8,000</p>
                    </div>
                  </Col>

                </div>
              </Row>
              <Row>
                <div className="avat-flex">
                  <Col span={12} md={12}>
                    <Avatar
                      src="https://http2.mlstatic.com/D_NQ_NP_787972-MLM75966223824_052024-O.webp"
                      shape="square"
                      size={110}
                      alt="photo-12"
                      className="mr-15"
                    />
                  </Col>
                  <Col span={12} md={12}>
                    <div>
                      <h6 className="mb-0 mt-10 font-semibold">Croqueta perro adulto</h6>
                      <p className="mb-15">Cantidad: 5 costales 20 kg</p>
                      <p className="mb-15">Total $10,000</p>
                    </div>
                  </Col>

                </div>
              </Row>
            </Col >
            <Col className="ml-auto" span={12} md={12} lg={8}>
              <h6 className="mb-15">Desglose de cotización</h6>
              <div className="mb-10 ant-order-flex">
                <span className="text-muted"> Precio Total </span>
                <span className="text-lg text-dark font-semibold ml-auto">
                  $18,000
                </span>
              </div>
              <div className="mb-10 ant-order-flex">
                <span className="text-muted"> Flete </span>
                <span className="text-lg text-dark font-semibold ml-auto">
                  $850.00
                </span>
              </div>
              <div className="mb-10 ant-order-flex">
                <span className="text-muted"> Impuestos.: </span>
                <span className="text-lg text-dark font-semibold ml-auto">
                  $2,800
                </span>
              </div>
              <div className="mb-10 ant-order-flex">
                <h5 className="font-regular text-muted"> Total: </h5>
                <h5 className="text-dark font-semibold ml-auto">$21,650</h5>
              </div>
            </Col>
          </Row >
          <Row>
            <Col span={24} md={12} className="text-right">
            </Col>
          </Row>
          <hr className="gradient-line" />
          <Row gutter={[24, 0]}>
            <Col span={24} md={12} lg={6}>
              <h5 className="mb-15">Historia de cotización</h5>
              <Timeline>
                <Timeline.Item color="green">
                  Solicitud de cotización
                  <small>10 Junio 2024 10:20 AM</small>
                </Timeline.Item>
                <Timeline.Item color="green">
                  Cotización generada
                  <small> 10 Junio 2024 3:45 AM</small>
                </Timeline.Item>
                <Timeline.Item>
                  Cotización enviada
                  <small>11 Junio 4:35 PM</small>
                  <small> </small>
                </Timeline.Item>
              </Timeline>
            </Col>
            <Col span={24} md={12} lg={18}>
              <Row>
                <Col span={8} md={12}>
                  <h6 className="mb-15">Información del vendedor</h6>
                  <Card bordered={false} className="card-billing-info">
                    <Descriptions title="Petco">
                      <Descriptions.Item label="Contacto" span={3}>
                        Petco México
                      </Descriptions.Item>
                      <Descriptions.Item label="Correo electrónico" span={3}>
                        petco@gmail.com
                      </Descriptions.Item>
                      <Descriptions.Item label="Telefono" span={3}>
                        378786883423 ext 15
                      </Descriptions.Item>
                      <Descriptions.Item label="RFC" span={3}>
                        XAXX010101000
                      </Descriptions.Item>
                    </Descriptions>
                  </Card>
                </Col>
                <Col span={8} md={12}>
                  <h6 className="mb-15">Información del flete</h6>
                  <Card bordered={false} className="card-billing-info">
                    <Descriptions title="Logistica">
                      <Descriptions.Item label="Paquetería" span={3}>
                        Castores
                      </Descriptions.Item>
                      <Descriptions.Item label="Correo electrónico" span={3}>
                        Castore@gmail.com
                      </Descriptions.Item>
                      <Descriptions.Item label="Telefono" span={3}>
                        555345214367 ext 105
                      </Descriptions.Item>
                      <Descriptions.Item label="Código Postal origen " span={3}>
                        47600
                      </Descriptions.Item>
                      <Descriptions.Item label="Código Postal destino " span={3}>
                        01040
                      </Descriptions.Item>
                      <Descriptions.Item label="Peso aproximado de envío " span={3}>
                        200 kg
                      </Descriptions.Item>
                      <Descriptions.Item label="Costo " span={3}>
                        $850
                      </Descriptions.Item>
                    </Descriptions>
                  </Card>
                </Col>
              </Row>
            </Col>


          </Row>
        </Card >
      </>
    );
  }
}
