import React, { useEffect, useState } from "react";
import { Button, Card, Input, Space, Typography, Modal, Form, DatePicker, Select, message} from "antd";
import { MinusOutlined } from '@ant-design/icons';
import DebounceSelect from "../../../form/DebounceSelect";
import ProductList from "./ProductList";
import { fetchSuppliers, fetchProducts } from "../utils/query";
import AddressAutocomplete from "../../../form/PlacesSelect";
import { calc } from "antd/es/theme/internal";
import Parse from "parse";
const { Title } = Typography;

const NewPurchaseOrder = ({open, setOpen, refresh}) => {    
    const [form] = Form.useForm();
    const [renderId, setRenderId] = useState(0);
    const [disableSave, setDisableSave] = useState(false);
    const [deliveryAddress, setDeliveryAddress] = useState({});
    
    const handleCancel = () => {
        setOpen(false);
    };
    
    useEffect(() => {
        form.resetFields();
        form.setFieldsValue({
            // deliveryDate: estimatedDeliveryDate(),
            products: [],
        });
    }, [open]);

    useEffect(() => {
        allowSave();
    }, [form.getFieldsValue()]);

    const allowSave = () => {
       let error = false;
        const values = form.getFieldsValue();
        if (!values.supplier || !values.products || !values.products.length || calculateTotal() <= 0) {
            error = true;
        }
        setDisableSave(error);
    };
    

   const calculateTotal = () => {
        const products = form.getFieldValue("products");
        const deliveryPrice = form.getFieldValue("deliveryPrice") || 0;
        let total = products?.reduce((acc, product) => acc + product.total, 0) + Number(deliveryPrice);
        total = total.toFixed(2);
        return !isNaN(total) ? total : 0;
    }

    const handleSave = async() => {
        console.log("form", form.getFieldsValue());
        form.getFieldsError();
        const values = form.getFieldsValue();
        console.log(values)

        const Index = Parse.Object.extend("Index");
        const index = new Parse.Query(Index);
        index.equalTo("name", "PurchaseOrder");
        index.equalTo("year", new Date().getFullYear());
        console.log("year", new Date().getFullYear());
        const result = await index.first();
        console.log("result", result);
        const newIndex = result.get("value") + 1;
        const indexString = "C"+newIndex.toString().padStart(4, "0")+"-"+new Date().getFullYear().toString().substr(-2);
        const PurchaseOrder = Parse.Object.extend("PurchaseOrder");
        const purchaseOrder = new PurchaseOrder();
        purchaseOrder.set("supplier", {
            __type: "Pointer",
            className: "_User",
            objectId: values.supplier.value,
        });
        purchaseOrder.set("products", values.products.map((p) => ({
            name: p.name,
            objectId: p.value,
            presentation: p.presentation,
            quantity: p.quantity,
            cost: p.cost,
            total: p.total,
            baseQuantity: p.presentations.find((pr) => pr.presentation === p.presentation).quantity * p.quantity,
            baseUnit: p.basePresentation
        })
    ));
        purchaseOrder.set("total", calculateTotal());
        purchaseOrder.set("productPointers", values.products.map((p) => ({
            __type: "Pointer",
            className: "Ingredient",
            objectId: p.objectId,
        })));
        purchaseOrder.set("status", "Pendiente");
        purchaseOrder.set("folio", indexString);
        purchaseOrder.set("deliveries", "Pendiente");
        purchaseOrder.set("payments", "Pendiente");
        
        result.increment("value");
        result.save();

        purchaseOrder.save().then(
            (result) => {
                message.success("Orden de compra creada correctamente");
                console.log("Purchase Order created", result);
                setOpen(false);
                refresh();
            }
        );
    }
    
    return (
        <Modal
        title="Nueva orden de compra"
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={1200}
        >
        <Form form={form} layout="vertical">
            <Form.Item label="Proveedor" name="supplier" rules={[{ required: true, message: "Selecciona un proveedor" }]}>
            <DebounceSelect
                fetchOptions={fetchSuppliers}
                placeholder="Buscar proveedor"
                style={{ width: "100%" }}
                allowClear
                showSearch
            />
            </Form.Item>
            

            
            <Form.Item label="Productos" name="products" rules={[{ required: true, message: "Selecciona al menos un producto" }]}>
                <ProductList form={form} setRenderId={setRenderId} />
            </Form.Item>
            {/* <Form.Item label="Entrega" name="delivery" rules={[{ required: true, message: "Selecciona un tipo de entrega" }]}>
                <Select
                    onChange={() => setRenderId((prev) => prev + 1)}
                    placeholder="Selecciona un tipo de entrega"
                    style={{ width: "100%" }}
                    allowClear
                >
                    <Select.Option value="Única">Entrega única</Select.Option>
                    <Select.Option value="Múltiple">Entregas múltiples</Select.Option>
                </Select>
            </Form.Item>
            {form.getFieldValue("delivery") === "Múltiple" && (
            <Space direction="horizontal" style={{ width: "100%" }}>
                <Form.Item label="Cantidad" name="deliveryQuantity" rules={[{ required: true, message: "Ingresa una cantidad" }]}>
                    <Input type="number" placeholder="Ingresa una cantidad" />
                </Form.Item>
                <Form.Item label="Frecuencia" name="frequency" rules={[{ required: true, message: "Selecciona una frecuencia" }]}>
                    <Select
                        placeholder="Selecciona una frecuencia"
                        style={{ width: "100%" }}
                        allowClear
                    >
                        <Select.Option value="Semanal">Semanal</Select.Option>
                        <Select.Option value="Quincenal">Quincenal</Select.Option>
                        <Select.Option value="Mensual">Mensual</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item label="Fecha de inicio" name="startDate" rules={[{ required: true, message: "Selecciona una fecha" }]}>
                    <DatePicker style={{ width: "100%" }} placeholder="Selecciona una fecha" />
                </Form.Item>
            </Space>
            )} */}

        </Form>
        <Space direction="horizontal" style={{ width: "100%" }}>
        <Title level={4}>Total: ${String(calculateTotal()).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Title>
        </Space>
        <Button type="primary" htmlType="submit" style={{ position: "absolute", bottom: 20, right: 20 }} disabled={disableSave} onClick={handleSave}>
                Guardar
            </Button>
        </Modal>
    );
    }

export default NewPurchaseOrder;