import React, { useState } from "react";
import { Tabs, Card, Statistic, Row, Col, Progress } from 'antd';
import ReactApexChart from "react-apexcharts";

const dataSource = {
    "month": "Junio",
    "year": "2024",
    "sellObjective": 8000000,
    "productionObjective": 100000,
    "sell": [
        { "day": 1, "kg": 100, "money": 80000 },
        { "day": 12, "kg": 500, "money": 400000 },
        { "day": 23, "kg": 60000, "money": 480000 },
        { "day": 24, "kg": 120000, "money": 800000 },
    ],
    "production": [
        { "day": 1, "kg": 10000, "money": 10000 },
        { "day": 12, "kg": 30000, "money": 30000 },
        { "day": 23, "kg": 60000, "money": 60000 },
        { "day": 24, "kg": 100000, "money": 1000000 },
    ]
};

const translation = {
    "sell": "ventas",
    "production": "producción"
};

const OKRsDetails = () => {
    const [activeTab, setActiveTab] = useState("1");

    const handleTabChange = (key) => {
        setActiveTab(key);
    };

    // Función para obtener los datos de acuerdo al tab seleccionado
    const getChartData = () => {
        let series = [];
        let categories = [];

        if (activeTab === "1" || activeTab === "2") {
            // Ventas
            const dataKey = "sell";
            categories = dataSource[dataKey].map(record => record.day);

            if (activeTab === "1") {
                series = [
                    {
                        name: `Dinero en Ventas`,
                        data: dataSource[dataKey].map(record => ({
                            x: record.day,
                            y: record.money,
                        }))
                    },
                    {
                        name: `Objetivo de Dinero en Ventas`,
                        data: categories.map(day => ({
                            x: day,
                            y: dataSource.sellObjective,
                        })),
                        type: 'line',
                        markers: {
                            size: 0,
                        }
                    }
                ];
            } else if (activeTab === "2") {
                series = [
                    {
                        name: `Volúmen en Ventas`,
                        data: dataSource[dataKey].map(record => ({
                            x: record.day,
                            y: record.kg,
                        }))
                    },
                    {
                        name: `Objetivo de Volúmen en Ventas`,
                        data: categories.map(day => ({
                            x: day,
                            y: dataSource.sellObjective,
                        })),
                        type: 'line',
                        markers: {
                            size: 0,
                        }
                    }
                ];
            }
        } else if (activeTab === "3" || activeTab === "4") {
            // Producción
            const dataKey = "production";
            categories = dataSource[dataKey].map(record => record.day);

            if (activeTab === "3") {
                series = [
                    {
                        name: `Dinero en Producción`,
                        data: dataSource[dataKey].map(record => ({
                            x: record.day,
                            y: record.money,
                        }))
                    },
                    {
                        name: `Objetivo de Dinero en Producción`,
                        data: categories.map(day => ({
                            x: day,
                            y: dataSource.productionObjective,
                        })),
                        type: 'line',
                        markers: {
                            size: 0,
                        }
                    }
                ];
            } else if (activeTab === "4") {
                series = [
                    {
                        name: `Volúmen en Producción`,
                        data: dataSource[dataKey].map(record => ({
                            x: record.day,
                            y: record.kg,
                        }))
                    },
                    {
                        name: `Objetivo de Volúmen en Producción`,
                        data: categories.map(day => ({
                            x: day,
                            y: dataSource.productionObjective,
                        })),
                        type: 'line',
                        markers: {
                            size: 0,
                        }
                    }
                ];
            }
        } else if (activeTab === "5") {
            // General
            const allData = [...dataSource.sell, ...dataSource.production];
            categories = allData.map(record => record.day);

            series = [
                {
                    name: `Dinero en Ventas`,
                    data: dataSource.sell.map(record => ({
                        x: record.day,
                        y: record.money,
                    }))
                },
                {
                    name: `Volúmen en Ventas`,
                    data: dataSource.sell.map(record => ({
                        x: record.day,
                        y: record.kg,
                    }))
                },
                {
                    name: `Dinero en Producción`,
                    data: dataSource.production.map(record => ({
                        x: record.day,
                        y: record.money,
                    }))
                },
                {
                    name: `Volúmen en Producción`,
                    data: dataSource.production.map(record => ({
                        x: record.day,
                        y: record.kg,
                    }))
                },
                {
                    name: `Objetivo de Dinero en Ventas`,
                    data: categories.map(day => ({
                        x: day,
                        y: dataSource.sellObjective,
                    })),
                    type: 'line',
                    markers: {
                        size: 0,
                    }
                },
                {
                    name: `Objetivo de Volúmen en Ventas`,
                    data: categories.map(day => ({
                        x: day,
                        y: dataSource.sellObjective,
                    })),
                    type: 'line',
                    markers: {
                        size: 0,
                    }
                },
                {
                    name: `Objetivo de Dinero en Producción`,
                    data: categories.map(day => ({
                        x: day,
                        y: dataSource.productionObjective,
                    })),
                    type: 'line',
                    markers: {
                        size: 0,
                    }
                },
                {
                    name: `Objetivo de Volúmen en Producción`,
                    data: categories.map(day => ({
                        x: day,
                        y: dataSource.productionObjective,
                    })),
                    type: 'line',
                    markers: {
                        size: 0,
                    }
                }
            ];
        }

        const options = {
            chart: {
                height: 400,
                type: 'line',
                zoom: {
                    enabled: false
                }
            },
            xaxis: {
                categories: categories,
            },
            yaxis: [
                {
                    title: {
                        text: 'Dinero ($)',
                    },
                },
                {
                    opposite: true,
                    title: {
                        text: 'Volúmen (kg)',
                    },
                },
            ],
        };

        return { series, options };
    };

    const dataCards = ["sell", "production"].flatMap(key => (
        [
            <Col span={6} key={`${key}-money`}>
                <Card style={{ padding: "0px 10px" }}>
                    <Statistic
                        title={`Dinero en ${translation[key].charAt(0).toUpperCase() + translation[key].slice(1)}`}
                        value={dataSource[key].reduce((sum, record) => sum + record.money, 0)}
                        valueStyle={{ color: '#3f8600' }}
                        prefix="$"
                    />
                    <span>Objetivo: ${dataSource[key + "Objective"]}</span>
                    <Progress percent={((dataSource[key].reduce((sum, record) => sum + record.money, 0) / dataSource[key + "Objective"]) * 100).toFixed(2)} size="small" />
                </Card>
            </Col>,
            <Col span={6} key={`${key}-kg`}>
                <Card style={{ padding: "0px 10px" }}>
                    <Statistic
                        title={`Volúmen en ${translation[key].charAt(0).toUpperCase() + translation[key].slice(1)}`}
                        value={dataSource[key].reduce((sum, record) => sum + record.kg, 0)}
                        valueStyle={{ color: '#3f8600' }}
                        suffix="kg"
                    />
                    <span>Objetivo: {dataSource[key + "Objective"]} kg</span>
                    <Progress percent={((dataSource[key].reduce((sum, record) => sum + record.kg, 0) / dataSource[key + "Objective"]) * 100).toFixed(2)} size="small" />
                </Card>
            </Col>
        ]
    ));

    return (
        <Col style={{ margin: "10px" }}>
            <h1 style={{ fontSize: "30px", fontWeight: "bold" }}>
                OKRs
            </h1>
            <h2>
                {dataSource.month.charAt(0).toUpperCase() + dataSource.month.slice(1)} - {dataSource.year}
            </h2>
            <Row gutter={16} justify="center">
                {dataCards}
            </Row>
            <Row gutter={16} style={{ marginTop: '20px' }} justify="center">
                <Tabs
                    className="tabs-sliding"
                    defaultActiveKey="1"
                    activeKey={activeTab}
                    onChange={handleTabChange}
                >
                    <Tabs.TabPane tab="Dinero en Ventas" key="1" />
                    <Tabs.TabPane tab="Volúmen en Ventas" key="2" />
                    <Tabs.TabPane tab="Dinero en Producción" key="3" />
                    <Tabs.TabPane tab="Volúmen en Producción" key="4" />
                    <Tabs.TabPane tab="General" key="5" />
                </Tabs>
            </Row>
            <Row gutter={16} style={{ marginTop: '20px' }}>
                <Card style={{ width: "100%" }}>
                    <ReactApexChart
                        options={getChartData().options}
                        series={getChartData().series}
                        type="line"
                        height={400}
                    />
                </Card>
            </Row>
        </Col>
    );
};

export default OKRsDetails;
