import React, { useEffect } from 'react';
import { Row, Col, Card, Statistic, Steps, Table, Progress } from 'antd';

const pesoIngrdientes = 1000;
const pesoGrasaDigesta = 200;
const pesoTotal = pesoGrasaDigesta + pesoIngrdientes;


const dataSource = [
    { presentation: "5kg", unity: 3 },
    { presentation: "10kg", unity: 19 },
    { presentation: "20kg", unity: 56 },
];

const productShrinkageDataSource = [
    {product: "Croqueta para perros", quantity: 10, reason:"Exceso de ingredientes"},
    {product: "Croquerta para gatos", quantity: 45, reason:"Mala calidad"},
    {product: "Suplemento para gatos", quantity: 39, reason:"Producto contaminado"},
]

const supplyShrinkageDataSource = [
    {product: "Costal 10kg", quantity: 1, reason:"Defectos en el material"},
    {product: "Costal 20kg", quantity: 20, reason:"Se quemó el material"},
    {product: "Costal 5kg", quantity: 3, reason:"Material desaparecido"},
]

dataSource.forEach(item => {
    const weight = parseInt(item.presentation.replace('kg', ''), 10);
    item.total = item.unity * weight;
});


const columns = [
    {
        title: "Presentación",
        dataIndex: "name",
        key: "name",
        render: (text) => <h3>{text}</h3>,
    },
    {
        title: "Total",
        dataIndex: "quantity",
        key: "quantity",
        render: (text) => <h3>{text} pz</h3>,
    },
    {
        title: "Peso",
        dataIndex: "content",
        key: "content",
        render: (text) => <h3>{text} kg</h3>,
    },
    {
        title: "Peso Total",
        dataIndex: "total",
        key: "total",
        render: (text, record) => <h3>{record.content * record.quantity} kg</h3>,
    },
    {
        title: "Progreso",
        dataIndex: "added",
        key: "added",
        render: (added, record) => <Progress percent={added / record.quantity * 100} format={() => `${added} / ${record.quantity}`} />,
    }
];

const shrinkageColumns = [
    {
        title: "Cantidad",
        dataIndex: "quantity",
        key: "quantity",
        render: (text) => <h3>{text}</h3>
    },
    {
        title: "Producto",
        dataIndex: "product",
        key: "product",
        render: (text) => <h3>{text}</h3>
    },
    {
        title: "Motivo",
        dataIndex: "reason",
        key: "reason",
        render: (text) => <h3>{text}</h3>
    },
]

const ProductionSummary = ({ production }) => {
    const [ productionInfo, setProductionInfo ] = React.useState(null);

  

    return (
        <>
            <Row gutter={16}>
                <Col span={8} xs={24} md={8}>
                    <Card
                        bordered={false}
                        bodyStyle={{ padding: '10px' }}
                        style={{ margin: '10px' }}
                        className="criclebox cardbody mb-24 header-solid"
                        title={
                            <Statistic
                                title="Peso en Ingredientes"
                                value={production?.recipe?.ingredientsWeight * production?.productionQuantity}
                                suffix="KG"
                                style={{ fontSize: '20px' }}
                            />
                        }
                    />
                </Col>
                <Col span={8} xs={24} md={8}>
                    <Card
                        bordered={false}
                        bodyStyle={{ padding: '10px' }}
                        style={{ margin: '10px' }}
                        className="criclebox cardbody mb-24 header-solid"
                        title={
                            <Statistic
                                title="Peso terminado"
                                value={production?.productionQuantity}
                                suffix="KG"
                                style={{ fontSize: '20px' }}
                            />
                        }
                    />
                </Col>
                <Col span={8} xs={24} md={8}>
                    <Card
                        bordered={false}
                        bodyStyle={{ padding: '10px' }}
                        style={{ margin: '10px' }}
                        className="criclebox cardbody mb-24 header-solid"
                        title={
                            <Statistic
                                title="Tolva"
                                value={production?.hopper ? production?.hopper : 'No asignada'}
                                style={{ fontSize: '20px' }}
                            />
                        }
                    />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Card
                        bordered={false}
                        bodyStyle={{ padding: "0px"}}
                        style={{ padding: 20 }}
                        className="criclebox cardbody mb-24 header-solid"
                        title={<h2>Envasado</h2>}
                    >
                        <div className="ant-list-box table-responsive">
                            <Table
                                style={{ width: '100%' }}
                                dataSource={production?.presentations}
                                columns={columns}
                                rowKey={(record, index) => index}
                                pagination={true}
                            />
                        </div>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col span={12} md={12}>
                    <Card
                        bordered={false}
                        bodyStyle={{ padding: "0px"}}
                        style={{ padding: 20, marginRight: 10 }}
                        className="criclebox cardbody mb-24 header-solid"
                        title={<h2>Merma Total de Insumos</h2>}
                    >
                        <div className="ant-list-box table-responsive">
                            <Table
                                style={{ width: '100%' }}
                                dataSource={supplyShrinkageDataSource}
                                columns={shrinkageColumns}
                                rowKey={(record, index) => index}
                                pagination={true}
                            />
                        </div>
                    </Card>
                </Col>
                <Col span={12} md={12}>
                    <Card
                        bordered={false}
                        bodyStyle={{ padding: "0px" }}
                        style={{ padding: 20, marginLeft: 10 }}
                        className="criclebox cardbody mb-24 header-solid"
                        title={<h2>Merma Total de Producto</h2>}
                    >
                        <div className="ant-list-box table-responsive">
                            <Table
                                style={{ width: '100%' }}
                                dataSource={productShrinkageDataSource}
                                columns={shrinkageColumns}
                                rowKey={(record, index) => index}
                                pagination={true}
                            />
                        </div>
                    </Card>
                </Col>
            </Row>
        </>
    )
}


export default ProductionSummary;
