import React, { useEffect, useState } from "react";
import { Card, Col, Row, Table, Typography, Statistic, FloatButton } from "antd";
import { fetchClients } from "./utils/query";
import NewClient from "./components/NewClient";

const { Title } = Typography;

const ClientsList = () => {
  const [dataSource, setDataSource] = useState([]);
  const [newClientModal, setNewClientModal] = React.useState(false);
  const [shouldRefresh, setShouldRefresh] = React.useState(false);

  useEffect(() => {
    const getClients = async () => {
      const clients = await fetchClients();
      console.log(clients);
      setDataSource(clients);
    };

    getClients();
  }, []);

  const refresh = () => {
    setShouldRefresh(!shouldRefresh);
  };

  const renderBalance = (text) => {
    const balanceValue = parseFloat(text.replace(/[^\d.-]/g, ""));
    const color = balanceValue >= 0 ? "green" : "red";

    return (
      <h3 style={{ color: color }}>
        {text}
      </h3>
    );
  };

  const columns = [
    {
      dataIndex: "img",
      key: "img",
      render: (img) => <img src={img} alt="" className="avatar-sm mr-10" style={{ width: "100%" }} />,
    },
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
      render: (text) => (
        <>
          <h3>{text}</h3>
        </>
      ),
    },
    {
      title: "Estado",
      dataIndex: "state",
      key: "state",
      render: (text) => (
        <>
          <p>{text}</p>
        </>
      ),
    },
    { // TODO: Verify EMAIL data from Parse
      title: "Correo electrónico",
      dataIndex: "email",
      key: "email",
      render: (text) => (
        <>
          <p>{text}</p>
        </>
      ),
    },
    {
      title: "Número de teléfono",
      dataIndex: "phone",
      key: "phone",
      render: (text) => (
        <>
          <p>{text}</p>
        </>
      ),
    },
    {
      title: "Vendedor",
      dataIndex: "seller",
      key: "seller",
      render: (text) => (
        <>
          <p>{text}</p>
        </>
      ),
    },
    {
      title: "Volumen de ventas",
      dataIndex: "sales",
      key: "sales",
      render: (text) => (
        <>
          <p>{text}</p>
        </>
      ),
    },
    {
      title: "Balance financiero",
      dataIndex: "balance",
      key: "balance",
      //render: renderBalance,
    },
  ];

  return (
    <div className="layout-content">
      <FloatButton onClick={() => setNewClientModal(true)}/>
      <NewClient open={newClientModal} setOpen={setNewClientModal} refresh={refresh} />
      <Row>
        <Col lg={28} className="zindex">
          <h2 className="p-15 h2">Clientes</h2>
        </Col>
        <Col>
          <Statistic
            title="Número de Clientes"
            value={dataSource.length}
            valueStyle={{ color: '#3f8600' }}
            suffix="clientes"
            style={{ padding: "20px" }}
          />
        </Col>
      </Row>
      <Row>
        <Card
          bordered={false}
          bodyStyle={{ padding: "0px" }}
          className="criclebox cardbody mb-24 header-solid"
        >
          <div className="ant-list-box table-responsive">
            <Table
              dataSource={dataSource}
              columns={columns}
              rowKey={(record, index) => index}
              pagination={true}
            />
          </div>
        </Card>
      </Row>
    </div>
  );
};

export default ClientsList;
