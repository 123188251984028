import React, { useEffect, useState } from "react";
import { Table, Typography, Button, Space, Card, Segmented, Select } from "antd";
import Parse from "parse";
import moment from "moment";
const { Title } = Typography;


const entryColumns = [
    {
      title: "Fecha",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => <h3>{moment(text).format("DD/MM/YYYY")}</h3>,
    },
    {
      title: 'Proveedor',
      dataIndex: 'purchaseOrder',
      key: 'purchaseOrder',
      render: (purchaseOrder) => <h3>{purchaseOrder?.supplier?.name}</h3>,
    },
    {
      title: "Orden de compra",
      dataIndex: "purchaseOrder",
      key: "purchaseOrder",
      render: (purchaseOrder) => <h3>{purchaseOrder?.folio}</h3>,
    },
    {
      title: "Entrada",
      dataIndex: "folio",
      key: "folio",
      render: (text) => <h3>{text}</h3>,
    },
    {
      title: "Cantidad",
      dataIndex: "product",
      key: "product",
      render: (product) => <h3>{(product?.received * product?.baseQuantity).toLocaleString()} {product?.baseUnit}</h3>,
    },
    {
      title: "Costo unitario",
      dataIndex: "product",
      key: "cost",
      render: (product) => <h3>${(product?.cost / product?.baseQuantity).toFixed(2)} / {product?.baseUnit}</h3>,
    },
    {
      title: "Costo total",
      dataIndex: "product",
      key: "totalCost",
      render: (product) => <h3>{'$' + (product?.cost * product?.received).toLocaleString()}</h3>,
    }
  ];
  
  const dataSource = [
    {
      Date: "16/06/2024",
      Order: "D0001",
      Product: 'Croqueta para perro',
      Quantity: '100 kg',
      cost: '$2,450.00',
    },
  ];
  
  const options = {
    chart: {
      width: "100%",
      height: 350,
      type: "area",
      toolbar: {
        show: false,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      colors: ["#1890FF", "#B37FEB"],
      width: 3,
    },
    yaxis: {
      labels: {
        style: {
          fontSize: "14px",
          fontWeight: 600,
          colors: ["#8c8c8c"],
        },
        offsetY: -50,
      },
    },
    xaxis: {
      labels: {
        style: {
          fontSize: "14px",
          fontWeight: 600,
          colors: ["#8c8c8c"],
        },
      },
      categories: [
        "Ene",
        "Feb",
        "Abr",
        "May",
        "Jun",
        "Jul",
        "Ago",
        "Sep",
        "Oct",
        "Nov",
        "Dic",
      ],
    },
    grid: {
      show: true,
      borderColor: "rgba(0, 0, 0, .2)",
      strokeDashArray: 6,
      position: "back",
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
      row: {
        colors: undefined,
        opacity: 0.5,
      },
      column: {
        colors: undefined,
        opacity: 0.5,
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
  };
  
  const series = [
    {
      name: "Compras en pesos",
      data: [50, 40, 300, 220, 500, 250],
      offsetY: 0,
    },
    {
      name: "Kilos",
      data: [30, 90, 40, 140, 290, 290],
      offsetY: 0,
    },
  ];

  const queryEntries = async (objectId) => {
    try {
      const query = new Parse.Query("WarehouseEntry");
      query.include(["user", "purchaseOrder", "purchaseOrder.supplier"]);
      query.exclude(["images", "productPointers"]);
      query.containedBy('productPointer',[{__type: "Pointer", className: "Product", objectId: objectId}]);
      query.descending("createdAt");

      const results = await query.find();
      return results.map((result) => ({
        ...result.toJSON(),
      product: result.get("products")?.find((p) => p.objectId === objectId),
      }));
    } catch (error) {
      console.error("Error while fetching entries", error);
    }
  };


const MovementsTable = ({ item }) => {
    const [chartGroup, setChartGroup] = useState('Entradas');
    const [entries, setEntries] = useState([]);

    useEffect(() => {
      console.log("item", item);
     if (item) {
       queryEntries(item.objectId).then((results) => {
        console.log("results", results);
         setEntries(results);
       });
     }
    }, [item]);
    
    return (
        <Card
          bordered={false}
          bodyStyle={{ padding: "0px" }}
          style={{ padding: 20 }}
          className="criclebox cardbody mb-24 header-solid"
          title={<Space size={8} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
          ><h3>Historial de Movimientos</h3> <Segmented 
          options={['Entradas', 'Salidas']}
          selected="Entradas"
          onChange={(e) => setChartGroup(e)}
        />
        </Space>}
        >
         
          {chartGroup === 'Entradas' && (
          <div className="ant-list-box table-responsive">
            <Table
              style={{ width: '100%' }}
              dataSource={entries}
              columns={entryColumns}
              rowKey={(record, index) => index}
              pagination={true}
            />
          </div>
          )}
          {chartGroup === 'Salidas' && (
          <div className="ant-list-box table-responsive">
            <Table
              style={{ width: '100%' }}
              dataSource={dataSource}
              columns={entryColumns}
              rowKey={(record, index) => index}
              pagination={true}
            />
          </div>
          )}
        </Card>
    );
}

export default MovementsTable;